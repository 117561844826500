import styled from "styled-components";
import { Title } from "../components/Title";
import { breakpoints, marketplaceColors } from "../constants/styleConfig";

interface IProductGridProps {
  label?: string;
  items: {
    imageSrc: string;
    label: string;
  }[];
};

const GridContainer = styled.div`
  width: 100%;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;
    margin-bottom: 2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const GridCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0 1.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  border: 1px solid ${marketplaceColors.gray1};
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: ${marketplaceColors.primaryDark};
    border-color: ${marketplaceColors.primaryDark};
    box-shadow: 0px 0px 12px 0px ${marketplaceColors.primaryGhostDark};
  }
`;

const CardImage = styled.img`
  margin-bottom: 1rem;
`;

const ProductGrid = ({
  label,
  items,
}: IProductGridProps) => {
  return (
    <GridContainer>
      <Title>{label}</Title>
      <CardGrid>
        {items.map((gridItem) => (
          <GridCard>
            <CardImage width={130} height={130} src={gridItem.imageSrc} alt={gridItem.label} />
            {gridItem.label}
          </GridCard>
        ))}
      </CardGrid>
    </GridContainer>
  );
}

export default ProductGrid;
