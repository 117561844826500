import { ReactNode } from "react";
import styled from "styled-components";
import { animations, marketplaceColors } from "../../constants/styleConfig";

interface IButtonProps {
  buttonStyle?: 'fill' | 'border' | 'ghost';
  size?: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
};

const StyledButton = styled.div<IButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ size }) => {
    switch(size) {
      case 'small':
        return '0.625rem 1.5rem';
      case 'medium':
        return '0.875rem 2rem';
      case 'large':
        return '1rem 2.5rem';
    }
  }};
  font-size: ${({ size }) => {
    switch(size) {
      case 'small':
        return '0.875rem';
      case 'medium':
        return '1rem';
      case 'large':
        return '1.125rem';
    }
  }};
  font-weight: 600;
  text-align: center;
  color: ${({ buttonStyle, isDisabled }) => {
    return (buttonStyle === 'fill' || isDisabled)
      ? marketplaceColors.white
      : marketplaceColors.primary;
  }};
  background-color: ${({ buttonStyle, isDisabled }) => {
    if (isDisabled) return marketplaceColors.gray4;
    switch(buttonStyle) {
      case 'fill':
        return marketplaceColors.primary;
      case 'border':
        return marketplaceColors.white;
      case 'ghost':
        return marketplaceColors.primaryGhost;
    }
  }};
  border: 2px solid ${({ buttonStyle, isDisabled }) => (buttonStyle === 'ghost' || isDisabled)
    ? 'transparent'
    : marketplaceColors.primary
  };
  border-radius: 0.375rem;
  cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'};
  transition: all ${animations.timeDefault};

  &:hover {
    color: ${({ buttonStyle, isDisabled }) => (buttonStyle === 'fill' || isDisabled)
      ? marketplaceColors.white
      : marketplaceColors.primaryDark
    };
    background-color: ${({ buttonStyle, isDisabled }) => {
      if (isDisabled) return marketplaceColors.gray4;
      switch(buttonStyle) {
        case 'fill':
          return marketplaceColors.primaryDark;
        case 'border':
          return marketplaceColors.white;
        case 'ghost':
          return marketplaceColors.primaryGhostDark;
      }
    }};
    border-color: ${({ buttonStyle, isDisabled }) => (buttonStyle === 'ghost' || isDisabled)
      ? 'transparent'
      : marketplaceColors.primaryDark
    };
  }
`;

const AppButton = ({
  buttonStyle = 'fill',
  size = 'medium',
  isDisabled = false,
  className,
  children,
  onClick = () => {},
}: IButtonProps) => {
  return (
    <StyledButton
      className={className}
      buttonStyle={buttonStyle}
      size={size}
      isDisabled={isDisabled}
      onClick={() => !isDisabled && onClick()}
    >
      {children}
    </StyledButton>
  );
};

export { AppButton };
