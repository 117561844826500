import styled from "styled-components";
import { colors, marketplaceColors } from "../../constants/styleConfig";

interface IOptionProps {
  $isActive?: boolean;
}

export const Wrapper = styled.div`
  min-width: fit-content;
`;

export const SelectInput = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`;

export const Container = styled.div`
  position: relative;
  user-select: none;
  cursor: pointer;
`;

export const LabelText = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
`;

export const SelectedValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  background-color: ${marketplaceColors.white};
  border: 1px solid ${marketplaceColors.gray1};
  border-radius: 4px;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 10;
  font-size: 16px;
  border: 1px solid ${marketplaceColors.gray1};
  border-top: none;
`;

export const Option = styled.div<IOptionProps>`
  padding: 0.25rem 0.5rem;
  background-color: ${({ $isActive }) => $isActive ? marketplaceColors.primary : marketplaceColors.white};

  &:hover {
    background-color: ${({ $isActive }) => $isActive ? marketplaceColors.primary : marketplaceColors.primaryLight};
  }
`;
