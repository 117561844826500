import styled from "styled-components";

import { marketplaceColors } from "../constants/styleConfig";

const Breadcrumbs = styled.span`
  font-size: 14px;
  color: ${marketplaceColors.gray6};
  margin-bottom: 10px;

  a {
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${marketplaceColors.primaryDark};
    }
  }
`;

export default Breadcrumbs;
