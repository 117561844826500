import axios from "axios";
// import 'dotenv/config';
import { Buffer } from "buffer";
import { IOpenSearchProduct, IProduct, IProductExtended, IProductSearchResult, IProductVariant, IVariantHit, IVariantList } from "../types/products";
import { IAuth, IClientDiscountData, IFilterQueryCheckbox } from "../types/reducers";
import { SEARCH_RESULTS_SIZE } from "../constants/appConfig";
import { IGetTotalArticlesResponse, INovaCity, INovaWarehouses } from "../types/api";
import { IOrder, IOrderProduct, IOrderRaw, IUserDataContext } from "../types/orders";
import { IArticle } from "../types/articles";
import {OfferFormDataType} from "../types/content";

const API_URL = 'https://api.fitness-post.com/api';
const TEST_API_URL = 'https://api.fitness-post.com/api';
const NOVA_API_URL = 'https://api.novaposhta.ua/v2.0/json/';
const NOVA_API_KEY = '1a2e601560aec83b158a89dee64ffe9d';

const requestOptions = {
  headers: {
    'Content-Type': 'application/json',
  }
};

enum API_ROUTES {
  LOG_IN='/authenticate-user',
  GET_PRODUCTS='/products',
  GET_PRODUCT_BY_ID='/get-product-by-id',
  GET_ALL_PRODUCTS_BY_IDS='/get-all-products-by-ids',
  SEARCH_PRODUCTS='/search-products',
  SEARCH_VARIANTS='/search-variants',
  SEARCH_FILTERS='/search-filters',
  GET_CATEGORIES='/categories',
  SUBMIT_CART_LIST='/add-sold-products',
  GET_CHECKBOXES='/get-checkboxes',
  CREATE_BACKUP='/create-backup',
  GET_BACKUPS='/get-backups',
  GET_CLIENT_DISCOUNT='/get-client-discount',
  SUBMIT_CLIENT_DISCOUNT='/add-client-discount',
  CREATE_ORDER='/create-order',
  GET_ORDERS_BY_ID='/get-orders-by-id',
  GET_ORDER_BY_NUMBER='/get-order-by-number',
  GET_ORDERS='/get-orders',
  GET_TOTAL_ARTICLES='/get-total-articles',
  GET_ARTICLE_BY_ID='/get-article-by-id',
  SEND_EMAIL='/send-email',
  ADD_CLIENT_MESSAGE='/add-client-message',
};

export const logIn = (username: string, password: string) => {
  return axios.post(
    `${API_URL}${API_ROUTES.LOG_IN}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`,
      }
    },
  ).then(response => {
    return response?.data;
  }).catch(e => {
    return e.response.data;
  });
};


export const getProductById = (id: string) => {
  if (!id) return;
  const requestBody = {
    id: id,
  };

  return axios.post<IProductExtended>(
    `${TEST_API_URL}${API_ROUTES.GET_PRODUCT_BY_ID}`,
    requestBody,
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
      },
    },
  ).then(response => {
    return response.data;
  }).catch(e => {});
};

interface IGetAllProductsByIds {
  ids: string[];
  fields?: string[];
}

export const getAllProductsByIds = ({ ids, fields = [] }: IGetAllProductsByIds) => {
  return axios.post(
    `${TEST_API_URL}${API_ROUTES.GET_ALL_PRODUCTS_BY_IDS}`,
    { ids, fields },
    { ...requestOptions },
  ).then((response) => {
    const responseData = response.data;

    return responseData?.items;
  }).catch((error) => {});
};

export interface ISearchProductsRequestProps {
  from?: number;
  size?: number;
  page?: number;
  query?: string;
  checkboxes?: IFilterQueryCheckbox | null;
  idList?: string[];
  availability?: boolean;
  sort?: {
    field: string;
    order: 'acs' | 'desc';
  }[];
  extraFields?: string[];
};

export interface ISearchVariantsRequestProps {
  from?: number;
  size?: number;
  query?: string;
  currentVariant: IProductVariant;
  categoryId: string;
  vendor: string;
};

export const searchProducts = ({
  size = SEARCH_RESULTS_SIZE,
  page = 1,
  query = '',
  checkboxes = null,
  availability = true,
  sort = [],
  extraFields = [],
}: ISearchProductsRequestProps): Promise<IProductSearchResult | void> => {

  const filterQuery = checkboxes
    ? (Object.keys(checkboxes) as Array<keyof typeof checkboxes>)
      .filter((key) => checkboxes[key].length > 0)
      .map((key) => ({ terms: { [key]: checkboxes[key] } }))
    : [];

  return axios.post(
    `${TEST_API_URL}${API_ROUTES.SEARCH_PRODUCTS}`,
    {
      from: (page - 1) * size,
      size: size,
      query: query,
      filter: filterQuery,
      availability: availability,
      sort: sort,
    },
    {
      ...requestOptions,
      headers: { ...requestOptions.headers },
    },
  )
    .then((response) => {
      const responseData: IOpenSearchProduct | undefined = response.data;
      const searchResults = responseData?.hits?.hits;
      const total = responseData?.hits?.total?.value || 0;

      if (!searchResults) {
        return {
          results: [],
          pagination: { total, page, totalPages: 0 },
        };
      }

      const formattedResults = searchResults.map((item) => (
        { ...item._source, id: item._id }
      ));

      const pagination = { total, page, totalPages: Math.ceil(total / size) };

      if (extraFields.length) {
        const ids = formattedResults.map((item) => item.id);

        return getAllProductsByIds({ ids, fields: ['id', ...extraFields] })
          .then((fieldsRes) => {
            const resultsWithExtraFields = formattedResults.map((item) => {
              const newFields = fieldsRes.find((fieldsItem: Partial<IProduct>) => fieldsItem.id === item.id);

              return { ...item, ...newFields };
            });

            return { results: resultsWithExtraFields, pagination };
          });

      } else {
        return { results: formattedResults, pagination };
      }
    })
    .catch((error) => {});
};

export const searchVariants = ({
  from = 0,
  size = 200,
  query = '',
  currentVariant,
  categoryId,
  vendor,
}: ISearchVariantsRequestProps): Promise<{ hits: IVariantHit[]; uniqueVariants: IVariantList[] }> => {
  const filterQuery = [
    { term: { "vendor": vendor } },
    { term: { "categoryId": categoryId } },
  ];
  
  return axios.post(
    `${TEST_API_URL}${API_ROUTES.SEARCH_VARIANTS}`,
    {
      from: from,
      size: size,
      query: query,
      filter: filterQuery,
    },
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
      },
    },
  ).then(response => {
    const responseData: IOpenSearchProduct = response.data;
    const aggregations = responseData.aggregations?.variant || {};
    const hits = responseData.hits.hits.map((item) => {
      return ({
        id: item._id,
        variant: item._source.variant,
        score: 0,
        quantity: item._source.quantity,
      });
    });

    const uniqueVariants = (Object.keys(currentVariant) as Array<keyof typeof currentVariant>).map(key => {
      const uniqueValues = aggregations[key].buckets.map(bucket => bucket.key);
      const variantLinks = uniqueValues.map(uniqueValue => {
        const linkItem = hits.find(hit => hit.variant[key] === uniqueValue);
        return ({
          id: linkItem?.id ?? '',
          label: uniqueValue,
        });
      });
      
      return ({
        name: key,
        values: variantLinks,
      })
    });

    return {
      hits: hits,
      uniqueVariants: uniqueVariants,
    };
  });
};

export const searchFilters = ({
  from = 0,
  size = 0,
  query = '',
}: ISearchProductsRequestProps) => {
  return axios.post(
    `${TEST_API_URL}${API_ROUTES.SEARCH_FILTERS}`,
    {
      from: from,
      size: size,
      query: query,
    },
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
      },
    },
  ).then(response => {
    const responseData: IOpenSearchProduct = response.data;
    const aggregations = responseData.aggregations?.variant || {};

    const DICTIONARY: {
      [key: string]: string
    } = {
      "vendor": "Виробник",
      "country": "Країна",
    }

    const filters = Object.keys(aggregations).map(key => {
      const buckets = aggregations[key].buckets;
      const list = buckets.map((bucket) => ({
        id: bucket.key,
        label: bucket.key,
        isChecked: false,
      }));

      return ({
        id: key,
        label: DICTIONARY[key] || key,
        list: list,
      })
    })

    return filters;
  });
};

export const getClientDiscount = (auth: IAuth, phone: string) => {
  const requestBody = {
    phone: phone,
  };
  return axios.post(
    `${API_URL}${API_ROUTES.GET_CLIENT_DISCOUNT}`,
    requestBody,
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        'Authorization': `Bearer ${auth.token}`,
      },
    },
  ).then(response => {
    return {
      phone: response.data.phone,
      discount: response.data.discount,
      isFixed: response.data.is_fixed,
    };
  }).catch(e => {
    throw new Error(e);
  });
};

export const addClientDiscount = (auth: IAuth, clientDiscountData: IClientDiscountData) => {
  return axios.post(
    `${API_URL}${API_ROUTES.SUBMIT_CLIENT_DISCOUNT}`,
    {
      phone: clientDiscountData.phone,
      payment: clientDiscountData.payment,
      fixedDiscount: clientDiscountData.fixedDiscount,
    },
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        'Authorization': `Bearer ${auth.token}`,
      },
    },
  ).then(response => {
    return response;
  }).catch(e => {});
};

export const createBackup = () => {
  return axios.post(
    `${API_URL}${API_ROUTES.CREATE_BACKUP}`,
    {},
    requestOptions,
  ).then(response => {
    return response;
  }).catch(e => {});
};

export const getBackups = () => {
  return axios.get(
    `${API_URL}${API_ROUTES.GET_BACKUPS}`,
    {
      ...requestOptions,
    },
  ).then(response => {
    return response;
  }).catch(e => {});
};

export const getNovaCities = async (city: string): Promise<INovaCity[]> => {
  const response = await axios.post(NOVA_API_URL, {
    apiKey: NOVA_API_KEY,
    modelName: 'Address',
    calledMethod: 'searchSettlements',
    methodProperties: {
      CityName: city,
      Limit: '50',
    },
  });

  return response.data.data[0].Addresses;
};

export const getNovaWarehouses = async (deliveryCity: string, street: string): Promise<INovaWarehouses[]> => {
  const response = await axios.post(NOVA_API_URL, {
    apiKey: NOVA_API_KEY,
    modelName: 'Address',
    calledMethod: 'getWarehouses',
    methodProperties: {
      CityRef: deliveryCity,
      FindByString: street,
      Limit: '50',
    },
  });

  return response.data.data;
};

export enum CREATE_ORDER_RESPONSE_TYPE {
  AVAILABLE='available',
  NOT_AVAILABLE='not_available',
}

export interface ICreateOrderResponse {
  type: CREATE_ORDER_RESPONSE_TYPE;
  notAvailableProducts: string[];
  orderId: string;
}
 
export const createOrder = async (orderProducts: IOrderProduct[], userData: IUserDataContext): Promise<ICreateOrderResponse> => {
  return axios.post(
    `${API_URL}${API_ROUTES.CREATE_ORDER}`,
    {
      orderProducts: orderProducts,
      userData: userData,
    },
    {...requestOptions}
  ).then(response => {
    return response.data;
  }).catch(e => {});
};

export const getOrdersById = async (orderIds: string[]): Promise<IOrder[]> => {
  return axios.post(
    `${TEST_API_URL}${API_ROUTES.GET_ORDERS_BY_ID}`,
    { orderIds },
    { ...requestOptions }
  ).then((response) => {
    const rawOrders = response.data;
    if (rawOrders) {
      return rawOrders.map((order: IOrderRaw) => ({
        ...order,
        orderProducts: JSON.parse(order.orderProducts)
      }));
    }
    return [];
  }).catch((error) => {});
};

export const getOrdersAdmin = async (auth: IAuth): Promise<IOrder[]> => {
  return axios.post(
    `${TEST_API_URL}${API_ROUTES.GET_ORDERS}`,
    {},
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        'Authorization': `Bearer ${auth.token}`,
      },
    },
  ).then((response) => {
    return response.data;
  }).catch((error) => {});
};

export const getOrderByNumber = async (id: string, phoneNumber: string): Promise<IOrder>  => {
  return axios.post(
    `${TEST_API_URL}${API_ROUTES.GET_ORDER_BY_NUMBER}`,
    { id, phoneNumber },
    { ...requestOptions }
  ).then((response) => {
    const rawOrder = response.data;
    if (rawOrder) {
      return {
        ...rawOrder,
        orderProducts: JSON.parse(rawOrder.orderProducts)
      }
    }
  }).catch((error) => {});
};

export const getTotalArticles = async (): Promise<IGetTotalArticlesResponse[]> => {
  return axios.post(
    `${API_URL}${API_ROUTES.GET_TOTAL_ARTICLES}`,
    {},
    { ...requestOptions }
  ).then((response) => {
    return response.data;
  }).catch((error) => {});
};

export const getArticleById = async (id: string): Promise<IArticle> => {
  return axios.post(
    `${API_URL}${API_ROUTES.GET_ARTICLE_BY_ID}`,
    { id },
    { ...requestOptions }
  ).then((response) => ({
    ...response.data,
    content: JSON.parse(response.data.content),
    header: JSON.parse(response.data.header),
  })).catch((error) => {});
};

export const sendEmail = async (data: OfferFormDataType) => {
  return axios.post(
    `${API_URL}${API_ROUTES.ADD_CLIENT_MESSAGE}`,
    {
      name: [data.firstName, data.lastName].join(' '),
      email: data.email,
      message: data.message,
    },
    { ...requestOptions }
  ).then((response) => {
    if (response.status === 200) {
      return { status: 200 }
    }
  }).catch((response) => {
    return { status: response.status }
  });
};
