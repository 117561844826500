import { useEffect, useReducer, useState } from "react";
import { formatPhone, reformatPhone, isPhoneValid } from "../../util/formatData";
import { useCartContext, useUserContext, useCartDispatchContext } from "../../contexts/ProductCartContext";
import { CURRENCY_SYMBOL, DELIVERY_OPTIONS, LOCALSTORAGE_ORDERS_IDS } from "../../constants/appConfig";
import { ScreenWrapper, CartListWrapper, Control, Title, Labels, RowsWrapper, TotalPrice, FormWrapper, FieldsWrapper, ErrorToast, FormButton, SuccessToast } from "./styles";
import { INovaCity, INovaWarehouses } from "../../types/api";
import { CREATE_ORDER_RESPONSE_TYPE, createOrder, getNovaCities, getNovaWarehouses } from "../../util/api";
import { SEARCH_CITY_REGEX, SEARCH_WAREHOUSE_REGEX } from "../../constants/regex";
import { CartSection, InputField, InputSelect, RadioGroup } from "../../components";
import { DEFAULT_ORDER, DELIVERY_TYPE, ICartContext, IOrderDetails, IUserDataContext, ORDER_ERROR } from "../../types/orders";
import { ORDER_DETAILS_ACTION_TYPE, OrderDetailsAction } from "../../types/reducers";
import { useNavigate } from "react-router-dom";

const orderDetailsReducer = (orderDetails: IOrderDetails, action: OrderDetailsAction) => {
  switch (action.type) {
    case ORDER_DETAILS_ACTION_TYPE.SET: {
      const { type, ...newDetails } = action;
      return {
        ...orderDetails,
        ...newDetails,
      };
    }
    case ORDER_DETAILS_ACTION_TYPE.CLEAR: {
      return DEFAULT_ORDER;
    }
    default: {
      return orderDetails;
    }
  };
};

const CartScreen = () => {
  const [orderDetails, dispatchOrderDetails] = useReducer(orderDetailsReducer, DEFAULT_ORDER);
  const [cityOptions, setCityOptions] = useState<INovaCity[]>([]);
  const [warehouseOptions, setWarehouseOptions] = useState<INovaWarehouses[]>([]);
  const [notAvailableProducts, setNotAvailableProducts] = useState<{
    id: string,
    vendor: string,
    name: string,
  }[]>([]);
  const [error, setError] = useState<ORDER_ERROR>(ORDER_ERROR.NONE);
  const [success, setSuccess] = useState<string>('');

  const cartContext: ICartContext[] | null = useCartContext();
  const userContext: IUserDataContext = useUserContext();
  const cartDispatchContext = useCartDispatchContext();

  const navigate = useNavigate();

  useEffect(() => {
    dispatchOrderDetails({
      type: ORDER_DETAILS_ACTION_TYPE.SET,
      firstName: userContext.firstName,
      lastName: userContext.lastName,
      phoneNumber: userContext.phoneNumber,
      deliveryType: userContext.deliveryType,
      city: userContext.city,
      warehouse: userContext.warehouse,
    });
  }, [userContext]);

  const {
    removeProductFromCart,
    incrementProductInCart,
    decrementProductInCart,
    addContactInformation,
    addNovaCity,
    addNovaWarehouse,
    clearCart,
  } = cartDispatchContext;

  const totalPrice: number | undefined = cartContext?.reduce((price, product: ICartContext) => 
    price + (product.price * product.count || 0), 0);

  const fetchNovaCities = async (value: string) => {
    const filteredValue = value.replace(SEARCH_CITY_REGEX, '');
    dispatchOrderDetails({ type: ORDER_DETAILS_ACTION_TYPE.SET, city: filteredValue });
    
    const response = await getNovaCities(value);
    setCityOptions(response);
  };

  const fetchNovaWarehouses = async (deliveryCity: string, street: string) => {
    const filteredStreet = street.replace(SEARCH_WAREHOUSE_REGEX, '');
    dispatchOrderDetails({ type: ORDER_DETAILS_ACTION_TYPE.SET, warehouse: filteredStreet });

    const response = await getNovaWarehouses(deliveryCity, filteredStreet);
    setWarehouseOptions(response);
  };

  const requestOrder = async (): Promise<void> => {
    const { firstName, lastName, phoneNumber } = orderDetails;
    const { deliveryType } = userContext;
    // addContactInformation(firstName, lastName, phoneNumber, deliveryType);

    //const requiredUserDataFields = (Object.keys(DEFAULT_ORDER) as Array<keyof IUserDataContext>);
    //const emptyUserDataFields = requiredUserDataFields.filter((field) => !userContext[field]);

    const isComplete = userContext.firstName
      && userContext.lastName
      && userContext.phoneNumber
      && (
        userContext.deliveryType === DELIVERY_TYPE.MALL
        || (
          userContext.deliveryType === DELIVERY_TYPE.NOVA
          && userContext.city
          && userContext.warehouse
        )
      );

    if (!cartContext?.length) {
      setError(ORDER_ERROR.EMPTY_CART);
    } else if (!isComplete) {
      setError(ORDER_ERROR.EMPTY_FORM);
    } else if (!isPhoneValid(phoneNumber)) {
      setError(ORDER_ERROR.WRONG_NUMBER);
    } else {
      const orderProducts = cartContext.map((product) => ({
        id: product.id,
        name: product.name,
        price: product.price,
        count: product.count,
      }));
      const response = await createOrder(orderProducts, userContext);
      
      if (response.type === CREATE_ORDER_RESPONSE_TYPE.NOT_AVAILABLE) {
        setError(ORDER_ERROR.NONE);
        const notAvailableProducts = cartContext.filter(product => response.notAvailableProducts.includes(product.id));
        setNotAvailableProducts(notAvailableProducts);
      }

      if (response.type === CREATE_ORDER_RESPONSE_TYPE.AVAILABLE) {
        setSuccess('Замовлення прийнято');
        clearCart();
        setError(ORDER_ERROR.NONE);

        let ordersIds: string | string[] | null;

        ordersIds = localStorage.getItem(LOCALSTORAGE_ORDERS_IDS);

        if (!ordersIds) {
          ordersIds = [];
        } else {
          ordersIds = JSON.parse(ordersIds) as string[];
        }

        ordersIds = [...ordersIds, response.orderId];

        localStorage.setItem(LOCALSTORAGE_ORDERS_IDS, JSON.stringify(ordersIds));

        navigate(`/success-order/${response.orderId}`);
      }
    }
  };

  return (
    <ScreenWrapper> 
      <CartListWrapper>
        <Control>
          <Title>Кошик</Title>
          {notAvailableProducts.length ? (
            <ErrorToast prefix='Деяких товарів немає в наявності: '>
              {notAvailableProducts.map((product) => ` ${product.vendor}  ${product.name}`)}
            </ErrorToast>
          ) : null}
          <Labels>
            <div />
            <div>Назва</div>
            <div>Ціна</div>
            <div>Кількість</div>
            <div />
          </Labels>
        </Control>
        <RowsWrapper>
          {cartContext?.map((product) => (
            <CartSection
              key={product.id}
              id={product.id}
              imageSrc={product.imageSrc} 
              brand={product.vendor}
              name={product.name}
              country={product.country}
              price={product.price}
              count={product.count}
              deleteProduct={() => removeProductFromCart(product.id)}
              incrementProduct={() => incrementProductInCart(product.id)}
              decrementProduct={() => decrementProductInCart(product.id)}
            />
          ))}
        </RowsWrapper>
        <TotalPrice>
          Загальна вартість: {totalPrice} {CURRENCY_SYMBOL}
        </TotalPrice>
      </CartListWrapper>
      <FormWrapper>
        <FieldsWrapper>
          <InputField
            label="Ім'я"
            value={orderDetails.firstName}
            onChange={(e) => {
              dispatchOrderDetails({ type: ORDER_DETAILS_ACTION_TYPE.SET, firstName: e.target.value });
              addContactInformation('firstName', e.target.value);
            }}
          />
          <InputField
            label="Прізвище"
            value={orderDetails.lastName}
            onChange={(e) => {
              dispatchOrderDetails({ type: ORDER_DETAILS_ACTION_TYPE.SET, lastName: e.target.value });
              addContactInformation('lastName', e.target.value);
            }}
          />
          <InputField
            label='Телефон'
            placeholder='+380 (00) 000 00 00'
            value={formatPhone(orderDetails.phoneNumber)}
            onChange={(event) => {
              const value = reformatPhone(event);

              if (value.length <= 12) {
                dispatchOrderDetails({ type: ORDER_DETAILS_ACTION_TYPE.SET, phoneNumber: value });
                addContactInformation('phoneNumber', value);
              }
            }}
          />
        </FieldsWrapper>
        <RadioGroup
          id="groupId"
          label="Спосіб доставки:"
          radioList={DELIVERY_OPTIONS.map(item => ({ ...item, isChecked: item.id === orderDetails.deliveryType }))}
          onClick={(id) => {
            dispatchOrderDetails({ type: ORDER_DETAILS_ACTION_TYPE.SET, deliveryType: id });
            addContactInformation('deliveryType', id);
          }}
        />
        {orderDetails.deliveryType === DELIVERY_TYPE.NOVA && (
          <InputSelect
            label={userContext.city ? `Місто - ${userContext.city}` : 'Місто'}
            placeholder="Місто..."
            value={orderDetails.city}
            onFocus={(e) => fetchNovaCities(e.target.value)}
            onChange={(e) => fetchNovaCities(e.target.value)}
            options={cityOptions.map(city => ({ label: city.Present, value: city.DeliveryCity }))}
            onSelectOption={(option) => {
              dispatchOrderDetails({ type: ORDER_DETAILS_ACTION_TYPE.SET, city: option.label, warehouse: '' });
              setCityOptions([]);
              setWarehouseOptions([]);
              addNovaCity(option.label, option.value);
            }}
          />
        )}
        {orderDetails.deliveryType === DELIVERY_TYPE.NOVA && userContext.city && (
          <InputSelect
            label={userContext.warehouse ? `Відділення - ${userContext.warehouse}` : 'Відділення'}
            placeholder="Відділення..."
            value={orderDetails.warehouse}
            onFocus={(e) => fetchNovaWarehouses(userContext.deliveryCity, e.target.value)}
            onChange={(e) => fetchNovaWarehouses(userContext.deliveryCity, e.target.value)}
            options={warehouseOptions.map(warehouse => ({ label: warehouse.Description, value: warehouse.Ref }))}
            onSelectOption={(option) => {
              dispatchOrderDetails({ type: ORDER_DETAILS_ACTION_TYPE.SET, warehouse: option.label });
              setWarehouseOptions([]);
              addNovaWarehouse(option.label, option.value);
            }}
          />
        )}
        {error && <ErrorToast prefix='Помилка: '>{error}</ErrorToast>}
        {!error && success && <SuccessToast prefix=''>{success}</SuccessToast>}
        <FormButton onClick={() => requestOrder()}>Замовити</FormButton>
      </FormWrapper>
    </ScreenWrapper>
  )
};

export { CartScreen };
