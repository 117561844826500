import { FC } from 'react';
import { Link, useParams } from "react-router-dom";

import { PATHS } from "../../constants/appConfig";
import { Title, OrderNumberTitle, OrderNumber } from './styles';
import { AppButton, FlexContainer, PageWrapper } from "../../components";

const SuccessOrderScreen: FC = () => {
  const { id } = useParams();

  return (
    <PageWrapper display='flex'>
      <FlexContainer direction='column' justify='center' align='center' width='100%'>
        <Title>Дякуємо за замовлення!</Title>
        <OrderNumberTitle>Номер Вашого замовлення: <OrderNumber>{id}</OrderNumber></OrderNumberTitle>
        <Link to={PATHS.main}><AppButton>На головну</AppButton></Link>
      </FlexContainer>
    </PageWrapper>
  );
};

export default SuccessOrderScreen;
