export const colors = {
  backgroundWhite: '#ffffff',
  backgroundSnow: '#fdfdfd',
  backgroundLight: '#f5f5f5',
  textWhite: '#ffffff',
  textLight: '#eeeeee',
  textDark: '#333333',
  textError: '#dc3545',
  textWarning: '#ffc107',
  paleYellow: '#f7ba83',
  paleOrange: '#ffffe0',
  palestYellow: '#fff3cd',
  paleRed: '#f8d7da',
  paleGreen: '#e2fee5',
  paleGrey: '#a3a3a3',
  highlightBlue: '#3f83ae',
  highlightYellow: '#f98012',
  highlightRed: '#e84b56',
  highlightGreen: '#2ba676',
  highlightPurple: '#673ab7',
  darkBlue: '#26526e',
  darkYellow: '#cb8a4f',
  darkRed: '#99121c',
  darkGreen: '#106946',
  darkPurple: '#462188',
  darkGrey: '#747474',
  borderLight: '#dee2e6',
  borderDark: '#194866',
  overlayDark: '#8d8d8db3',
};

export const statusColors = {
  created: '#b7aa00',
  confirmed: '#e0af00',
  sent: '#33a4a4',
  delivered: '#336699',
  done: '#66CC33',
  canceled: '#FF3333',
};

export const marketplaceColors = {
  white: '#FFFFFF',
  danger: '#EA4B48',
  dangerDark: '#c32825',
  dangerLight: '#daa3a3',
  warning: '#FF8A00',
  success: '#00B207',
  primary: '#3f54f3',
  primaryLight: '#8997ff',
  primaryDark: '#1426ae',
  primaryGhost: 'rgba(86, 86, 172, 0.10)',
  primaryGhostDark: 'rgba(44, 44, 116, 0.20)',
  black: '#000000',
  gray0: '#F2F2F2',
  gray1: '#E6E6E6',
  gray2: '#CCCCCC',
  gray3: '#B3B3B3',
  gray4: '#999999',
  gray5: '#808080',
  gray6: '#666666',
  gray7: '#4D4D4D',
  gray8: '#333333',
  gray9: '#1A1A1A',
  placeholder: '#767676',
};

export const filterLabelColors = {
  blue: '#B8DAFFD9',
  green: '#A3E59BD9',
  pink: '#fb99d9',
}

export const breakpoints = {
  xxs: '300px',
  xs: '400px',
  sm: '600px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export const animations = {
  timeDefault: '0.3s',
  timeFast: '0.1s',
  timeSlow: '1s',
};

export const layers = {
  staticLayout: 100,
  header: 200,
  modalOverlay: 300,
  modal: 310,
};
