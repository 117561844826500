import { FC, useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";

import { categories } from "../../articles";
import { PATHS, S3Link } from "../../constants/appConfig";
import { getTotalArticles } from "../../util/api";
import { IGetTotalArticlesResponse } from "../../types/api";
import {
  PageWrapper,
  Title,
  CategoryBlock,
  GridWrapper,
  ArticleBlock,
  Breadcrumbs,
} from "../../components";

const ArticlesScreen: FC = () => {
  const { category: categoryUrl } = useParams();
  const categoryName = categories.find((category) => category.url === categoryUrl);
  const [articles, setArticles] = useState<IGetTotalArticlesResponse[]>([]);

  useEffect(() => {
    getTotalArticles().then((response) => setArticles(response));
  }, []);

  return (
    <PageWrapper>
      {categoryUrl ? (<Breadcrumbs><Link to={PATHS.articles}>← Блог</Link> / {categoryName?.title}</Breadcrumbs>) : null}

      <Title>{categoryUrl ? `Категорія: ${categoryName?.title}` : 'Блог'}</Title>

      {categoryUrl ? null : (
        <GridWrapper columns={2} columnsLg={6}>
          {categories.map((category) => (
            <Link key={category.title} to={category.url}><CategoryBlock image={category.image} title={category.title} /></Link>
          ))}
        </GridWrapper>
      )}

      <GridWrapper columns={3} columnsLg={5}>
        {articles.map((article) => {
          const shouldRender = categoryUrl === article.categoryId;

          return (!categoryUrl || shouldRender) ? (
            <Link key={article.id} to={categoryUrl ? article.id : `${article.categoryId}/${article.id}`}>
              <ArticleBlock
                titleImage={`${S3Link}/${article.id}/titleImage.png`}
                title={article.title}
                category={article.category}
              />
            </Link>
          ) : null;
        })}
      </GridWrapper>
    </PageWrapper>
  );
};

export default ArticlesScreen;
