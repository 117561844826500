import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { filterLabelColors, marketplaceColors } from "../../constants/styleConfig";

interface ITagStyle {
  type: string;
}

export const TagContainer = styled.div<ITagStyle>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 25px;
  border: 1px solid ${(props) => {
    switch (props.type) {
      case 'categoryId':
        return filterLabelColors.blue;
      case 'vendor':
        return filterLabelColors.green;
      default:
        return filterLabelColors.pink;
    }
  }};
`;

export const Text = styled.p`
  margin: 0;
  padding-right: 8px;
  color: ${marketplaceColors.gray6};
  white-space: nowrap;
`;

export const DeleteIcon = styled(IoClose)<ITagStyle>`
  cursor: pointer;
  fill: ${(props) => {
    const { blue, green } = filterLabelColors;

    switch (props.type) {
      case 'category':
        return blue;
      case 'brand':
        return green;
    }
}}
`;
