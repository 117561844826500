import { useEffect, useState } from "react";
import { IVariantHit, IVariantList } from "../../types/products";
import {
  InfoContainer,
  OrderButtonsWrapper,
  ProductAvailability,
  ProductBrand,
  ProductInfo,
  ProductMainInfo,
  ProductName,
  ProductParameterContainer,
  ProductPrice,
  ProductPriceContainer,
  ProductVariantsContainer,
  VariantName,
  VariantRowContainer,
} from "./styles";
import { useCartDispatchContext } from "../../contexts/ProductCartContext";
import { AppButton } from "../Button/Button";
import { CURRENCY_SYMBOL, PATHS } from "../../constants/appConfig";
import { searchVariants } from "../../util/api";
import { AppCarousel } from "../AppCarousel/AppCarousel";
import { VARIANT_DICTIONARY } from "../../constants/dictionary";
import VariantSelect from "../VariantSelect/VariantSelect";
import DescriptionInfo from "./DescriptionInfo";

interface ICatalogItemInfoProps {
  productData: {
    id: string;
    categoryId: string[];
    descriptionUA: string;
    nameUA: string;
    params: string;
    pictures: string[];
    price: number;
    quantity: number;
    variant: {
      flavor?: string;
      size?: string;
      color?: string;
    };
    vendor: string;
    vendorCode: string;
    country: string;
  };
};

const CatalogItemInfo = ({
  productData: {
    id,
    categoryId,
    nameUA,
    pictures,
    price,
    quantity = 0,
    vendor,
    vendorCode,
    country,
    descriptionUA,
    params,
    variant,
  },
}: ICatalogItemInfoProps) => {
  const [isMarked, setIsMarked] = useState<boolean>(false);
  const [variants, setVariants] = useState<IVariantList[]>([]);
  const [variantHits, setVariantHits] = useState<IVariantHit[]>([]);
  const cartDispatchContext = useCartDispatchContext();

  useEffect(() => {
    const bookmarks: string[] = localStorage.getItem('bookmarks')?.split(',') || [];
    setIsMarked(bookmarks.includes(id));

    const isSameVariantScope = variants.find(variantItem => {
      return variantItem.values.find(value => value.id === id);
    });

    if (!isSameVariantScope) {
      searchVariants({
        query: `${nameUA.replace(/,.*/g, '')},`,
        currentVariant: variant,
        categoryId: categoryId[categoryId.length - 1],
        vendor: vendor,
      }).then((res) => {
        setVariants(res.uniqueVariants);
        setVariantHits(res.hits);
      });
    };
  }, [id]);

  const isAvailable = quantity > 0;

  const orderItem = () => {
    cartDispatchContext.addProductToCart({
      id,
      vendor,
      name: nameUA,
      country,
      price,
      variant: variant,
      imageSrc: pictures[0],
      count: 1,
    });
  };

  return (
    <InfoContainer>
      <ProductMainInfo>
        <div style={{ maxWidth: '400px', width: '100%', flex: '0 0 auto' }}>
          <AppCarousel>
            {pictures.map(src => {
              const alt = src.replace(/\.*/g, '').split('/')[-1];

              return (
                <div key={`img_${src}`} style={{ maxHeight: '400px', height: '100%' }}>
                  <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={src} alt={alt} />
                </div>
              );
            })}
          </AppCarousel>
        </div>
        <ProductInfo>
          <ProductName>{nameUA}</ProductName>
          <div></div>
          {/* <BookmarkIcon
            onClick={() => {
              const bookmarks: string[] = localStorage.getItem('bookmarks')?.split(',') || [];
              if (isMarked) {
                localStorage.setItem('bookmarks', bookmarks.filter(bookmarkId => bookmarkId !== id).join(','));
              } else {
                localStorage.setItem('bookmarks', [...bookmarks, id].join(','));
              }
              setIsMarked(prev => !prev);
            }}
            isActive={isMarked}
            title='Додати до закладок'
          /> */}
          <ProductBrand><b>Виробник:</b> {vendor}</ProductBrand>
          {country && <ProductBrand><b>Країна:</b> {country}</ProductBrand>}
          <ProductPriceContainer>
            {!!quantity && (<ProductPrice>{price} {CURRENCY_SYMBOL}</ProductPrice>)}
            {/* {priceOld && <ProductPrice isOld>{priceOld} {CURRENCY_SYMBOL}</ProductPrice>} */}
          </ProductPriceContainer>
          <ProductVariantsContainer>
            {variants.map((variantRow) => {
              const otherVariantKeys = (Object.keys(variant) as Array<keyof typeof variant>).filter(key => key !== variantRow.name);
              const scoredHits = variantHits.map(hit => {
                let score = 0;
                otherVariantKeys.forEach(key => {
                  if (hit.variant[key] === variant[key]) {
                    score += 1;
                  }
                })
                return {
                  ...hit,
                  score: score,
                }
              }).sort((a, b) => b.score - a.score);

              const variantLinks = variantRow.values.map((variantItem) => {
                let topHit = scoredHits
                  .filter((hit) => hit.quantity > 0)
                  .find((hit) => hit.variant[variantRow.name] === variantItem.label);
                if (!topHit) {
                  topHit = scoredHits.find(hit => hit.variant[variantRow.name] === variantItem.label);
                }
                if (!topHit) {
                  topHit = variantHits.find(hit => hit.variant[variantRow.name] === variantItem.label) || variantHits[0];
                }

                const otherLabels = otherVariantKeys.map((key) => topHit?.variant[key]);

                return {
                  key: `${id}_${variantRow.name}_${variantItem.id}`,
                  label: variantItem.label,
                  secondaryLabel: otherLabels.length ? `(${otherLabels.join(', ')})` : '',
                  to: PATHS.product.replace(':id', topHit.id),
                  isActive: variantItem.label === variant[variantRow.name],
                  isFullMatch: topHit.score === otherVariantKeys.length,
                  isDisabled: topHit.quantity === 0,
                }
              });

              return (
                <VariantRowContainer key={`${id}_${variantRow.name}`}>
                  <VariantName>{VARIANT_DICTIONARY[variantRow.name]}</VariantName>
                  <VariantSelect
                    selectedVariant={variant[variantRow.name] || ''}
                    options={variantLinks}
                  />
                </VariantRowContainer>
              )
            })}
          </ProductVariantsContainer>
          <ProductParameterContainer>
            {/* {parameters.map((paramItem, paramIndex) => (
              <React.Fragment key={`${id}_param_${paramIndex}`}>
                <b>{paramItem.name}</b>
                <ProductParameterValue>{paramItem.value}</ProductParameterValue>
              </React.Fragment>
            ))} */}
          </ProductParameterContainer>
          <ProductAvailability isAvailable={isAvailable}>{isAvailable ? 'В наявності' : 'Немає в наявності'}</ProductAvailability>
          <OrderButtonsWrapper>
            <AppButton onClick={() => orderItem()} isDisabled={!isAvailable}>
              Замовити
            </AppButton>
            {/* {!isAvailable && <AppButton> Повідомити про наявність </AppButton>} */}
          </OrderButtonsWrapper>
        </ProductInfo>
      </ProductMainInfo>
      <DescriptionInfo
        id={id}
        descriptionUA={descriptionUA}
      />
    </InfoContainer>
  );
};

export { CatalogItemInfo };
