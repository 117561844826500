import { FC, useState } from 'react';

import { DELIVERY_STATUS, IOrderProduct } from "../../types/orders";
import {
  Wrapper,
  Header,
  OrderNumber,
  Status,
  StatusBlock,
  Arrow,
  OrderDetails,
  Text,
  DottedLine,
  List,
  ListItem,
} from "./styles";
import { CROSS_SYMBOL, CURRENCY_SYMBOL, NUMBER_SYMBOL } from "../../constants/appConfig";
import FlexContainer from "../FlexContainer";
import { RENDER_STATUSES } from '../AdminTable/config';

interface IOrderBlockProps {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  city: string;
  orderProducts: IOrderProduct[];
  isCloseable?: boolean;
  status: DELIVERY_STATUS;
}

const OrderBlock: FC<IOrderBlockProps> = ({
  id,
  firstName,
  lastName,
  phoneNumber,
  city,
  orderProducts,
  isCloseable = true,
  status,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const actualStatus = RENDER_STATUSES[status];

  const handleHeaderClick = (): void => {
    setIsOpen((prev) => !prev);
  };

  const calculateTotalPrice = (): number => {
    return orderProducts.reduce((total, orderProduct): number => {
      return total + (orderProduct.count * orderProduct.price);
    }, 0);
  };

  return (
    <Wrapper>
      <Header onClick={() => isCloseable && handleHeaderClick()} $isCloseable={isCloseable}>
        <OrderNumber>{NUMBER_SYMBOL}{id}</OrderNumber>
        {(isCloseable && !isOpen) && <StatusBlock status={status}>{actualStatus}</StatusBlock>}
        {isCloseable && <Arrow isOrderBlockOpen={isOpen} />}
      </Header>
      {(isOpen || !isCloseable) && (
        <OrderDetails>
          <Text><b>Ім'я: </b>{firstName} {lastName}</Text>
          <Text><b>Адреса: </b>{city}</Text>
          <Text><b>Номер телефону: </b>{phoneNumber}</Text>
          <Text><b>Статус: <Status status={status}>{actualStatus}</Status></b></Text>
          <DottedLine />
          <Text><b>Замовлення:</b></Text>
          <List>
            {orderProducts.map((orderProduct) => (
              <ListItem key={orderProduct.id}>
                <Text>{orderProduct.name}</Text>
                <FlexContainer justify='space-between'>
                  <Text>{orderProduct.count} шт. {CROSS_SYMBOL} {orderProduct.price} {CURRENCY_SYMBOL}</Text>
                  <Text>{orderProduct.count * orderProduct.price} {CURRENCY_SYMBOL}</Text>
                </FlexContainer>
              </ListItem>
            ))}
          </List>
          <DottedLine />
          <FlexContainer justify='flex-end'>
            <Text><b>Сума: </b>{calculateTotalPrice()} {CURRENCY_SYMBOL}</Text>
          </FlexContainer>
        </OrderDetails>
      )}
    </Wrapper>
  );
};

export default OrderBlock;
