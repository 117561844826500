import { Link } from "react-router-dom";
import styled from "styled-components";
import { marketplaceColors } from "../../constants/styleConfig";
import { FaAngleDown } from "react-icons/fa";

interface IVariant {
  $isActive: boolean;
  $isFullMatch: boolean;
  $isDisabled: boolean;
};

export const Wrapper = styled.div`
  position: relative;
  font-size: 1rem;
  cursor: pointer;
`;

export const SelectedVariantContainer = styled.div<{ $isOpen: boolean; }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border: 1px solid ${marketplaceColors.gray1};
  border-radius: ${({ $isOpen }) => $isOpen ? '4px 4px 0 0' : '4px'};
  background-color: ${marketplaceColors.white};
  user-select: none;
`;

export const Label = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AngleIcon = styled(FaAngleDown)<{ $isOpen: boolean; }>`
  transform: rotate(${({ $isOpen }) => $isOpen ? '180deg' : '0'});
`;

export const OptionsContainer = styled.div`
  position: absolute;
  z-index: 1;
  transform: translateY(-1px);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 50vh;
  border: 1px solid ${marketplaceColors.gray1};
  border-radius: 0 0 4px 4px;
  background-color: ${marketplaceColors.white};
  overflow-y: auto;
`;

export const Option = styled(Link)<IVariant>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  color: ${({ $isActive, $isFullMatch, $isDisabled }) => {
    if ($isActive) return marketplaceColors.primaryDark;
    if ($isDisabled) return marketplaceColors.gray4;
    return marketplaceColors.black;
  }};
  font-weight: ${({ $isActive }) => $isActive ? 700 : 400};
  text-align: center;
  background-color: ${({ $isActive, $isFullMatch, $isDisabled }) => {
    if ($isActive) return marketplaceColors.primaryGhost;
    return marketplaceColors.white;
  }};

  &:hover {
    background-color: ${({ $isActive, $isFullMatch, $isDisabled }) => {
      if ($isActive) return marketplaceColors.primaryGhost;
      if ($isDisabled) return marketplaceColors.gray1;
      if ($isFullMatch) return marketplaceColors.primaryGhost;
      return marketplaceColors.gray0;
    }};
  }
`;