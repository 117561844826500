import { 
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { LOCALSTORAGE_CART_DATA, LOCALSTORAGE_USER_DATA } from "../constants/appConfig";
import { DELIVERY_TYPE, ICartContext, ICartContextMethods, IUserDataContext } from "../types/orders";
const defaultProductUserData: IUserDataContext = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  deliveryType: DELIVERY_TYPE.MALL,
  city: '',
  deliveryCity: '',
  warehouse: '',
  warehouseRef: '',
  isModalOpen: false,
};

const CartContext = createContext<ICartContext[] | null>([]);
const UserContext = createContext<IUserDataContext>(defaultProductUserData);
const CartDispatchContext = createContext<ICartContextMethods>({
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  incrementProductInCart: () => {},
  decrementProductInCart: () => {},
  addContactInformation: () => {},
  addNovaCity: () => {},
  addNovaWarehouse: () => {},
  clearCart: () => {},
  setIsModalOpen: () => {},
});

const useCartContext = () => {
  return useContext(CartContext);
};

const useUserContext = () => {
  return useContext(UserContext);
};
 
const useCartDispatchContext = () => {
  return useContext(CartDispatchContext);
};

const ProductCartContextProvider = ({ children }: { children: ReactNode }) => {
  const [productCartData, setProductCartData] = useState<ICartContext[]>([]);
  const [productUserData, setProductUserData] = useState<IUserDataContext>(defaultProductUserData);

  useEffect(() => {
    const storedCartData: string | null = localStorage.getItem(LOCALSTORAGE_CART_DATA);
    const cartData: ICartContext[] = storedCartData ? JSON.parse(storedCartData) : [];

    const storedUserData: string | null = localStorage.getItem(LOCALSTORAGE_USER_DATA);

    let userData: IUserDataContext;

    if (storedUserData) {
      userData = JSON.parse(storedUserData);
    } else {
      userData = defaultProductUserData;
      localStorage.setItem(LOCALSTORAGE_USER_DATA, JSON.stringify(defaultProductUserData));
    }
    
    setProductCartData(cartData);
    setProductUserData(userData);
  }, []);

  const handleAddProduct = (prevProducts: ICartContext[], product: ICartContext): ICartContext[] => {
    const index = prevProducts.findIndex((prevProduct) => prevProduct.id === product.id);

    let newProducts = [];
    
    if (index === -1) {
      newProducts = [...prevProducts, product];
    } else {
      const newPrevProducts = prevProducts.filter((oldProduct) => oldProduct.id !== product.id);
      newProducts = [...newPrevProducts, { ...prevProducts[index], count: (prevProducts[index].count || 0) + 1 }];
    }

    localStorage.setItem(LOCALSTORAGE_CART_DATA, JSON.stringify(newProducts));

    return newProducts;
  };

  const addProductToCart = (newProduct: ICartContext) => {
    setProductCartData((prev) => {
      return handleAddProduct(prev, newProduct);
    });
    setProductUserData(prev => ({ ...prev, isModalOpen: true }));
  };

  const setIsModalOpen = (state: boolean) => {
    setProductUserData(prev => ({ ...prev, isModalOpen: state }));
  };

  const removeProductFromCart = (productId: string) => {
    setProductCartData((prev) => {
      const updatedCart: ICartContext[] = prev.filter((product: ICartContext) => product.id !== productId);
      localStorage.setItem(LOCALSTORAGE_CART_DATA, JSON.stringify(updatedCart));

      return updatedCart;
    });
  };

  const incrementProductInCart = (productId: string) => {
    setProductCartData((prev) => {
      const updatedCart: ICartContext[] = prev.map((product: ICartContext) => {
        if (product.id === productId) {
          return {...product, count: (product.count || 0) + 1};
        }

        return product;
      });
      localStorage.setItem(LOCALSTORAGE_CART_DATA, JSON.stringify(updatedCart));

      return updatedCart;
    });
  };

  const decrementProductInCart = (productId: string) => {
    setProductCartData((prev) => {
      const updatedCart = prev.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            count: (product.count || 0) - 1
          };
        }
        return product;
      }).filter(product => product.count > 0);
      
      localStorage.setItem(LOCALSTORAGE_CART_DATA, JSON.stringify(updatedCart));

      return updatedCart;
    });
  };

  const addContactInformation = (key: string, value: string) => {
    setProductUserData((prev) => ({
      ...prev,
      [key]: value,
    }));

    localStorage.setItem(LOCALSTORAGE_USER_DATA, JSON.stringify({
      ...productUserData,
      [key]: value,
    }));
  };

  const addNovaCity = (city: string, deliveryCity: string) => {
    setProductUserData((prev) => ({
      ...prev,
      city: city,
      deliveryCity: deliveryCity,
      warehouse: '',
      warehouseRef: '',
    }));

    localStorage.setItem(LOCALSTORAGE_USER_DATA, JSON.stringify({
      ...productUserData,
      city: city,
      deliveryCity: deliveryCity,
      warehouse: '',
      warehouseRef: '',
    }));
  };

  const addNovaWarehouse = (warehouse: string, warehouseRef: string) => {
    setProductUserData((prev) => ({ ...prev, warehouse: warehouse, warehouseRef: warehouseRef }));
    localStorage.setItem(LOCALSTORAGE_USER_DATA, JSON.stringify({ ...productUserData, warehouse: warehouse, warehouseRef: warehouseRef }));
  };

  const clearCart = () => {
    localStorage.removeItem(LOCALSTORAGE_CART_DATA);
    setProductCartData([]);
  };

  const dispatchContextValue: ICartContextMethods = {
    addProductToCart,
    setIsModalOpen,
    removeProductFromCart,
    incrementProductInCart,
    decrementProductInCart,
    addContactInformation,
    addNovaCity,
    addNovaWarehouse,
    clearCart,
  };

  return (
    <CartContext.Provider value={productCartData}>
      <UserContext.Provider value={productUserData}>
        <CartDispatchContext.Provider value={dispatchContextValue}>
          { children }
        </CartDispatchContext.Provider>
      </UserContext.Provider>
    </CartContext.Provider>
  );
};

export {
  useCartContext,
  useUserContext,
  useCartDispatchContext,
  ProductCartContextProvider,
};
