import { FC } from "react";

import { HeaderContainer, HeaderImage, HeaderTitle } from "./fragments";

interface IHeaderLeftImageRightText {
  title: string;
  image: string;
  imageAlt: string;
}

export const HeaderLeftImageRightText: FC<IHeaderLeftImageRightText> = ({ title, image, imageAlt }) => (
  <HeaderContainer>
    <HeaderImage src={image} alt={imageAlt} />
    <HeaderTitle>{title}</HeaderTitle>
  </HeaderContainer>
);
