import { useRef, useState } from "react";
import { Wrapper, OptionsContainer, Option, SelectedVariantContainer, AngleIcon, Label } from "./styles";
import { IProductVariant } from "../../types/products";
import { useClickOutside } from "../../util/hooks";

interface IProps {
  selectedVariant: string;
  options: {
    key: string;
    label: string;
    secondaryLabel: string;
    to: string;
    isActive: boolean;
    isFullMatch: boolean;
    isDisabled: boolean;
  }[];
}

const VariantSelect = ({ selectedVariant, options }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setIsOpen(false));

  return (
    <Wrapper ref={wrapperRef} onClick={() => setIsOpen(prev => !prev)}>
      <SelectedVariantContainer $isOpen={isOpen}>
        <Label>{selectedVariant}</Label>
        <AngleIcon $isOpen={isOpen} />
      </SelectedVariantContainer>
      {isOpen && (
        <OptionsContainer>
          {options.map((option) => (
            <Option
              key={option.key}
              to={option.to}
              $isActive={option.isActive}
              $isFullMatch={option.isFullMatch}
              $isDisabled={option.isDisabled}
            >
              <Label>{option.isFullMatch ? `${option.label} ${option.secondaryLabel}` : option.label}</Label>
            </Option>
          ))}
        </OptionsContainer>
      )}
    </Wrapper>
  )
};

export default VariantSelect;
