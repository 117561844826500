import styled from "styled-components";
import { breakpoints, marketplaceColors } from "../constants/styleConfig";

export const Title = styled.h2`
  position: relative;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  color: ${marketplaceColors.gray9};

  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: 2.5rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 72px;
    height: 4px;
    background-color: ${marketplaceColors.danger};
  }
`;
