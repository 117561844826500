import { isRouteErrorResponse, Link, useRouteError } from "react-router-dom";
import { ErrorScreenWrapper, Message, StatusCode } from "./styles";
import { AppButton } from "../../components";
import { PATHS } from "../../constants/appConfig";

const ErrorScreen = () => {
  const error = useRouteError();  
  const isRouteError = isRouteErrorResponse(error);

  return (
    <ErrorScreenWrapper>
      {isRouteError && (<>
        <StatusCode>{error.status}</StatusCode>
        <Message>{error.status === 404 ? 'Сторінку не знайдено' : error.data?.message}</Message>
      </>)}
      <Link to={PATHS.main}><AppButton>На головну</AppButton></Link>
    </ErrorScreenWrapper>
  );
}

export { ErrorScreen };
