import { ErrorCode, ErrorContainer } from "./styled";

export interface IErrorProps {
  type?: 'warning' | 'error';
  code?: number;
  message?: string;
  onClose?: () => void;
};

const Error = ({
  type = 'error',
  code,
  message = '',
  onClose,
}: IErrorProps) => {
  return <>
    {message && (
      <ErrorContainer type={type}>
        {code && <ErrorCode>{code}:</ErrorCode>} {message}
      </ErrorContainer>
    )}
  </>;
};

export default Error;
