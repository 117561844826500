import { Link } from "react-router-dom";

import { FILTER_GROUP, IFilterItem } from "../../types/reducers";
import {
  CATEGORY_LABEL_BUTTONS,
  MAIN_BRANDS,
  MAIN_CATEGORIES,
  PATHS,
} from "../../constants/appConfig";
import {
  AppButton,
  FlexContainer,
  CategoryLabelButton,
  Title,
  GetInTouch,
  AppCarousel,
} from "../../components";
import {
  MainScreenWrapper,
  ContentWrapper,
  CategoryCard,
  CategoryImage,
  CategoryLabel,
  Text,
  FormWrapper,
  ItemsWrapper,
  CarouselWrapper,
} from "./styles";

const MainScreen = () => {
  const mainScreenLinksMap = ( mainScreenLinks: IFilterItem[], queryParam: 'vendor' | 'categoryId', itemsPerPage: number = 6): JSX.Element[] => {
    let result: JSX.Element[] = [];

    for (let index = 0; index < mainScreenLinks.length; index += itemsPerPage) {
      const pageItems = mainScreenLinks
        .slice(index, index + itemsPerPage)
        .map(({ id, imageSrc, label }) => (
            <Link key={id} to={`${PATHS.search}?${queryParam}=${id}`}>
              <CategoryCard>
                <CategoryImage src={imageSrc} />
                <CategoryLabel>{label}</CategoryLabel>
              </CategoryCard>
            </Link>
          )
        );

      result = [...result, <ItemsWrapper key={index}>{pageItems}</ItemsWrapper>];
    }

    return result;
  };

  return (
    <MainScreenWrapper>
      <ContentWrapper>
        <Title>Категорії</Title>
        <CarouselWrapper>
          <AppCarousel showArrows={true} isArrowShadow={true} infiniteLoop={true} autoPlay={true} interval={10000} showThumbs={false} swipeable={false}>
            {mainScreenLinksMap(MAIN_CATEGORIES, FILTER_GROUP.CATEGORY)}
          </AppCarousel>
        </CarouselWrapper>
        <Title>Бренди</Title>
        <CarouselWrapper>
          <AppCarousel showArrows={true} isArrowShadow={true} infiniteLoop={true} autoPlay={true} interval={10000} showThumbs={false} swipeable={false}>
            {mainScreenLinksMap(MAIN_BRANDS, FILTER_GROUP.BRAND)}
          </AppCarousel>
        </CarouselWrapper>
        <FlexContainer direction='column' align='center'>
          <Text>Переглянути весь каталог</Text>
          <Link to={PATHS.search}><AppButton>Каталог</AppButton></Link>
        </FlexContainer>
        <FormWrapper>
          <FlexContainer justify='center' padding='1rem 0'>
            <GetInTouch
              title='Маєте пропозицію?'
            />
          </FlexContainer>
        </FormWrapper>
        <FlexContainer wrap='wrap' gap='0.5rem' justify='center'>
          {CATEGORY_LABEL_BUTTONS.map((category) => (
            <CategoryLabelButton key={`${category.label}_${category.path}`} label={category.label} path={category.path} />
          ))}
        </FlexContainer>
        {/* <Title>Знижки</Title>
        <Title>Популярне</Title>
        <Title>Нове</Title> */}
      </ContentWrapper>
    </MainScreenWrapper>
  );
}

export { MainScreen };
