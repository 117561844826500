import styled from "styled-components";
import { breakpoints, marketplaceColors } from "../constants/styleConfig";
import { IProduct } from "../types/products";
import { Link } from "react-router-dom";
import { CURRENCY_SYMBOL, PATHS } from "../constants/appConfig";
import { getPrecedingSubstring } from "../util/helpers";

interface IProductCard {
  $isSmall: boolean;
};

const ProductCardWrapper = styled(Link)<IProductCard>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0;
  border: 1px solid ${marketplaceColors.gray1};
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    color: ${marketplaceColors.primaryDark};
    border-color: ${marketplaceColors.primaryDark};
    box-shadow: 0px 0px 12px 0px ${marketplaceColors.primaryGhostDark};
  }

  @media (min-width: ${breakpoints.xxs}) {
    flex-direction: ${({ $isSmall }) => $isSmall ? 'column' : 'row'};
    width: ${({ $isSmall }) => $isSmall ? '180px' : '100%'};
    padding: ${({ $isSmall }) => $isSmall ? '0.5rem 0' : '0.5rem 1rem 0.5rem 0'};
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: column;
    width: ${({ $isSmall }) => $isSmall ? '180px' : '240px'};
    padding: 0.5rem 0;
  }
`;

const ImageContainer = styled.div<IProductCard>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $isSmall }) => $isSmall ? '120px' : '240px'};
  height: ${({ $isSmall }) => $isSmall ? '120px' : '240px'};

  @media (min-width: ${breakpoints.xxs}) {
    width: 120px;
    height: 120px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: ${({ $isSmall }) => $isSmall ? '120px' : '240px'};
    height: ${({ $isSmall }) => $isSmall ? '120px' : '240px'};
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ProductInfo = styled.div<IProductCard>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: ${({ $isSmall }) => $isSmall ? '0.25rem 0.5rem' : '0.5rem'};

  @media (min-width: ${breakpoints.xxs}) {
    width: ${({ $isSmall }) => $isSmall ? '100%' : '0'};
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

const ProductParameter = styled.div<IProductCard>`
  font-size: ${({ $isSmall }) => $isSmall ? '0.75rem' : '0.875rem'};
  font-weight: 400;
  color: ${marketplaceColors.gray7};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 0.25rem 0;
  border-bottom: 1px solid ${marketplaceColors.gray1};
`;

const ProductName = styled.div<IProductCard>`
  margin-bottom: 0.25rem;
  font-size: ${({ $isSmall }) => $isSmall ? '0.875rem' : '1rem'};
  font-weight: 500;
  color: ${marketplaceColors.gray9};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProductFlavor = styled.div<IProductCard>`
  font-size: ${({ $isSmall }) => $isSmall ? '0.75rem' : '0.875rem'};
  font-weight: 500;
  color: ${marketplaceColors.gray4};
`;

const ProductSize = styled.div<IProductCard>`
  font-size: ${({ $isSmall }) => $isSmall ? '0.75rem' : '0.875rem'};
  font-weight: 500;
  color: ${marketplaceColors.gray4};
`;

const Variant = styled.div`
  flex-grow: 1;
  margin-bottom: 0.25rem;
`;

interface IProductPrice {
  isOld?: boolean;
  $isSmall: boolean;
}

const ProductPrice = styled.span<IProductPrice>`
  margin-right: 1rem;
  font-size: ${({ isOld }) => isOld ? '0.75rem' : '1.25rem'};
  font-weight: ${({ isOld }) => isOld ? 300 : 500};
  color: ${({ isOld }) => isOld ? marketplaceColors.gray5 : marketplaceColors.gray9};
  text-decoration: ${({ isOld }) => isOld ? 'line-through' : 'none'};
  white-space: nowrap;

  @media (min-width: ${breakpoints.sm}) {
    font-size: ${({ $isSmall, isOld }) => $isSmall
      ? (isOld ? '0.75rem' : '1.25rem')
      : (isOld ? '1rem' : '1.5rem')
    };
  }
`;

interface IProductAvailability {
  isAvailable?: boolean;
}

const ProductAvailability = styled.div<IProductAvailability>`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ isAvailable }) => isAvailable ? marketplaceColors.success : marketplaceColors.gray4};
`;

interface IProductCardProps {
  isSmall?: boolean;
  productData: IProduct;
}

const ProductCard = ({
  isSmall = false,
  productData,
  productData: {
    id,
    nameUA,
    pictures = ['https://placehold.co/240x240/png?text=Не+знайдено'],
    price,
    quantity = 0,
    vendor,
    country,
    variant,
  }
}: IProductCardProps) => {
  const isAvailable = quantity > 0;
  // Handle a case when vendor contains 'Nutrition' but nameUA doesn't, or vice versa
  const shortVendor = vendor.replace('Nutrition', '').trim();
  const shortVendorRegex = new RegExp(`${shortVendor}(\\sNutrition)?`, 'i');
  const category = getPrecedingSubstring(nameUA, vendor)
    || getPrecedingSubstring(nameUA, shortVendor);
  const shortName = category
    ? nameUA.replace(category, '').replace(shortVendorRegex, '')
    : nameUA;
  
  return (
    <ProductCardWrapper to={PATHS.product.replace(':id', id)} $isSmall={isSmall}>
      <ImageContainer $isSmall={isSmall}>
        <ProductImage
          width={240}
          height={240}
          src={pictures[0]}
          alt={nameUA}
        />
      </ImageContainer>
      <ProductInfo $isSmall={isSmall}>
        {category && <ProductParameter $isSmall={isSmall}>{category}</ProductParameter>}
        {vendor && <ProductParameter $isSmall={isSmall}>{vendor}</ProductParameter>}
        <Separator />
        <ProductName $isSmall={isSmall} title={shortName}>{shortName}</ProductName>
        <Variant>
          {variant.flavor && <ProductFlavor $isSmall={isSmall}>{variant.flavor}</ProductFlavor>}
          {variant.size && <ProductSize $isSmall={isSmall}>{variant.size}</ProductSize>}
        </Variant>
        <div>
          {!!quantity && (<ProductPrice $isSmall={isSmall}>{price} {CURRENCY_SYMBOL}</ProductPrice>)}
          {/* {priceOld && <ProductPrice $isSmall={isSmall} isOld>{priceOld} {CURRENCY_SYMBOL}</ProductPrice>} */}
        </div>
        <ProductAvailability isAvailable={isAvailable}>
          {isAvailable ? 'В наявності' : 'Немає в наявності'}
        </ProductAvailability>
      </ProductInfo>
    </ProductCardWrapper>
  );
};

export default ProductCard;
