import styled from 'styled-components';
import {animations, marketplaceColors} from "../../constants/styleConfig";

export const Title = styled.b`
  font-size: 32px;
  color: ${marketplaceColors.gray6};
  text-align: center;
  margin: 1rem 0;
`;

export const OrderNumberTitle = styled.p`
  font-size: 18px;
  color: ${marketplaceColors.gray6};
  text-align: center;
  margin-top: 1.25rem;
  margin-bottom: 2rem;
`;

export const OrderNumber = styled.span`
  font-weight: bold;
  background-color: ${marketplaceColors.gray0};
  padding: 5px;
  border-radius: 7px;
  color: ${marketplaceColors.gray6};
  cursor: pointer;
  transition: background-color ${animations.timeDefault} ease;
  
  &:hover {
    background-color: ${marketplaceColors.gray1};
  }
`;
