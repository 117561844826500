import { IProductVariant } from "../types/products";

export const VARIANT_DICTIONARY: {
  [key in keyof IProductVariant]: string;
} = {
  'size': 'Розмір',
  'flavor': 'Смак',
  'color': 'Колір',
};

export const CATEGORY_ID_NAME: { [key: string]: string } = {
  "19": "Протеїни",
  "20": "Гейнери",
  "21": "Амінокислоти",
  "22": "Амінокислоти BCAA",
  "23": "Креатини",
  "76": "Передтренувальні комплекси",
  "150": "Післятренувальні комплекси",
  "25": "Жироспалювачі",
  "30": "Стимулятори тестостерону",
  "28": "Препарати для суглобів та зв'язок",
  "29": "Вітаміни та мінерали",
  "78": "Натуральні добавки та екстракти",
  "79": "Риб'ячий жир та омега",
  "110": "Пробіотики та пребіотики",
  "31": "Замінники харчування",
  "80": "Ізотоніки",
  "33": "Батончики",
  "34": "Спортивні напої",
  "36": "Грим для бодібілдингу",
  "38": "Екіпірування",
  "197": "Фітнес",
  "130": "Бінти",
  "131": "Лямки, гачки, накладки",
  "149": "М'ячі для фітнесу",
  "129": "Рукавички для кросфіту",
  "128": "Рукавички для фітнесу",
  "133": "Пояси для важкої атлетики",
  "194": "Скакалки",
  "121": "Еспандери",
  "195": "Бокс та єдиноборства",
  "202": "Аксесуари для боксу та єдиноборств",
  "196": "Захист для боксу та єдиноборств",
  "136": "Рукавички для боксу та єдиноборств",
  "148": "Йога",
  "198": "Велоспорт",
  "137": "Велоперчатки",
  "229": "Cпортивне обладнання",
  "230": "Гантелі, диски, грифи, замки",
  "37": "Шейкери",
  "72": "Пляшки та фляги",
  "39": "Аксесуари",
  "173": "Контейнери",
  "141": "Магнезія",
  "143": "Олії для тіла",
  "104": "Таблетниці",
  "40": "Одяг",
  "112": "Головні убори",
  "162": "Кепки",
  "161": "Шапки",
  "152": "Одяг для жінок",
  "168": "Футболки",
  "182": "Кросівки",
  "151": "Одяг для чоловіків",
  "158": "Футболки",
  "155": "Тайтси",
  "157": "Худі",
  "227": "Засоби для догляду за взуттям",
  "71": "Сумки та рюкзаки",
  "172": "Рюкзаки",
  "170": "Рюкзаки-мішки",
  "171": "Сумки",
  "183": "Інші товари",
  "204": "Акумулятори та батареї",
  "184": "Аксесуар для УМБ та зарядних станцій",
  "217": "Аксесуари до кліматичної техніки",
  "144": "Біноклі",
  "221": "Дошки для малювання",
  "225": "Рідини для склоомивача",
  "178": "Зарядні пристрої",
  "222": "Зарядні пристрої",
  "180": "ДБЖ",
  "179": "Акумулятори для ДБЖ",
  "181": "Джерела безперебійного живлення",
  "200": "Іграшки-нічники",
  "186": "Інструменти",
  "210": "Автомобільні пилососи",
  "220": "Корончасті свердла",
  "213": "Накидні гайкові ключі",
  "187": "Викрутки",
  "214": "Торцеві головки",
  "233": "Біти для шуруповерта",
  "244": "Свердла",
  "245": "Електровикрутки",
  "203": "Кабелі та адаптери",
  "188": "Кухонне приладдя",
  "201": "Аксесуари для заварювання чаю",
  "209": "Співзбивачі",
  "226": "Ємності для олії або оцту",
  "206": "Кухонні ножиці",
  "189": "Мусати та точила",
  "211": "Пробки та лійки для пляшок",
  "218": "Пуровери та аксесуари",
  "193": "Ручні соковитискачі",
  "228": "Губки для посуду",
  "232": "Банки та ємності",
  "234": "Кухонні таймери",
  "236": "Приладдя для спецій",
  "207": "Настільний годинник",
  "118": "Подарунки та товари для свят",
  "119": "Новорічний декор",
  "120": "Світлодіодні гірлянди",
  "177": "Світло",
  "175": "USB акумуляторні лампи",
  "185": "Лампи",
  "176": "Настільні лампи",
  "208": "Нічники",
  "174": "Ліхтарі",
  "246": "Побутова техніка, інтер'єр",
  "219": "Вентилятори",
  "247": "Масажери",
  "238": "Термометри та гігрометри",
  "242": "Запчастини для пилососів",
  "125": "Засоби від комах",
  "146": "Тактичні рукавички",
  "212": "Товари для догляду за одягом",
  "199": "Зволожувачі повітря",
  "205": "Хаби та кардрідери",
  "223": "Швабри",
  "215": "Ефірні масла",
  "235": "Москітні сітки",
  "237": "IP-камери",
  "239": "Розумні датчики",
  "240": "Зоотовар",
  "216": "Інструменти для грумінгу",
  "224": "Посуд для тварин",
  "241": "Ретранслятори Wi-Fi",
  "243": "Електронні перекладачі",
};
