import { CATEGORY_ID_NAME } from "../constants/dictionary";
import { IFilterQueryCheckbox } from "../types/reducers";

interface IFormatCheckboxes {
  searchKey: string;
  type: string;
  label: string;
}

const formatCheckboxes = (selectedCheckboxes: IFilterQueryCheckbox): IFormatCheckboxes[] => {
  return Object.entries(selectedCheckboxes).map(
    ([key, value]) => (
      value.map((categoryItem) => ({
        searchKey: categoryItem,
        type: key,
        label: CATEGORY_ID_NAME[categoryItem] || categoryItem,
      }))
    )
  ).flat();
};

export default formatCheckboxes;
