import { IOrderDetails } from "./orders";

export enum AUTH_ACTION_TYPE {
  SET='set',
  CLEAR='clear',
}

interface IAuthActionSet {
  type: AUTH_ACTION_TYPE.SET;
  username: string;
  token: string;
}

interface IAuthActionClear {
  type: AUTH_ACTION_TYPE.CLEAR;
}

export type AuthAction = IAuthActionSet | IAuthActionClear;

export interface IAuth {
  isAuthenticated: boolean;
  username: string;
  token: string;
}

export interface IClientDiscountData {
  phone: string;
  payment: number;
  fixedDiscount: number;
}

export interface IClientDiscountResponse {
  phone: string;
  discount: number;
  isFixed: boolean;
}

export interface IFilterItem {
  id: string;
  label: string;
  imageSrc?: string;
  route?: string;
}

export interface ICheckbox extends IFilterItem {
  groupId?: string;
  isChecked?: boolean;
}

export enum FILTER_GROUP {
  BRAND='vendor',
  COUNTRY='country',
  CATEGORY='categoryId',
}

export interface IFilterGroup {
  id: string;
  label: string;
  list: IFilterItem[];
}

export interface IFilterQueryCheckbox {
  [key: string]: string[];
}

export enum ORDER_DETAILS_ACTION_TYPE {
  SET='set',
  CLEAR='clear',
}

export interface IOrderDetailsActionSet extends Partial<IOrderDetails> {
  type: ORDER_DETAILS_ACTION_TYPE.SET;
}

export interface IOrderDetailsActionClear {
  type: ORDER_DETAILS_ACTION_TYPE.CLEAR;
}

export type OrderDetailsAction = IOrderDetailsActionSet | IOrderDetailsActionClear;
