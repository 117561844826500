import { ReactElement } from "react";

export type articlesItemType = {
    component: () => ReactElement,
    categoryId: string,
    category: string,
    breadcrumbs: string,
    title: string,
    titleImage: string,
};

export type articleType = {
    [key: string]: articlesItemType,
};

export interface IArticleBlock extends Pick<articlesItemType,
    | 'titleImage'
    | 'title'
    | 'category'
    > {}

export type categoriesType = {
    title: string,
    image: string,
    url: string,
};

export enum ARTICLE_HEADER_TYPE {
    LEFT_IMAGE_RIGHT_TEXT = 'left-image-right-text',
};

export enum ARTICLE_CONTENT_TYPE {
    TEXT = 'text',
    LIST_ITEM = 'listItem',
};

type ContentAsStringType = {
    type: ARTICLE_CONTENT_TYPE.TEXT;
    text: string;
};

type ContentAsArrayType = {
    type: ARTICLE_CONTENT_TYPE.LIST_ITEM;
    text: string[];
}

export type ContentType = ContentAsArrayType | ContentAsStringType;

export type HeaderWithImageType = {
    type: ARTICLE_HEADER_TYPE.LEFT_IMAGE_RIGHT_TEXT;
    title: string;
    image: string;
    imageAlt: string;
}

type HeaderWithoutImageType = {
    type: '';
    title: string;
}

export type HeaderType = HeaderWithImageType | HeaderWithoutImageType;

export interface IArticle {
    id: string;
    categoryId: string;
    category: string;
    breadcrumbs: string;
    title: string;
    titleImage?: string;
    header: HeaderType;
    content: ContentType[];
}
