import { FC } from 'react';
import { ArticleContainer, Image, Content, Title, Category } from "./styles";
import { IArticleBlock } from "../../types/articles";

const ArticleBlock: FC<IArticleBlock> = ({ titleImage, title, category }) => {
    return (
        <ArticleContainer>
            <Image src={titleImage} alt={title} />
            <Content>
                <Title>{title}</Title>
                <Category>{category}</Category>
            </Content>
        </ArticleContainer>
    );
};

export default ArticleBlock;
