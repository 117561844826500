import styled from "styled-components";
import { animations, breakpoints, colors, marketplaceColors } from "../../constants/styleConfig";

export const DropdownGroupLabel = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
`;

export const DropdownGroupContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: none;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem 1rem;
  max-height: 300px;
  padding: 0.5rem;
  background-color: ${colors.backgroundLight};
  border: 1px solid ${colors.borderDark};
  overflow-y: scroll;
  white-space: nowrap;
`;

export const DropdownGroupWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${colors.borderLight};

  &:hover > ${DropdownGroupContainer} {
    display: grid;
  }
`;

export const DropdownLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GroupContainer = styled.div``;

export const GroupLabel = styled.div`
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;

  user-select: none;
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.125rem;
  }
`;

export const StyledCheckbox = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.75rem;
  font-size: 1.125rem;
  color: ${colors.backgroundWhite};
  background-color: ${marketplaceColors.white};
  border: 1px solid ${marketplaceColors.gray2};
  border-radius: 5px;
  user-select: none;
  transition: all ${animations.timeFast};

  @media (min-width: ${breakpoints.md}) {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
    font-size: 0.75rem;
    border-radius: 3px;
  }
`;

export const CheckboxContainer = styled.label<{ $isOpen?: boolean }>`
  display: flex;
  align-items: flex-start;
  max-height: ${({ $isOpen }) => ($isOpen ? '60px' : '0')};
  padding: 0.375rem;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  cursor: pointer;
  transition: all ${animations.timeDefault} ease;

  &:hover > ${StyledCheckbox} {
    border-color: ${marketplaceColors.primary};
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 0.25rem;
  }
`;

export const CheckboxLabel = styled.div`
  font-size: 1.125rem;
  font-weight: 500;

  @media (min-width: ${breakpoints.md}) {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

export const CheckboxInput = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;

  &[checked] + ${StyledCheckbox} {
    background-color: ${marketplaceColors.primary};
    border-color: ${marketplaceColors.primary};
  }
`;

export const CheckboxWrapper = styled.div<{ $isCheckboxesOpen: boolean }>`
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  transition: max-height ${animations.timeDefault} ease;
  max-height: ${({ $isCheckboxesOpen }) => $isCheckboxesOpen ? '300px' : '0px'};

  @media (min-width: ${breakpoints.md}) {
    max-height: 300px;
  }
`;

export const GroupLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
`;

export const SearchInputWrapper = styled.div`
  padding-top: 0.25rem;
  padding-right: 1rem;
`;

export const ResultNotFound = styled.p`
  font-size: 14px;
  color: ${marketplaceColors.gray6};
`;

export const AngleIconWrapper = styled.div`
  display: block;
  
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;
