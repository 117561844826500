import styled from "styled-components";

import { marketplaceColors } from "../../constants/styleConfig";

export const Wrapper = styled.div`
`;

export const Title = styled.p`
  font-size: 2rem;
  font-weight: 600;
  color: ${marketplaceColors.gray9};
  padding: 1rem 0;
`;

export const ButtonWrapper = styled.div`
  width: 50%;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
`;

export const FormMessage = styled.div<{ $type: 'error' | 'success' }>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ $type }) => $type === 'error' ? marketplaceColors.danger : marketplaceColors.success};
`;

export const MessageIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;
