import { InputHTMLAttributes } from "react";
import { Container, FieldWrapper, LabelText, StyledInput, SubmitButton } from "./styles";
import { IoArrowForward } from "react-icons/io5";

export interface IInputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  status?: 'success' | 'error' | 'warning' | false;
  radius?: string;
  label?: string;
  onSubmit?: () => void;
};

const InputField = ({
  status,
  radius = 'unset',
  label = '',
  onSubmit,
  ...inputProps
}: IInputFieldProps) => {
  return (
    <Container>
      {label && <LabelText>{label}</LabelText>}
      <FieldWrapper>
        <StyledInput
          $status={status || undefined}
          radius={radius}
          {...inputProps}
        />
        {onSubmit && (
          <SubmitButton onClick={onSubmit}>
            <IoArrowForward />
          </SubmitButton>
        )}
      </FieldWrapper>
    </Container>
  );
};

export { InputField };
