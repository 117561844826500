import styled from "styled-components";
import { breakpoints, colors, marketplaceColors } from "../../constants/styleConfig";
import { AppButton } from "../../components";

export const ScreenWrapper = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: 2fr 1fr;
  }
`;

export const CartListWrapper = styled.div`
  padding: 0;
  overflow: hidden;

  @media (min-width: ${breakpoints.sm}) {
    padding: 0 0.5rem;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const Control = styled.div`
  padding: 1rem 1rem 0;
`;

export const Labels = styled.div`
  display: none;
  grid-template-columns: 60px 7fr 3fr 2fr 40px;
  gap: 0.5rem;
  padding: 1rem 0 0.5rem;
  font-size: 0.75rem;

  @media (min-width: ${breakpoints.md}) {
    display: grid;
  }
`;

export const RowsWrapper = styled.div`
  font-size: 0.875rem;
  border-top: 1px solid ${colors.borderLight};
`;

export const FormWrapper = styled.div`
  max-width: ${breakpoints.md};
  padding: 1rem;
  order: -1;

  @media (min-width: ${breakpoints.sm}) {
    padding: 2rem;
  }

  @media (min-width: ${breakpoints.xl}) {
    order: 1;
  }
`;

export const TotalPrice = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  text-align: right;
  padding: 1rem 1rem;

  @media (min-width: ${breakpoints.sm}) {
    padding: 1rem 1.5rem;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem 2.5rem;
  }
`;

interface IDropdown {
  isOpen: boolean,
};

export const SelectWrapper = styled.div`
  position: relative;
`;

export const PostSelect = styled.input<IDropdown>`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: ${marketplaceColors.gray9};
  border: 1px solid ${colors.borderLight};
  outline: none;
`;

export const DropdownContainer = styled.div<IDropdown>`
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  background-color: ${marketplaceColors.white};
  border: 1px solid ${colors.borderLight};
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const ErrorToast = styled.div`
  display: inline-block;
  width: auto;
  margin-bottom: 1rem;
  padding: 0.5rem;
  color: ${colors.highlightRed};
  border: 1px solid ${colors.highlightRed};
  border-radius: 5px;
  background-color: ${colors.paleRed};

  &:before {
    content: '${({ prefix }) => prefix || ''}';
    font-weight: 600;
  }
`;

export const SuccessToast = styled.div`
  display: inline-block;
  width: auto;
  margin-bottom: 1rem;
  padding: 0.5rem;
  color: ${colors.highlightGreen};
  border: 1px solid ${colors.highlightGreen};
  border-radius: 5px;
  background-color: ${colors.paleGreen};

  &:before {
    content: '${({ prefix }) => prefix || ''}';
    font-weight: 600;
  }
`;

export const FormButton = styled(AppButton)`
  width: 100%;

  @media (min-width: ${breakpoints.xs}) {
    width: 200px;
    height: 50px;
  }
`;
