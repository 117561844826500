import { DELIVERY_TYPE } from "../types/orders";
import { FILTER_GROUP, IFilterGroup, IFilterItem } from "../types/reducers";
import { CATEGORY_ID_NAME } from "./dictionary";

export const CURRENCY = 'UAH';
export const CURRENCY_SYMBOL = '₴';
export const CROSS_SYMBOL = '×';
export const NUMBER_SYMBOL = '№';
export const DATE_FORMAT = 'DD.MM.YYYY, HH:mm:ss';
export const SEARCH_RESULTS_SIZE = 20;
export const PER_PAGE_OPTIONS = ['20', '50', '100'];
export const PAGINATION_MAX_LENGTH = 3;

export const LOCALSTORAGE_CART_DATA = 'fp-cart-data';
export const LOCALSTORAGE_USER_DATA = 'fp-user-data';
export const LOCALSTORAGE_ORDERS_IDS = 'fp-orders-ids';

export const DELIVERY_OPTIONS = [
  { id: DELIVERY_TYPE.MALL, label: "Вул. Київська, 2л, ТРЦ 'Cherry Mall', 1-й поверх", isChecked: false },
  { id: DELIVERY_TYPE.NOVA, label: "Нова Пошта", isChecked: false },
];

type PATHSType = {
  [key: string]: string;
};

export const PATHS: PATHSType = {
  main: '/',
  test: '/test/:id',
  catalog: '/catalog',
  adminFilters: '/admin-filters',
  search: '/search',
  product: '/product/:id',
  contact: '/contact',
  proposition: '/proposition',
  order: '/order',
  terms: '/terms',
  articles: '/articles',
  articlesCategory: '/articles/:category',
  article: '/articles/:category/:id',
  bookmarks: '/bookmarks',
  views: '/views',
  cart: '/cart',
  login: '/admin/login',
  adminDiscounts: '/admin/discounts',
  adminProductCatalog: '/admin/product-catalog',
  orders: '/orders',
  successOrder: '/success-order/:id',
  adminOrders: '/admin/orders',
};

export const S3Link = 'https://fp-articles.s3.eu-central-1.amazonaws.com';

const VALID_CATEGORY_IDS: string[] = ["19","20","21","22","23","76","150","25","30","28","29","78","79","110","31","80","33","34","36","38","197","130","131","149","129","128","133","194","121","195","202","196","136","148","198","137","229","230","37","72","39","173","141","143","104","40","112","162","161","152","168","182","151","158","155","157","71","172","170","171","183","247","146","212","215"];
const CATEGORY_ID_MAP: { [key: string]: string[] } = {
    "19": ["19"],
    "20": ["20"],
    "21": ["21"],
    "22": ["22"],
    "23": ["23"],
    "25": ["25"],
    "28": ["28"],
    "29": ["29"],
    "30": ["30"],
    "31": ["31"],
    "33": ["33"],
    "34": ["34"],
    "36": ["36"],
    "37": ["37"],
    "38": ["38"],
    "39": ["39"],
    "40": ["40"],
    "71": ["71"],
    "72": ["72"],
    "76": ["76"],
    "78": ["78"],
    "79": ["79"],
    "80": ["80"],
    "104": ["39","104"],
    "110": ["110"],
    "112": ["40","112"],
    "118": ["183","118"],
    "119": ["183","118","119"],
    "120": ["183","118","119","120"],
    "121": ["38","197","121"],
    "125": ["183","125"],
    "128": ["38","197","128"],
    "129": ["38","197","129"],
    "130": ["38","197","130"],
    "131": ["38","197","131"],
    "133": ["38","197","133"],
    "136": ["38","195","136"],
    "137": ["38","198","137"],
    "141": ["39","141"],
    "143": ["39","143"],
    "144": ["183","144"],
    "146": ["183","146"],
    "148": ["38","148"],
    "149": ["38","197","149"],
    "150": ["150"],
    "151": ["40","151"],
    "152": ["40","152"],
    "155": ["40","151","155"],
    "157": ["40","151","157"],
    "158": ["40","151","158"],
    "161": ["40","112","161"],
    "162": ["40","112","162"],
    "168": ["40","152","168"],
    "170": ["71","170"],
    "171": ["71","171"],
    "172": ["71","172"],
    "173": ["39","173"],
    "174": ["183","177","174"],
    "175": ["183","177","175"],
    "176": ["183","177","176"],
    "177": ["183","177"],
    "178": ["183","178"],
    "179": ["183","180","179"],
    "180": ["183","180"],
    "181": ["183","180","181"],
    "182": ["40","152","182"],
    "183": ["183"],
    "184": ["183","184"],
    "185": ["183","177","185"],
    "186": ["183","186"],
    "187": ["183","186","187"],
    "188": ["183","188"],
    "189": ["183","188","189"],
    "193": ["183","188","193"],
    "194": ["38","197","194"],
    "195": ["38","195"],
    "196": ["38","195","196"],
    "197": ["38","197"],
    "198": ["38","198"],
    "199": ["183","199"],
    "200": ["183","200"],
    "201": ["183","188","201"],
    "202": ["38","195","202"],
    "203": ["183","203"],
    "204": ["183","204"],
    "205": ["183","205"],
    "206": ["183","188","206"],
    "207": ["183","207"],
    "208": ["183","177","208"],
    "209": ["183","188","209"],
    "210": ["183","186","210"],
    "211": ["183","188","211"],
    "212": ["183","212"],
    "213": ["183","186","213"],
    "214": ["183","186","214"],
    "215": ["183","215"],
    "216": ["183","240","216"],
    "217": ["183","217"],
    "218": ["183","188","218"],
    "219": ["183","246","219"],
    "220": ["183","186","220"],
    "221": ["183","221"],
    "222": ["183","222"],
    "223": ["183","223"],
    "224": ["183","240","224"],
    "225": ["183","225"],
    "226": ["183","188","226"],
    "227": ["40","227"],
    "228": ["183","188","228"],
    "229": ["38","229"],
    "230": ["38","229","230"],
    "232": ["183","188","232"],
    "233": ["183","186","233"],
    "234": ["183","188","234"],
    "235": ["183","235"],
    "236": ["183","188","236"],
    "237": ["183","237"],
    "238": ["183","246","238"],
    "239": ["183","239"],
    "240": ["183","240"],
    "241": ["183","241"],
    "242": ["183","246","242"],
    "243": ["183","243"],
    "244": ["183","186","244"],
    "245": ["183","186","245"],
    "246": ["183","246"],
    "247": ["183","247"]  // original position ["183","246","247"]
};

const NAVBAR_CATEGORIES = VALID_CATEGORY_IDS
  .filter(id => CATEGORY_ID_MAP[id].length === 1)
  .map(id => ({
    id: id,
    label: CATEGORY_ID_NAME[id],
  }));

export interface ITreeNode {
  id: string;
  sub: ITreeNode[];
}

function buildTree(data: any): ITreeNode[] {
  const nodeMap = {};

  // Step 1: Create nodes for all unique ids
  for (const id in data) {
    //@ts-ignore
      nodeMap[id] = { id: id };
  }

  // Step 2: Build the tree structure
  for (const id in data) {
      const path = data[id];

      for (let i = 0; i < path.length; i++) {
          const currentId = path[i];//@ts-ignore
          const node = nodeMap[currentId];

          if (i === 0) {
              // Initialize the root node
              //@ts-ignore
              if (!nodeMap[currentId].sub) {
                //@ts-ignore
                  nodeMap[currentId].sub = [];
              }
          } else {
              // Append to the sub array of the previous node
              //@ts-ignore
              let parentNode = nodeMap[path[i - 1]];

              if (!parentNode.sub) {
                  parentNode.sub = [];
              }

              // Check if the current node already exists in the parent's sub array
              //@ts-ignore
              let existingNode = parentNode.sub.find(n => n.id === currentId);
              if (!existingNode) {
                  parentNode.sub.push(node);
              }
          }
      }
  }

  // Step 3: Collect all top-level nodes
  const topNodes = [];
  const addedIds = new Set();

  for (const id in data) {
      const path = data[id];
      const topNodeId = path[0];

      if (!addedIds.has(topNodeId)) {
        //@ts-ignore
          topNodes.push(nodeMap[topNodeId]);
          addedIds.add(topNodeId);
      }
  }

  return topNodes;
}

let VALID_MAP = {};
VALID_CATEGORY_IDS.forEach(id => { VALID_MAP = { ...VALID_MAP, [id]: CATEGORY_ID_MAP[id] } })
export const CATEGORY_TREE = buildTree(VALID_MAP);

type CATEGORY_LABEL_BUTTONS_TYPE = {
  label: string,
  path: string,
};

export const CATEGORY_LABEL_BUTTONS: CATEGORY_LABEL_BUTTONS_TYPE[] = [
  {
    label: 'Протеїни',
    path: '/search?categoryId=19',
  },
  {
    label: 'Амінокислоти',
    path: '/search?categoryId=21',
  },
  {
    label: 'BCAA',
    path: '/search?categoryId=22',
  },
  {
    label: 'Гейнери',
    path: '/search?categoryId=20',
  },
  {
    label: 'Вітаміни',
    path: '/search?categoryId=29',
  },
  {
    label: 'Ізотоніки',
    path: '/search?categoryId=80',
  },
  {
    label: 'Креатини',
    path: '/search?categoryId=23',
  },
  {
    label: 'Жироспалювачі',
    path: '/search?categoryId=25',
  },
  {
    label: 'Омега',
    path: '/search?categoryId=79',
  },
  {
    label: 'Optimum Nutrition',
    path: '/search?vendor=Optimum%2520Nutrition',
  },
  {
    label: 'MST',
    path: '/search?vendor=MST',
  },
  {
    label: 'BioTech',
    path: '/search?vendor=BioTech',
  },
  {
    label: 'NOW Foods',
    path: '/search?vendor=NOW',
  },
  {
    label: 'MyProtein',
    path: '/search?vendor=MyProtein',
  },
  {
    label: 'Протеїнові батончики',
    path: '/search?categoryId=33',
  },
  {
    label: 'Аксесуари',
    path: '/search?categoryId=38',
  },
  {
    label: 'Препарати для суглобів',
    path: '/search?categoryId=28',
  },
  {
    label: 'Предтренувальні комплекси',
    path: '/search?categoryId=76',
  },
  {
    label: 'Стимулятори тестостерону',
    path: '/search?categoryId=30',
  },
  {
    label: 'Післятренувальні комплекси',
    path: '/search?categoryId=150',
  },
];


export const MAIN_CATEGORIES: IFilterItem[] = [
  {
    id: '19',
    label: 'Протеїни',
    imageSrc: require('../assets/images/categoryProtein.jpg'),
  },
  {
    id: '20',
    label: 'Гейнери',
    imageSrc: require('../assets/images/categoryGainer.jpg'),
  },
  {
    id: '23',
    label: 'Креатини',
    imageSrc: require('../assets/images/categoryCreatine.jpg'),
  },
  {
    id: '33',
    label: 'Протеїнові батончики',
    imageSrc: require('../assets/images/categoryBar.jpg'),
  },
  {
    id: '29',
    label: 'Вітаміни',
    imageSrc: require('../assets/images/categoryVitamin.jpg'),
  },
  {
    id: '38',
    label: 'Аксесуари',
    imageSrc: require('../assets/images/categoryAccessory.jpg'),
  },
];

export const MAIN_BRANDS: IFilterItem[] = [
  {
    id: 'Optimum%2520Nutrition',
    label: 'Optimum Nutrition',
    imageSrc: require('../assets/images/brands/brandOptimumNutrition.jpg'),
  },
  {
    id: 'BioTech',
    label: 'BioTech',
    imageSrc: require('../assets/images/brands/brandBioTech.jpg'),
  },
  {
    id: 'NOW',
    label: 'NOW foods',
    imageSrc: require('../assets/images/brands/brandNOW.jpg'),
  },
  {
    id: 'Universal%2520Nutrition',
    label: 'Universal Animal',
    imageSrc: require('../assets/images/brands/brandUniversalAnimal.jpg'),
  },
  {
    id: 'MyProtein',
    label: 'MyProtein',
    imageSrc: require('../assets/images/brands/brandMyprotein.jpg'),
  },
  {
    id: 'MST',
    label: 'MST',
    imageSrc: require('../assets/images/brands/brandMST.jpg'),
  },
];

/*
{
    id: 'Allnutrition',
    label: 'Allnutrition',
    imageSrc: '',
  },
  {
    id: 'Pure%2520Gold%2520Protein',
    label: 'Pure Gold Protein',
    imageSrc: '',
  },
  {
    id: 'MyProtein',
    label: 'MyProtein',
    imageSrc: '',
  },
  {
    id: 'BioTech',
    label: 'BioTech',
    imageSrc: '',
  },
  {
    id: 'Swanson',
    label: 'Swanson',
    imageSrc: '',
  },
  {
    id: 'Puritan\'s%2520Pride',
    label: 'Puritan\'s Pride',
    imageSrc: '',
  },
 */

export const NAVBAR_LINKS: {
  label: string;
  route: string;
  sublinks?: IFilterItem[];
  tree?: ITreeNode[];
}[] = [
  {
    label: 'Головна',
    route: PATHS.main,
  },
  {
    label: 'Каталог',
    sublinks: NAVBAR_CATEGORIES,
    route: `${PATHS.search}?${FILTER_GROUP.CATEGORY}=`,
  },
  {
    label: 'Бренди',
    sublinks: [
      ...MAIN_BRANDS,
      {
        id: 'other',
        label: 'Інші',
        route: PATHS.search
      }
    ],
    route: `${PATHS.search}?${FILTER_GROUP.BRAND}=`,
  },
  {
    label: 'Контакти',
    route: PATHS.contact,
  },
  {
    label: 'Замовлення',
    route: PATHS.orders,
  },
  {
    label: 'Блог',
    route: PATHS.articles,
  },
];

export const FOOTER_LINKS = [
  {
    label: 'Категорії',
    links: [
      {
        label: 'Спортивне харчування',
        route: `${PATHS.search}?${FILTER_GROUP.CATEGORY}=19+20+21+22+23+76+150+25+30`,
      },
      {
        label: 'Батончики та напої',
        route: `${PATHS.search}?${FILTER_GROUP.CATEGORY}=33+34`,
      },
      {
        label: 'Вітаміни та добавки',
        route: `${PATHS.search}?${FILTER_GROUP.CATEGORY}=29+78+79+110+31+80`,
      },
      {
        label: 'Аксесуари',
        route: `${PATHS.search}?${FILTER_GROUP.CATEGORY}=36+38+37+72+39+71`,
      },
    ],
  },
  /* {
    label: 'Персоналізація',
    links: [
      {
        label: 'Останні перегляди',
        route: PATHS.views,
      },
      {
        label: 'Закладки',
        route: PATHS.bookmarks,
      },
      {
        label: 'Запропонувати товар',
        route: PATHS.proposition,
      },
    ],
  }, */
  {
    label: 'Інформація',
    links: [
      {
        label: 'Контакти',
        route: PATHS.contact,
      },
      /* {
        label: 'Блог',
        route: PATHS.articles,
      }, */
      /* {
        label: 'Terms & Conditions',
        route: PATHS.terms,
      }, */
    ],
  },
];

const VENDORS = [
  {
    "key": "BioTech",
    "doc_count": 910
  },
  {
    "key": "NOW",
    "doc_count": 845
  },
  {
    "key": "Olimp",
    "doc_count": 678
  },
  {
    "key": "OstroVit",
    "doc_count": 668
  },
  {
    "key": "AMIX",
    "doc_count": 544
  },
  {
    "key": "Power Play",
    "doc_count": 542
  },
  {
    "key": "Puritan's Pride",
    "doc_count": 493
  },
  {
    "key": "Scitec Nutrition",
    "doc_count": 469
  },
  {
    "key": "AllNutrition",
    "doc_count": 466
  },
  {
    "key": "Solgar",
    "doc_count": 374
  },
  {
    "key": "IronFlex",
    "doc_count": 370
  },
  {
    "key": "Trec Nutrition",
    "doc_count": 368
  },
  {
    "key": "MST",
    "doc_count": 346
  },
  {
    "key": "MyProtein",
    "doc_count": 300
  },
  {
    "key": "Power System",
    "doc_count": 294
  },
  {
    "key": "Nutrend",
    "doc_count": 287
  },
  {
    "key": "ActivLab",
    "doc_count": 273
  },
  {
    "key": "Ironmaxx",
    "doc_count": 260
  },
  {
    "key": "Pure Gold Protein",
    "doc_count": 246
  },
  {
    "key": "Bluebonnet Nutrition",
    "doc_count": 238
  },
  {
    "key": "Natures Plus",
    "doc_count": 233
  },
  {
    "key": "Sporter",
    "doc_count": 213
  },
  {
    "key": "Kevin Levrone",
    "doc_count": 211
  },
  {
    "key": "6PAK Nutrition",
    "doc_count": 209
  },
  {
    "key": "MAD MAX",
    "doc_count": 208
  },
  {
    "key": "Vansiton",
    "doc_count": 205
  },
  {
    "key": "Optimum Nutrition",
    "doc_count": 204
  },
  {
    "key": "Fitness Authority",
    "doc_count": 200
  },
  {
    "key": "Haya Labs",
    "doc_count": 197
  },
  {
    "key": "Power Pro",
    "doc_count": 195
  },
  {
    "key": "Evolite Nutrition",
    "doc_count": 192
  },
  {
    "key": "Rule 1",
    "doc_count": 189
  },
  {
    "key": "Source Naturals",
    "doc_count": 180
  },
  {
    "key": "GNC",
    "doc_count": 176
  },
  {
    "key": "Powerful Progress",
    "doc_count": 170
  },
  {
    "key": "Ultimate Nutrition",
    "doc_count": 168
  },
  {
    "key": "Mason Natural",
    "doc_count": 164
  },
  {
    "key": "Country Life",
    "doc_count": 161
  },
  {
    "key": "Carlson Labs",
    "doc_count": 149
  },
  {
    "key": "Progress Nutrition",
    "doc_count": 143
  },
  {
    "key": "Doctor's Best",
    "doc_count": 141
  },
  {
    "key": "Mutant",
    "doc_count": 141
  },
  {
    "key": "BlenderBottle",
    "doc_count": 134
  },
  {
    "key": "Quamtrax",
    "doc_count": 133
  },
  {
    "key": "BSN",
    "doc_count": 130
  },
  {
    "key": "Life Extension",
    "doc_count": 127
  },
  {
    "key": "Nosorog",
    "doc_count": 127
  },
  {
    "key": "Redcon1",
    "doc_count": 121
  },
  {
    "key": "VPLab",
    "doc_count": 119
  },
  {
    "key": "UNS Supplements",
    "doc_count": 118
  },
  {
    "key": "Weider",
    "doc_count": 116
  },
  {
    "key": "Jarrow Formulas",
    "doc_count": 112
  },
  {
    "key": "Syntrax",
    "doc_count": 112
  },
  {
    "key": "Real Pharm",
    "doc_count": 111
  },
  {
    "key": "Willmax",
    "doc_count": 110
  },
  {
    "key": "Thorne",
    "doc_count": 109
  },
  {
    "key": "TOM",
    "doc_count": 106
  },
  {
    "key": "Universal Nutrition",
    "doc_count": 100
  },
  {
    "key": "Fitmax",
    "doc_count": 99
  },
  {
    "key": "Smart Shake",
    "doc_count": 98
  },
  {
    "key": "21st Century",
    "doc_count": 93
  },
  {
    "key": "Natrol",
    "doc_count": 92
  },
  {
    "key": "Nature's Way",
    "doc_count": 85
  },
  {
    "key": "SAN",
    "doc_count": 85
  },
  {
    "key": "Earth‘s Creation",
    "doc_count": 84
  },
  {
    "key": "Go On Nutrition",
    "doc_count": 84
  },
  {
    "key": "Maxler",
    "doc_count": 82
  },
  {
    "key": "AllSports Labs",
    "doc_count": 81
  },
  {
    "key": "CASNO",
    "doc_count": 81
  },
  {
    "key": "Healthy Origins",
    "doc_count": 81
  },
  {
    "key": "Nordic Naturals",
    "doc_count": 81
  },
  {
    "key": "Swanson",
    "doc_count": 81
  },
  {
    "key": "QNT",
    "doc_count": 79
  },
  {
    "key": "Nutrex Research",
    "doc_count": 75
  },
  {
    "key": "Dr. Mercola",
    "doc_count": 74
  },
  {
    "key": "Applied Nutrition",
    "doc_count": 72
  },
  {
    "key": "AB Pro",
    "doc_count": 68
  },
  {
    "key": "Blastex",
    "doc_count": 68
  },
  {
    "key": "Nuclear Nutrition",
    "doc_count": 63
  },
  {
    "key": "Extrifit",
    "doc_count": 62
  },
  {
    "key": "Dymatize Nutrition",
    "doc_count": 61
  },
  {
    "key": "Enzymedica",
    "doc_count": 59
  },
  {
    "key": "BPI Sport",
    "doc_count": 58
  },
  {
    "key": "EntherMeal",
    "doc_count": 56
  },
  {
    "key": "Uzspace",
    "doc_count": 56
  },
  {
    "key": "Semi",
    "doc_count": 54
  },
  {
    "key": "Phantom",
    "doc_count": 49
  },
  {
    "key": "FitWin",
    "doc_count": 45
  },
  {
    "key": "JNX Sports",
    "doc_count": 45
  },
  {
    "key": "Pure Encapsulations",
    "doc_count": 44
  },
  {
    "key": "Skull Labs",
    "doc_count": 44
  },
  {
    "key": "Erbenobili",
    "doc_count": 42
  },
  {
    "key": "Immune Labs",
    "doc_count": 42
  },
  {
    "key": "California Gold Nutrition",
    "doc_count": 40
  },
  {
    "key": "New Chapter",
    "doc_count": 39
  },
  {
    "key": "Cellucor",
    "doc_count": 38
  },
  {
    "key": "Solaray",
    "doc_count": 38
  },
  {
    "key": "Zoomad",
    "doc_count": 38
  },
  {
    "key": "Garden of Life",
    "doc_count": 37
  },
  {
    "key": "10XNutrition",
    "doc_count": 36
  },
  {
    "key": "Nutrabolics",
    "doc_count": 36
  },
  {
    "key": "Swedish",
    "doc_count": 35
  },
  {
    "key": "Yamamoto Nutrition",
    "doc_count": 33
  },
  {
    "key": "MegaFood",
    "doc_count": 32
  },
  {
    "key": "Quest Nutrition",
    "doc_count": 32
  },
  {
    "key": "Sport Generation",
    "doc_count": 31
  },
  {
    "key": "GAT",
    "doc_count": 30
  },
  {
    "key": "Douglas Laboratories",
    "doc_count": 28
  },
  {
    "key": "Slim",
    "doc_count": 28
  },
  {
    "key": "Bodyperson",
    "doc_count": 27
  },
  {
    "key": "Evlution Nutrition",
    "doc_count": 27
  },
  {
    "key": "Monster Energy",
    "doc_count": 27
  },
  {
    "key": "Muscletech",
    "doc_count": 27
  },
  {
    "key": "Mex Nutrition",
    "doc_count": 26
  },
  {
    "key": "All Max Nutrition",
    "doc_count": 25
  },
  {
    "key": "Ma Baker",
    "doc_count": 24
  },
  {
    "key": "Go Nutrition",
    "doc_count": 22
  },
  {
    "key": "Grassberg",
    "doc_count": 22
  },
  {
    "key": "UspLabs",
    "doc_count": 22
  },
  {
    "key": "Galvanize",
    "doc_count": 21
  },
  {
    "key": "Gaspari",
    "doc_count": 21
  },
  {
    "key": "Musclepharm",
    "doc_count": 21
  },
  {
    "key": "Xtend (Scivation)",
    "doc_count": 21
  },
  {
    "key": "Genius Nutrition",
    "doc_count": 20
  },
  {
    "key": "Haliborange",
    "doc_count": 20
  },
  {
    "key": "Collango",
    "doc_count": 18
  },
  {
    "key": "Megabol",
    "doc_count": 17
  },
  {
    "key": "DNA Supps",
    "doc_count": 16
  },
  {
    "key": "Muscle Care",
    "doc_count": 16
  },
  {
    "key": "Opro",
    "doc_count": 16
  },
  {
    "key": "4MOVE",
    "doc_count": 15
  },
  {
    "key": "Ener-C",
    "doc_count": 15
  },
  {
    "key": "FIZI",
    "doc_count": 15
  },
  {
    "key": "Forever Living",
    "doc_count": 14
  },
  {
    "key": "KAL",
    "doc_count": 14
  },
  {
    "key": "Ronnie Coleman",
    "doc_count": 14
  },
  {
    "key": "APS",
    "doc_count": 13
  },
  {
    "key": "Gold Star",
    "doc_count": 13
  },
  {
    "key": "Saputo",
    "doc_count": 13
  },
  {
    "key": "Stark Pharm",
    "doc_count": 13
  },
  {
    "key": "Energybody",
    "doc_count": 12
  },
  {
    "key": "Essence",
    "doc_count": 12
  },
  {
    "key": "Form Labs",
    "doc_count": 12
  },
  {
    "key": "SFD",
    "doc_count": 12
  },
  {
    "key": "Seeking Health",
    "doc_count": 12
  },
  {
    "key": "Biogenix",
    "doc_count": 10
  },
  {
    "key": "Sport Definition",
    "doc_count": 10
  },
  {
    "key": "4yourhealth",
    "doc_count": 9
  },
  {
    "key": "American Health",
    "doc_count": 9
  },
  {
    "key": "Fortogen Nutrition",
    "doc_count": 9
  },
  {
    "key": "Makura",
    "doc_count": 9
  },
  {
    "key": "No Brand",
    "doc_count": 9
  },
  {
    "key": "North Coast Naturals",
    "doc_count": 9
  },
  {
    "key": "Thompson",
    "doc_count": 8
  },
  {
    "key": "Allergy Research Group",
    "doc_count": 7
  },
  {
    "key": "MHN Supplements",
    "doc_count": 7
  },
  {
    "key": "Nutriversum",
    "doc_count": 7
  },
  {
    "key": "Black Energy",
    "doc_count": 5
  },
  {
    "key": "ChildLife",
    "doc_count": 5
  },
  {
    "key": "Labrada",
    "doc_count": 5
  },
  {
    "key": "Tactiger",
    "doc_count": 5
  },
  {
    "key": "Trace Minerals",
    "doc_count": 5
  },
  {
    "key": "4yourhealth, 7SPORTS",
    "doc_count": 4
  },
  {
    "key": "Hayabusa",
    "doc_count": 4
  },
  {
    "key": "Nature's Life",
    "doc_count": 4
  },
  {
    "key": "Snickers",
    "doc_count": 4
  },
  {
    "key": "YumEarth",
    "doc_count": 4
  },
  {
    "key": "Bios Line",
    "doc_count": 3
  },
  {
    "key": "Dynamic Health",
    "doc_count": 3
  },
  {
    "key": "Inner Armour",
    "doc_count": 3
  },
  {
    "key": "Jan Tana",
    "doc_count": 3
  },
  {
    "key": "Nature Made",
    "doc_count": 3
  },
  {
    "key": "Neocell",
    "doc_count": 3
  },
  {
    "key": "PVL",
    "doc_count": 3
  },
  {
    "key": "Salvum",
    "doc_count": 3
  },
  {
    "key": "Action Nutrition",
    "doc_count": 2
  },
  {
    "key": "Frugo",
    "doc_count": 2
  },
  {
    "key": "GummiKing",
    "doc_count": 2
  },
  {
    "key": "Kinscoter",
    "doc_count": 2
  },
  {
    "key": "LI Sports",
    "doc_count": 2
  },
  {
    "key": "Mars",
    "doc_count": 2
  },
  {
    "key": "Maxx",
    "doc_count": 2
  },
  {
    "key": "MiJia",
    "doc_count": 2
  },
  {
    "key": "Mix Bottle",
    "doc_count": 2
  },
  {
    "key": "Natural Factors",
    "doc_count": 2
  },
  {
    "key": "Orthomol",
    "doc_count": 2
  },
  {
    "key": "Sambucol",
    "doc_count": 2
  },
  {
    "key": "Sante",
    "doc_count": 2
  },
  {
    "key": "Strong Fit",
    "doc_count": 2
  },
  {
    "key": "Sunny Caps",
    "doc_count": 2
  },
  {
    "key": "Twinlab",
    "doc_count": 2
  },
  {
    "key": "Alpha Boottle",
    "doc_count": 1
  },
  {
    "key": "Blade Sport",
    "doc_count": 1
  },
  {
    "key": "Bodybuilding",
    "doc_count": 1
  },
  {
    "key": "Calvin Klein",
    "doc_count": 1
  },
  {
    "key": "Dorian Yates",
    "doc_count": 1
  },
  {
    "key": "Fit",
    "doc_count": 1
  },
  {
    "key": "Fit Pro",
    "doc_count": 1
  },
  {
    "key": "FuelUp",
    "doc_count": 1
  },
  {
    "key": "FutureBiotics",
    "doc_count": 1
  },
  {
    "key": "GNC, Sporter",
    "doc_count": 1
  },
  {
    "key": "Hi-Tech",
    "doc_count": 1
  },
  {
    "key": "Himalaya",
    "doc_count": 1
  },
  {
    "key": "Innovative Labs",
    "doc_count": 1
  },
  {
    "key": "Nature's Answer",
    "doc_count": 1
  },
  {
    "key": "Poundout Gear",
    "doc_count": 1
  },
  {
    "key": "Power Play, Phantom",
    "doc_count": 1
  },
  {
    "key": "ProMera Sports",
    "doc_count": 1
  },
  {
    "key": "Steve Madden",
    "doc_count": 1
  },
  {
    "key": "VegLife",
    "doc_count": 1
  },
  {
    "key": "Vision",
    "doc_count": 1
  },
  {
    "key": "X TECH",
    "doc_count": 1
  }
]

const COUNTRIES = [
  {
    "key": "США",
    "doc_count": 6414
  },
  {
    "key": "Польща",
    "doc_count": 4360
  },
  {
    "key": "Угорщина",
    "doc_count": 1842
  },
  {
    "key": "Україна",
    "doc_count": 1229
  },
  {
    "key": "Великобританія",
    "doc_count": 1076
  },
  {
    "key": "Пакистан",
    "doc_count": 1029
  },
  {
    "key": "Німеччина",
    "doc_count": 780
  },
  {
    "key": "Китай",
    "doc_count": 679
  },
  {
    "key": "Чехія",
    "doc_count": 364
  },
  {
    "key": "Канада",
    "doc_count": 215
  },
  {
    "key": "Іспанія",
    "doc_count": 171
  },
  {
    "key": "Європа",
    "doc_count": 97
  },
  {
    "key": "Швеція",
    "doc_count": 95
  },
  {
    "key": "Італія",
    "doc_count": 79
  },
  {
    "key": "Бельгія",
    "doc_count": 77
  },
  {
    "key": "Ірландія",
    "doc_count": 38
  },
  {
    "key": "Євросоюз",
    "doc_count": 16
  },
  {
    "key": "Бангладеш",
    "doc_count": 13
  },
  {
    "key": "Угорщина, Ірландія",
    "doc_count": 8
  },
  {
    "key": "Румунія",
    "doc_count": 7
  },
  {
    "key": "Туреччина",
    "doc_count": 2
  },
  {
    "key": "Єгипет",
    "doc_count": 1
  },
  {
    "key": "Індія",
    "doc_count": 1
  },
  {
    "key": "Домініканська республіка",
    "doc_count": 1
  },
  {
    "key": "Угорщина, Євросоюз",
    "doc_count": 1
  }
];

const FILTER_CATEGORY = VALID_CATEGORY_IDS.map(id => {
  return ({
    id: id,
    label: CATEGORY_ID_NAME[id],
  })
}).sort((a, b) => a.label.localeCompare(b.label));

const FILTER_VENDORS = VENDORS.map(vendor => ({
  id: vendor.key,
  label: vendor.key
})).sort((a, b) => a.label.localeCompare(b.label));

const FILTER_COUNTRY = COUNTRIES.map(country => ({
  id: country.key,
  label: country.key
})).sort((a, b) => a.label.localeCompare(b.label));

export const FILTERS: IFilterGroup[] = [
  {
    id: 'categoryId',
    label: 'Категорія',
    list: FILTER_CATEGORY,
  },
  {
    id: 'vendor',
    label: 'Виробник',
    list: FILTER_VENDORS,
  },
  {
    id: 'country',
    label: 'Країна',
    list: FILTER_COUNTRY,
  },
];

export const IS_ADMIN_PRODUCT_CATALOG_DISPLAY = false;
