import { isEmailValid } from "./formatData";

enum RULE {
  REQUIRED='required',
  MATCH='match',
  OF_TYPES='ofTypes',
  IN_RANGE='inRange',
  IS_EMAIL='isEmail',
}

interface Rules {
  [RULE.REQUIRED]: { message: string; };
  [RULE.MATCH]: { regex: RegExp; message: string; };
  [RULE.OF_TYPES]: { values: string[]; message: string; };
  [RULE.IN_RANGE]: { min?: number; max?: number; message: string; };
  [RULE.IS_EMAIL]: { message: string; };
};

interface ValidationOptions {
  filterNull?: boolean;
}

export type ValidationErrors = {
  [key: string]: string[] | null;
}

export type ValidationResult = {
  errors: ValidationErrors;
  isValid: boolean;
};

const isEmpty = (value: any) => {
  return (value === null || value === undefined || value === '');
}

const isInRange = (value: any, min?: number, max?: number) => {
  if (typeof value === 'number' || typeof value === 'bigint') {
    return (!min || value >= min) && (!max || value <= max);
  }
  if (typeof value === 'string') {
    return (!min || value.length >= min) && (!max || value.length <= max);
  }
  return false;
}

const isEmail = (value: any) => {
  if (typeof value !== 'string') return false;
  return isEmailValid(value);
}

export const validateData = (
  data: { [key: string]: any },
  rules: { [key: string]: Partial<Rules> },
  options?: ValidationOptions,
): ValidationResult => {
  const result: ValidationErrors = {};

  (Object.entries(rules)).forEach(([fieldName, fieldRules]) => {
    const dataValue = data[fieldName];
    const fieldResult: string[] = [];

    (Object.keys(fieldRules) as Array<RULE>).forEach((rule) => {
      if (fieldRules[rule]) {
        switch (rule) {
          case RULE.REQUIRED: {
            const { message } = fieldRules[rule]!;
            if (isEmpty(dataValue)) {
              fieldResult.push(message);
            }
            break;
          }
          case RULE.MATCH: {
            const { regex, message } = fieldRules[rule]!;
            if (typeof dataValue !== 'string' || !regex.test(dataValue)) {
              fieldResult.push(message);
            }
            break;
          }
          case RULE.OF_TYPES: {
            const { values, message } = fieldRules[rule]!;
            if (!values.some((type) => typeof dataValue === type) ) {
              fieldResult.push(message);
            }
            break;
          }
          case RULE.IN_RANGE: {
            const { min, max, message } = fieldRules[rule]!;
            if (!isInRange(dataValue, min, max)) {
              fieldResult.push(message);
            }
            break;
          }
          case RULE.IS_EMAIL: {
            const { message } = fieldRules[rule]!;
            if (!isEmail(dataValue)) {
              fieldResult.push(message);
            }
            break;
          }
          default: break;
        }
      }
    });

    result[fieldName] = fieldResult.length ? fieldResult : null;
  });

  const isValid = Object.values(result).every((fieldErrors) => !fieldErrors);

  if (options?.filterNull) {
    let filteredResult: ValidationErrors = {};

    Object.entries(result).forEach(([fieldName, fieldErrors]) => {
      if (fieldErrors) {
        filteredResult[fieldName] = fieldErrors;
      }
    });

    return { errors: filteredResult, isValid: isValid };
  }

  return { errors: result, isValid: isValid };
};
