import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";

import { animations, marketplaceColors, statusColors } from "../../constants/styleConfig";

export const Wrapper = styled.div`
  width: auto;
  margin: 1rem 1.5rem;
  border: 1px solid ${marketplaceColors.gray6};
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: ${marketplaceColors.gray6};
`;

export const Header = styled.div<{ $isCloseable: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  cursor: ${({ $isCloseable }) => ($isCloseable ? 'pointer' : 'default')};
`;

export const OrderNumber = styled.p`
  font-size: 18px;
  font-weight: 700;

  ::selection {
    color: ${marketplaceColors.primary};
  }
`;

export const Status = styled.span<{ status: string }>`
  color: ${(props) => {
    switch (props.status) {
      case 'created':
        return statusColors.created;
      case 'confirmed':
        return statusColors.confirmed;
      case 'sent':
        return statusColors.sent;
      case 'delivered':
        return statusColors.delivered;
      case 'done':
        return statusColors.done;
      case 'canceled':
        return statusColors.canceled;
      default:
        return 'black';
    }
  }};
`;

export const StatusBlock = styled(Status)`
  font-size: 16px;
  font-weight: 700;
  user-select: none;
`;

export const Arrow = styled(IoIosArrowDown)<{ isOrderBlockOpen: boolean; }>`
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(${(props) => (props.isOrderBlockOpen ? '180deg' : '0deg')});
  transition: transform ${animations.timeDefault} ease-in-out;
`;

export const OrderDetails = styled.div`
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  padding: 0 2rem 0.5rem;
  font-size: 16px;
`;

export const DottedLine = styled.div`
  margin: 0.75rem 2rem 1.25rem;
  border-bottom: 2px dotted ${marketplaceColors.gray6};
`;

export const List = styled.ul`
  list-style-type: none;
`;

export const ListItem = styled.li`
  padding-left: 1rem;
`;
