import ProductCard from "../../components/ProductCard";
import { useEffect, useState } from "react";
import { IProductSearchResult } from "../../types/products";
import { ISearchProductsRequestProps, searchProducts } from "../../util/api";
import { useSearchParams } from "react-router-dom";
import { FILTERS, PER_PAGE_OPTIONS, SEARCH_RESULTS_SIZE } from "../../constants/appConfig";
import Pagination from "../../components/Pagination";
import { SearchFilter, Select, Spinner, Tag } from "../../components";
import { IFilterQueryCheckbox } from "../../types/reducers";
import formatCheckboxes from "../../util/formatCheckboxes";
import {
  SearchScreenWrapper,
  GridContainer,
  ProductGrid,
  NoResultsMessage,
  PaginationContainer,
  PerPage,
  SpinnerContainer,
  TagsContainer,
  TagsWrapper,
} from "./styles";

const SearchScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<IProductSearchResult>();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<IFilterQueryCheckbox>({});
  const [selectedFilters, setSelectedFilters] = useState<IFilterQueryCheckbox>({});

  useEffect(() => {
    const query = searchParams.get('query') || '';
    const page = Number(searchParams.get('page')) || 1;
    const perPage = Number(searchParams.get('perPage')) || SEARCH_RESULTS_SIZE;
    let searchFilters = {};

    FILTERS.forEach((filter) => {
      const filterValues = searchParams.get(filter.id)?.split(' ') || [];

      searchFilters = {
        ...searchFilters,
        [filter.id]: filterValues.map(value => decodeURIComponent(value)),
      }
    });

    if (searchFilters) {
      setSelectedCheckboxes(searchFilters);
      setSelectedFilters(searchFilters);
    }

    setIsLoading(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    searchProducts({
      query: query,
      page: page,
      size: perPage,
      checkboxes: searchFilters,
      extraFields: ['pictures'],
    }).then(res => {
      res && setSearchResults(res);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [searchParams]);

  const onSearch = (searchParams: ISearchProductsRequestProps) => {
    const newSearchParams = new URLSearchParams();

    searchParams.query && newSearchParams.set('query', searchParams.query);
    searchParams.page && newSearchParams.set('page', searchParams.page.toString());

    const checkboxes = searchParams.checkboxes;

    if (checkboxes) {
      (Object.keys(checkboxes) as Array<keyof typeof checkboxes>).forEach((key) => {
        if (checkboxes[key]?.length > 0) {
          newSearchParams.set(key.toString(), checkboxes[key].map(value => encodeURIComponent(value)).join(' '));
        }
      })
    }
    setSearchParams(newSearchParams);
    setIsLoading(true);
    setSelectedFilters(searchParams.checkboxes || {});
  };

  const deleteCategoryItem = (type: string, searchKey: string) => {
    let targetArray = selectedCheckboxes[type as keyof IFilterQueryCheckbox]
      .filter((checkbox) => checkbox !== searchKey && checkbox);

    const filteredSelectedCheckboxes = {
      ...selectedCheckboxes,
      [type]: targetArray,
    };

    setSelectedCheckboxes(filteredSelectedCheckboxes)

    onSearch({
      from: 0,
      size: 20,
      query: searchParams.get('query') || '',
      checkboxes: filteredSelectedCheckboxes,
    });
  };

  return (
    <SearchScreenWrapper>
      <SearchFilter
        selectedCheckboxes={selectedCheckboxes}
        setSelectedCheckboxes={setSelectedCheckboxes}
        onSearch={() => onSearch({
          from: 0,
          size: 20,
          query: searchParams.get('query') || '',
          checkboxes: selectedCheckboxes,
        })}
      />
      <GridContainer>
        {isLoading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {Object.keys(selectedFilters).length > 0 && (
          <TagsWrapper>
            <TagsContainer>
              {formatCheckboxes(selectedFilters).map((selectedCheckbox, selectedCheckboxIndex) => {
                const { searchKey, type, label } = selectedCheckbox;

                return (
                  <Tag
                    key={`${searchKey}_${type}_${label}_${selectedCheckboxIndex}`}
                    type={type}
                    label={label}
                    onDelete={() => deleteCategoryItem(type, searchKey)}
                  />
                )
              })}
            </TagsContainer>
          </TagsWrapper>
        )}
        <ProductGrid>
          {!searchResults?.results.length && !isLoading && (
            <NoResultsMessage>Немає результатів</NoResultsMessage>
          )}
          {searchResults?.results.map(item => (
            <ProductCard
              key={item.id}
              productData={item}
            />
          ))}
        </ProductGrid>
        <PaginationContainer>
          <PerPage>
            <div>Показати</div>
            <Select
              options={PER_PAGE_OPTIONS}
              selectedValue={searchParams.get('perPage') || PER_PAGE_OPTIONS[0]}
              onSelect={(perPageValue) => {
                setIsLoading(true);
                setSearchParams(prev => {
                  prev.set('perPage', perPageValue);
                  prev.set('page', '1');
                  return prev;
                })
              }}
            />
          </PerPage>
          {searchResults?.pagination && (
            <Pagination
              length={searchResults.pagination.totalPages}
              selectedPage={Number(searchParams.get('page')) || 1}
              onSelect={(page) => {
                setIsLoading(true);
                setSearchParams(prev => {
                  prev.set('page', page.toString());
                  return prev;
                })
              }}
            />
          )}
        </PaginationContainer>
      </GridContainer>
    </SearchScreenWrapper>
  );
};

export { SearchScreen };
