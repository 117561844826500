import styled from 'styled-components';
import { breakpoints } from "../constants/styleConfig";

interface IGridWrapper {
    columns: 2 | 3,
    columnsLg: 6 | 5,
}

const GridWrapper = styled.div<IGridWrapper>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 0.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
    padding: 2rem 1rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(${({ columnsLg }) => columnsLg}, minmax(0, 1fr));
  }

  @media (min-width: ${breakpoints.xl}) {
    gap: 1.5rem;
  }
`;

export default GridWrapper;
