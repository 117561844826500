import styled from "styled-components";
import { breakpoints, marketplaceColors} from "../../constants/styleConfig";
import { IoArrowBackOutline } from "react-icons/io5";

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0 0.5rem 0;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: column;
    padding: 0.5rem 0.5rem 0;
    border-right: 1px solid ${marketplaceColors.gray1};
  }
`;

export const CheckboxGroupContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const ButtonContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
  background-color: ${marketplaceColors.white};
  border-bottom: 1px solid ${marketplaceColors.gray2};

  @media (min-width: ${breakpoints.md}) {
    position: static;
    border-top: 1px solid ${marketplaceColors.gray2};
    border-bottom: none;
  }
`;

export const FilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const Text = styled.p`
  font-size: 1.25rem;
`;

export const AdaptiveModalHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  width: 90%;
`;

export const NavbarBackArrow = styled(IoArrowBackOutline)`
  font-size: 2rem;
  cursor: pointer;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const CartModalTitle = styled.div`
  font-size: 1.25rem;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const AppButtonModalWrapper = styled.div`
  display: block;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;
