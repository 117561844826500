import styled from 'styled-components';
import { IoSearchOutline, IoClose } from 'react-icons/io5';
import { animations, breakpoints, colors, marketplaceColors } from '../../constants/styleConfig';

export const Container = styled.label`
  width: 100%;
  margin-bottom: 1rem;
`;

export const InputContainer = styled.div`
  position: relative;
  flex: 1;
  width: 0;
  height: 100%;
`;

export const CloseIcon = styled(IoClose)`
  position: absolute;
  top: 50%;
  right: 0.25rem;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: ${marketplaceColors.gray4};
  cursor: pointer;

  &:hover {
    color: ${marketplaceColors.gray5};
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SubmitButton = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  background-color: ${marketplaceColors.primary};
  color: ${marketplaceColors.white};
  cursor: pointer;

  &:hover {
    background-color: ${marketplaceColors.primaryDark};
  }
`;

export const LabelText = styled.div`
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: ${marketplaceColors.gray6};

  @media (min-width: ${breakpoints.sm}) {
    font-size: 0.75rem;
  }
`;

export const StyledInput = styled.input<{ radius: string, $status?: 'success' | 'error' | 'warning' }>`
  text-indent: 0.5rem;
  width: 100%;
  height: 2rem;
  padding: 0;
  font-size: 1rem;
  border: 1px solid ${({ $status }) => {
    switch ($status) {
      case 'success':
        return marketplaceColors.success;
      case 'error':
        return marketplaceColors.danger;
      case 'warning':
        return marketplaceColors.warning;
      default:
        return colors.borderLight;
    }
  }};
  border-radius: ${({ radius }) => radius };
  outline: none;
`;

export const SearchContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.75rem;
  border-radius: 0.375rem;
  overflow: hidden;
`;

export const SearchInput = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  margin-left: 2px;
  padding: 0 2rem 0 1rem;
  font-size: 1rem;
  border: 1px solid ${colors.borderLight};
  border-right: none;
  border-radius: 4px 0 0 4px;
  outline: none;
`;

export const SearchIcon = styled(IoSearchOutline)`
  font-size: 1.5rem;
`;

export const SearchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 120%;
  color: ${marketplaceColors.white};
  background-color: ${marketplaceColors.primary};
  cursor: pointer;
  transition: all ${animations.timeDefault};

  &:hover {
    background-color: ${marketplaceColors.primaryDark};
  }

  @media (min-width: ${breakpoints.sm}) {
    padding: 0.875rem 1.5rem;
  }
`;
