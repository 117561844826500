import { IProductVariant } from "./products";

export enum DELIVERY_TYPE {
  MALL='mall',
  NOVA='nova',
};

export enum DELIVERY_STATUS {
  CREATED='created',
  CONFIRMED='confirmed',
  SENT='sent',
  DELIVERED='delivered',
  DONE='done',
  CANCELLED='cancelled',
};

export interface IOrderDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  deliveryType: DELIVERY_TYPE;
  city: string;
  warehouse: string;
};

export interface IOrderRaw {
  id: string;
  city: string;
  createdAt: string;
  deliveryType: DELIVERY_TYPE;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  warehouse: string;
  orderProducts: string;
  status: DELIVERY_STATUS;
}

export interface IOrder extends Omit<IOrderRaw, 'orderProducts'> {
  orderProducts: IOrderProduct[];
}

export const DEFAULT_ORDER: IOrderDetails = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  deliveryType: DELIVERY_TYPE.MALL,
  city: '',
  warehouse: '',
};

export interface ICartContext {
  id: string,
  vendor: string,
  name: string,
  country: string,
  price: number,
  priceOld?: string,
  variant: IProductVariant,
  imageSrc: string,
  count: number,
};

export interface IUserDataContext {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  deliveryType: DELIVERY_TYPE;
  city: string;
  deliveryCity: string;
  warehouse: string;
  warehouseRef: string;
  isModalOpen: boolean;
};

export interface IOrderProduct {
  id: string;
  name: string;
  price: number;
  count: number;
}

export interface ICartContextMethods {
  addProductToCart: (newProduct: ICartContext) => void;
  removeProductFromCart: (productId: string) => void;
  incrementProductInCart: (productId: string) => void;
  decrementProductInCart: (productId: string) => void;
  addContactInformation: (key: string, value: string) => void;
  addNovaCity: (city: string, deliveryCity: string) => void;
  addNovaWarehouse: (warehouse: string, warehouseRef: string) => void;
  clearCart: () => void;
  setIsModalOpen: (state: boolean) => void;
};

export enum ORDER_ERROR {
  EMPTY_CART='Кошик порожній',
  EMPTY_FORM='Форма замовлення не заповнена',
  WRONG_NUMBER='Невірний номер',
  DEFAULT='',
  NONE='',
};
