import { FaCheck } from "react-icons/fa";
import { CheckboxContainer, CheckboxInput, CheckboxLabel, StyledCheckbox } from "./styles";
import { MouseEventHandler } from "react";

interface ICheckboxProps {
  label: string;
  isChecked?: boolean;
  isGroupOpen?: boolean;
  onClick: MouseEventHandler;
};

const Checkbox = ({
  label,
  isChecked,
  isGroupOpen,
  onClick,
}: ICheckboxProps) => {
  return (
    <CheckboxContainer $isOpen={isGroupOpen}>
      <CheckboxInput type="checkbox" defaultChecked={isChecked} onClick={onClick} />
      <StyledCheckbox>
        <FaCheck />
      </StyledCheckbox>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

export { Checkbox };
