import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants/appConfig";
import { getOrdersAdmin } from "../../util/api";
import { AdminTable } from "../../components";
import { StyledContainer, StyledContentWrapper, StyledBackButton, Title } from './styles';
import { IOrder } from "../../types/orders";
import { useAuthContext } from "../../contexts/AuthContext";

const AdminOrdersScreen = () => {
  const auth = useAuthContext();
  const [orders, setOrders] = useState<IOrder[]>([]);

  useEffect( () => {
    getOrdersAdmin(auth)
      .then((res: IOrder[]) => setOrders(res));
  }, [auth]);

  return (
    <StyledContainer>
      <StyledContentWrapper>
        <Link to={PATHS.adminDiscounts}>
          <StyledBackButton>
            ← Назад
          </StyledBackButton>
        </Link>
        <Title>
          Замовлення ({orders.length})
        </Title>
      </StyledContentWrapper>
      <AdminTable data={orders} />
    </StyledContainer>
  )
};

export default AdminOrdersScreen;
