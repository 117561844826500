import styled, { keyframes } from "styled-components";
import { CgSpinner } from "react-icons/cg";
import { marketplaceColors } from "../constants/styleConfig";

interface IProps {
  $fontSize?: string;
  $color?: string;
}

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled(CgSpinner)<IProps>`
  font-size: ${({ $fontSize }) => $fontSize || '2rem'};
  color: ${({ $color }) => $color || marketplaceColors.black};
  animation: ${spinAnimation} 1.5s linear infinite;
`;

const Spinner = ({ $fontSize, $color }: IProps) => {
  return <StyledSpinner $fontSize={$fontSize} $color={$color} />
};

export { Spinner };
