import styled from "styled-components";
import { animations, breakpoints, marketplaceColors } from "../constants/styleConfig";
import { Link } from "react-router-dom";
import { FOOTER_LINKS } from "../constants/appConfig";
import moment from "moment";

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
  color: ${marketplaceColors.gray5};
  background-color: ${marketplaceColors.gray9};
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 2rem 0 1rem;
  border-bottom: 1px solid ${marketplaceColors.gray8};

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    padding: 3.75rem 1.5rem;
  }
`;

const LinkBlock = styled.div`
  display: flex;
  flex-direction: column;
  
`;

const LinkBlockTitle = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: ${marketplaceColors.white};

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0.875rem;
  }
`;

const LinkBlockItem = styled.div`
  padding: 0.375rem 0;
  font-size: 0.875rem;
  font-weight: 400;
  transition: color ${animations.timeDefault};

  &:hover {
    color: ${marketplaceColors.gray1};
  }
`;

const CopyrightContainer = styled.div`
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 1.5rem 0;
  font-size: 0.875rem;
  font-weight: 400;

  @media (min-width: ${breakpoints.md}) {
    padding: 1.5rem;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <LinkContainer>
        {FOOTER_LINKS.map((linkBlock) => (
          <LinkBlock key={linkBlock.label}>
            <LinkBlockTitle>{linkBlock.label}</LinkBlockTitle>
            {linkBlock.links.map((linkItem) => (
              <Link key={`${linkBlock.label}_${linkItem.label}`} to={linkItem.route}>
                <LinkBlockItem>{linkItem.label}</LinkBlockItem>
              </Link>
            ))}
          </LinkBlock>
        ))}
      </LinkContainer>
      <CopyrightContainer>
        {`ТОВ "Fitness Post" © 2023 - ${moment().year()}`}
      </CopyrightContainer>
    </FooterWrapper>
  );
};

export default Footer;
