import { FC } from 'react';

import {
  StyledTable,
  StyledHeaderCell,
  StyledHoverRow,
  StyledDataCell,
  StyledLink,
  Circle,
} from './styles';
import {
  RENDER_KEYS,
  RENDER_STATUSES,
  KEYS,
  IAdminTableProps,
  IOrderProduct,
} from "./config";
import { getTotalCartPrice } from "../../util/price";
import { CURRENCY_SYMBOL, PATHS } from '../../constants/appConfig';
import { DELIVERY_STATUS } from '../../types/orders';

const AdminTable: FC<IAdminTableProps<any>> = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  let keys = Object.keys(data[0]);
  keys = KEYS;

  const isStatusInData = keys.includes('status');

  return (
    <StyledTable>
      <thead>
        <tr>
          {isStatusInData && <StyledHeaderCell />}
          {keys.map((key) => (
            <StyledHeaderCell key={key}>{RENDER_KEYS[key]}</StyledHeaderCell>
          ))}
        </tr>
      </thead>
      <tbody>
      {data.map((item) => (
        <StyledHoverRow key={item.id}>
          {isStatusInData && (
            <StyledDataCell>
              <Circle type={item.status} />
            </StyledDataCell>
          )}
          {keys.map((key) => (
            <StyledDataCell key={key}>
              {key === 'orderProducts' ? (
                JSON.parse(item[key]).map((orderProduct: IOrderProduct) => (
                  <p key={orderProduct.id}>
                    {`• ${orderProduct.name} — ${orderProduct.price} ${CURRENCY_SYMBOL} × ${orderProduct.count} (id: `}
                    <StyledLink to={PATHS.product.replace(':id', orderProduct.id)} target='_blank'>
                      {orderProduct.id}
                    </StyledLink>
                    {`)`}
                  </p>
                ))
              ) : key === 'totalPrice' ? (
                `${getTotalCartPrice(JSON.parse(item['orderProducts']))} ${CURRENCY_SYMBOL}`
              ) : key === 'deliveryType' ? (
                item[key] === 'mall' ? 'ТРЦ "Cherry Mall"' : 'Нова Пошта'
              ) : key === 'status' ? (
                RENDER_STATUSES[item[key] as DELIVERY_STATUS]
              ) : (
                item[key]
              )}
            </StyledDataCell>
          ))}
        </StyledHoverRow>
      ))}
      </tbody>
    </StyledTable>
  )
};

export default AdminTable;
