import React from "react";
import styled from "styled-components";
import { breakpoints, marketplaceColors } from "../../constants/styleConfig";

export interface IInputRadioProps {
  id: string;
  label: string;
  isChecked: boolean;
}

interface IProps extends IInputRadioProps {
  name: string;
  onClick: () => void;
}

const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const RadioBox = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 2px solid ${marketplaceColors.primary};
  border-radius: 50%;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

const Input = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;

  &[checked] + ${RadioBox} > ${Dot} {
    background-color: ${marketplaceColors.primary};
  }
`;

const Label = styled.div`
  font-size: 0.875rem;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 0.75rem;
  }
`;

const InputRadio = ({
  id,
  name,
  label,
  isChecked,
  onClick,
}: IProps) => {
  return (
    <Container>
      <Input
        id={id}
        name={name}
        type='radio'
        defaultChecked={isChecked}
        onClick={onClick}
      />
      <RadioBox>
        <Dot />
      </RadioBox>
      <Label>{label}</Label>
    </Container>
  );
};

export { InputRadio };
