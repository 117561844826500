import { Dispatch, ReactNode, createContext, useContext, useReducer } from "react";
import { IAuth, AuthAction, AUTH_ACTION_TYPE } from "../types/reducers";

const defaultAuth: IAuth = {
  isAuthenticated: false,
  username: '',
  token: '',
};

const AuthContext = createContext<IAuth>(defaultAuth);
const AuthDispatchContext = createContext<Dispatch<AuthAction>>(() => {});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const useAuthDispatch = () => {
  return useContext(AuthDispatchContext);
};

const authReducer = (auth: IAuth, action: AuthAction) => {
  switch (action.type) {
    case AUTH_ACTION_TYPE.SET: {
      const { username, token } = action;
      return {
        isAuthenticated: true,
        username: username,
        token: token,
      };
    }
    case AUTH_ACTION_TYPE.CLEAR: {
      return defaultAuth;
    }
    default: {
      return auth;
    }
  };
};

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [auth, dispatchAuth] = useReducer(authReducer, defaultAuth);
  
  return (
    <AuthContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatchAuth}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
