import styled from "styled-components";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { marketplaceColors } from "../../constants/styleConfig";

export const ErrorScreenWrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StatusCode = styled.div`
  font-size: 4rem;
`;

export const Message = styled.div`
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: ${marketplaceColors.gray5};
`;
