import './App.css';
import { Router } from './Router';
import AuthContextProvider from './contexts/AuthContext';
import { ProductCartContextProvider } from './contexts/ProductCartContext';

const App = () => {
  return (
    <AuthContextProvider>
      <ProductCartContextProvider>
        <Router />
      </ProductCartContextProvider>
    </AuthContextProvider>
  );
}

export default App;
