import { FC, ChangeEvent } from 'react';
import styled from 'styled-components';
import { breakpoints, colors, marketplaceColors } from "../constants/styleConfig";

const Container = styled.label`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
`;

const StyledTextarea = styled.textarea<{ $status?: 'success' | 'error' | 'warning' }>`
  text-indent: 0.5rem;
  width: 100%;
  height: 10rem;
  padding: 0 0 1.5rem;
  font-size: 1rem;
  border: 1px solid ${({ $status }) => {
    switch ($status) {
      case 'success':
        return marketplaceColors.success;
      case 'error':
        return marketplaceColors.danger;
      case 'warning':
        return marketplaceColors.warning;
      default:
        return colors.borderLight;
    }
  }};
  outline: none;
  resize: vertical;
  box-sizing: border-box;
  border-radius: 0.375rem;
  color: ${marketplaceColors.gray9};
  padding: 0.5rem;
  font-family: 'Geologica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  &::placeholder {
    color: ${marketplaceColors.gray6};
    font-size: 1rem;
  }
`;

const LabelText = styled.div`
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;  
  font-weight: 700;
  color: ${marketplaceColors.gray6};

  @media (min-width: ${breakpoints.sm}) {
    font-size: 0.75rem;
  }
`;

const Limit = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 0.75rem;
  color: ${marketplaceColors.gray4};
`

interface IProps {
  status?: 'success' | 'error' | 'warning' | false;
  label?: string;
  value?: string;
  name?: string;
  limit?: number;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: FC<IProps> = ({ label = '', status,  value = '', limit, onChange, ...textAreaProps }) => {
  return (
    <Container>
      {label && <LabelText>{label}</LabelText>}
      <StyledTextarea $status={status || undefined} value={value} onChange={(e) => {
        if (limit && e.target.value.length > limit) return;
        onChange && onChange(e);
      }} {...textAreaProps} />
      {limit && (<Limit>{value.length} / {limit}</Limit>)}
    </Container>
  )
};

export default TextArea;
