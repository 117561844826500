import styled from "styled-components";

interface IFlexContainerProps {
  direction?: string;
  justify?: string;
  align?: string;
  wrap?: string;
  gap?: string;
  margin?: string;
  padding?: string;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  height?: string;
  width?: string;
}

const FlexContainer = styled.div<IFlexContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  gap: ${({ gap }) => gap || 0};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  overflow: ${({ overflow }) => overflow || 'initial'};
  overflow-x: ${({ overflowX }) => overflowX || 'initial'};
  overflow-y: ${({ overflowY }) => overflowY || 'initial'};
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
`;

export default FlexContainer;
