import styled from "styled-components";
import { breakpoints } from "../../constants/styleConfig";

export const RelatedWrapper = styled.div`
  
`;

export const RelatedList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  & > a {
    width: 100%;

    & > div {
      width: 100%;
      flex-direction: row;
      padding: 0 1rem 0 0;

      & > img {
        width: 120px;
        height: 120px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
