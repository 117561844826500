import styled from 'styled-components';
import { colors, marketplaceColors } from "../../constants/styleConfig";

export const Wrapper = styled.div`
  background-color: ${colors.backgroundSnow};
  width: fit-content;
  padding: 0.5rem 0.75rem;
  border: 1px solid ${marketplaceColors.gray3};
  border-radius: 50px;
  color: ${marketplaceColors.gray7};
  cursor: pointer;
  
  &:hover {
    color: ${marketplaceColors.primaryDark};
  }
`;
