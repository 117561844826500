import styled, { keyframes } from "styled-components";
import { marketplaceColors, animations } from "../constants/styleConfig";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top-color: ${marketplaceColors.primary};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} ${animations.timeSlow} linear infinite;
  margin: 20px auto;
`;

export default Loader;
