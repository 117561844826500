import { MouseEventHandler } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from 'styled-components';
import { Carousel, CarouselProps } from 'react-responsive-carousel';
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { GoSquare, GoSquareFill } from "react-icons/go";
import { breakpoints } from "../../constants/styleConfig";

const Wrapper = styled.div`
  overflow: hidden;
  
  .carousel-slider {
    padding-bottom: 44px;

    @media (min-width: ${breakpoints.sm}) {
      padding-bottom: 10px;
    }
  }
  
  .slider-wrapper {
    width: 80%;

    @media (min-width: ${breakpoints.sm}) {
      width: 95%;
    }
  }
`;

const IndicatorContainer = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  user-select: none;
  background-color: white;
`;

const ArrowContainer = styled.div<{ $isLeft?: boolean; $isArrowShadow?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 44px;
  left: ${({ $isLeft }) => $isLeft ? 0 : 'unset'};
  right: ${({ $isLeft }) => $isLeft ? 'unset' : 0};
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  user-select: none;
  cursor: pointer;
  pointer-events: auto;
  


  @media (min-width: ${breakpoints.sm}) {
    bottom: 10px;
    background: none;
  }
`;

interface IArrow {
  onClickHandler: MouseEventHandler<HTMLDivElement>;
  isArrowShadow: boolean;
}

interface IAppCarousel extends Partial<CarouselProps> {
  isArrowShadow?: boolean;
}

const Indicator = (onClickHandler: MouseEventHandler<HTMLDivElement>, isSelected: boolean) => (
  <IndicatorContainer onClick={onClickHandler}>
    {isSelected ? <GoSquareFill /> : <GoSquare />}
  </IndicatorContainer>
);

const ArrowBack = ({ onClickHandler, isArrowShadow }: IArrow) => (
  <ArrowContainer onClick={onClickHandler} $isLeft $isArrowShadow={isArrowShadow}>
    <MdArrowBackIosNew />
  </ArrowContainer>
);

const ArrowForward = ({ onClickHandler, isArrowShadow }: IArrow) => (
  <ArrowContainer onClick={onClickHandler} $isArrowShadow={isArrowShadow}>
    <MdArrowForwardIos />
  </ArrowContainer>
);

const AppCarousel = ({ children, isArrowShadow = false, ...carouselProps }: IAppCarousel) => {
  return (
    <Wrapper>
      <Carousel
        infiniteLoop
        showStatus={false}
        {...carouselProps}
        renderIndicator={Indicator}
        renderArrowPrev={(onClickHandler) => ArrowBack({ onClickHandler, isArrowShadow} )}
        renderArrowNext={(onClickHandler) => ArrowForward({ onClickHandler, isArrowShadow })}
      >
        {children}
      </Carousel>
    </Wrapper>
  );
};

export { AppCarousel };
