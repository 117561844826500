import { SetStateAction, useState } from 'react';
import products from '../../data/products.json';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PATHS } from '../../constants/appConfig';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start; /* Выравниваем элементы по левому краю */
  align-items: center;
  margin-bottom: 20px;
  width: 100%; /* Для корректного выравнивания на всю ширину */
`;

const StyledBackButton = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const StyledInput = styled.input`
  padding: 15px;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  width: 300px;
  margin: 0 auto; /* Оставляем инпут посередине */

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const StyledHeaderCell = styled.th`
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
  font-weight: bold;
`;

const StyledDataCell = styled.td`
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;

  &:first-child {
    padding-left: 12px;
  }
`;

const StyledHoverRow = styled.tr`
  &:hover {
    background-color: #eaeaea;
  }
`;

const AdminProductCatalogScreen = () => {
    const [searchQuery, setSearchQuery] = useState('');
    
    const handleSearchInputChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setSearchQuery(event.target.value);
    };

    return (
        <StyledContainer>
          <StyledContentWrapper>
            <Link to={PATHS.main}>
              <StyledBackButton>
                ← Назад
              </StyledBackButton>
            </Link>
            <StyledInput 
              type="text" 
              value={searchQuery} 
              onChange={handleSearchInputChange} 
              placeholder="Пошук..."
            />
          </StyledContentWrapper>
          <StyledTable>
            <thead>
              <tr>
                <StyledHeaderCell>Виробник</StyledHeaderCell>
                <StyledHeaderCell>Категорія</StyledHeaderCell>
                <StyledHeaderCell>НАЗВА</StyledHeaderCell>
                <StyledHeaderCell>Фасування/розмір</StyledHeaderCell>
                <StyledHeaderCell>Смак/колір/</StyledHeaderCell>
                <StyledHeaderCell>Ціна</StyledHeaderCell>
              </tr>
            </thead>
            <tbody>
              {products.data.map((product, index) => {
                if (
                  product.Виробник.toLowerCase().includes(searchQuery.toLowerCase()) 
                  ||product.Категорія.toLowerCase().includes(searchQuery.toLowerCase())
                  ||product.НАЗВА.toLowerCase().includes(searchQuery.toLowerCase())
                  ||product['Фасування/розмір']?.toLowerCase().includes(searchQuery.toLowerCase())
                  ||product['Смак/колір']?.toLowerCase().includes(searchQuery.toLowerCase())
                ) {
                  return (
                    <StyledHoverRow key={index}>
                      <StyledDataCell>{product.Виробник}</StyledDataCell>
                      <StyledDataCell>{product.Категорія}</StyledDataCell>
                      <StyledDataCell>{product.НАЗВА}</StyledDataCell>
                      <StyledDataCell>{product['Фасування/розмір']}</StyledDataCell>
                      <StyledDataCell>{product['Смак/колір']}</StyledDataCell>
                      <StyledDataCell>{product.Ціна} грн.</StyledDataCell>
                    </StyledHoverRow>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </StyledTable>
        </StyledContainer>
    );
};

export { AdminProductCatalogScreen };
