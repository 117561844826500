import styled from "styled-components";
import { marketplaceColors } from "../../constants/styleConfig";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const StyledContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

export const StyledBackButton = styled.div`
  width: max-content;
  padding: 10px 20px;
  font-size: 16px;
  background-color: ${marketplaceColors.primary};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const Title = styled.p`
  font-size: 1.25rem;
  margin: 0 auto;
`;