import styled from "styled-components";
import { Link } from "react-router-dom";

import { IStyledCircle } from './config';
import { marketplaceColors } from "../../constants/styleConfig";
import { DELIVERY_STATUS } from "../../types/orders";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const StyledHeaderCell = styled.th`
  border: 1px solid #dddddd;
  padding: 8px;
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: center;
`;

export const StyledDataCell = styled.td`
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;

  &:first-child {
    padding-left: 12px;
  }
`;

export const StyledHoverRow = styled.tr`
  &:hover {
    background-color: #eaeaea;
  }
`;

export const StyledLink = styled(Link)`
  background: ${marketplaceColors.primaryGhost};
  padding: 0 10px;
  border-radius: 25px;
  
  &:hover {
    background: ${marketplaceColors.primaryGhostDark};
  }
`;

export const Circle = styled.div<IStyledCircle>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: relative;
  background-color: ${(props) => {
    const { danger, warning, primary, primaryLight, success, gray3 } = marketplaceColors;
    
    switch(props.type) {
      case DELIVERY_STATUS.CREATED:
        return danger;
      case DELIVERY_STATUS.CONFIRMED:
        return warning;
      case DELIVERY_STATUS.SENT:
        return primaryLight;
      case DELIVERY_STATUS.DELIVERED:
        return primary;
      case DELIVERY_STATUS.DONE:
        return success;
      case DELIVERY_STATUS.CANCELLED:
        return gray3;
    }
  }};
  backdrop-filter: blur(5px);
  box-shadow: 
    0 4px 8px rgba(0, 0, 0, 0.2),
    inset 0 2px 4px rgba(255, 255, 255, 0.3),
    0 0 15px ${(props) => {
      switch(props.type) {
        case DELIVERY_STATUS.CREATED:
          return 'rgba(255, 0, 0, 0.4)';
        case DELIVERY_STATUS.CONFIRMED:
          return 'rgba(255, 165, 0, 0.4)';
        case DELIVERY_STATUS.SENT:
          return 'rgba(0, 0, 255, 0.2)';
        case DELIVERY_STATUS.DELIVERED:
          return 'rgba(0, 0, 255, 0.4)';
        case DELIVERY_STATUS.DONE:
          return 'rgba(0, 255, 0, 0.4)';
        case DELIVERY_STATUS.CANCELLED:
          return 'rgba(0, 0, 0, 0.4)';
      }
    }};
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 50%;
    background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    pointer-events: none;
  }
`;
