import { useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import {
  Container,
  LabelText,
  Option,
  OptionsContainer,
  SelectedValue,
  Wrapper
} from "./styles";

interface ISelectProps {
  label?: string;
  options: string[];
  selectedValue: string;
  placeholder?: string;
  onSelect: (value: any) => void;
};

const Select = ({
  label,
  options,
  selectedValue,
  placeholder = 'Select...',
  onSelect,
}: ISelectProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const selectedOption = options.find(option => option === selectedValue);

  return (
    <Wrapper>
      {label && <LabelText>{label}</LabelText>}
      <Container>
        <SelectedValue onClick={() => setIsOptionsOpen(prev => !prev)}>
          {selectedOption || placeholder}
          <FaCaretDown />
        </SelectedValue>
        {isOptionsOpen && <OptionsContainer>
          {options.map(option => (
            <Option
              key={`${label}_${option}`}
              $isActive={selectedValue === option}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect(option);
                setIsOptionsOpen(false);
              }}
            >
              {option}
            </Option>
          ))}
        </OptionsContainer>}
      </Container>
    </Wrapper>
  );
};

export { Select };
