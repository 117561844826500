import styled from "styled-components";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { colors } from "../../constants/styleConfig";
import { AppButton } from "../../components";
import { Link } from "react-router-dom";

export const ScreenWrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
`;

export const ClientDataForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 2rem;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export const ListContainer = styled.div`
  min-height: 10rem;
  margin-bottom: 2rem;
  padding: 0.5rem;
  border: 1px solid ${colors.borderLight};
`;

export const InfoContainer = styled.div`
  min-height: 10rem;
  margin-bottom: 2rem;
  padding: 0.5rem;
  font-size: 14px;
  color: ${colors.textDark};
  border: 1px solid ${colors.borderLight};
`;

export const ClientDataItem = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 300px));
  gap: 20px;
`;

export const ClientDataValue = styled.div`

`;

export const ClientDataLabel = styled.div`
  font-weight: 700;
`;

export const FormButton = styled(AppButton)`
  height: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.5rem;

  @media screen and (min-width: 600px) {
    align-self: flex-end;
    flex: 0 0 4rem;
  }
`;

export const LinkWrapper = styled(Link)`
  display: block;
  max-width: 100%;
  word-wrap: break-word;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
