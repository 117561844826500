import { FC } from 'react';
import { Link } from "react-router-dom";

import { PATHS } from "../../constants/appConfig";
import { Breadcrumbs, PageWrapper } from "../../components";
import { ContentType, HeaderType, IArticle, ARTICLE_HEADER_TYPE, ARTICLE_CONTENT_TYPE } from "../../types/articles";
import { fragments, headers } from '../../articles';
import { replaceUrlParam } from "../../util/helpers";
import { sanitizeHTML } from '../../util/formatData';

const SelectedHeader: FC<{ header: HeaderType }> = ({ header }) => {
  switch (header.type) {
    case ARTICLE_HEADER_TYPE.LEFT_IMAGE_RIGHT_TEXT:
      return <headers.HeaderLeftImageRightText title={header.title} image={header.image} imageAlt={header.imageAlt} />
    default:
      return null;
  };
};

const SelectedComponent: FC<{ content: ContentType }> = ({ content }) => {
  switch (content.type) {
    case ARTICLE_CONTENT_TYPE.TEXT:
      return <fragments.Text dangerouslySetInnerHTML={{ __html: sanitizeHTML(content.text) }} />;
    case ARTICLE_CONTENT_TYPE.LIST_ITEM:
      return (
        <fragments.ListContainer>
          {content.text.map((item: string, index: number) => (
            <fragments.ListItem key={`${item}_${index}`} dangerouslySetInnerHTML={{ __html: sanitizeHTML(item) }} />
          ))}
        </fragments.ListContainer>
      );
    default:
      return null;
  };
};

const ArticleScreen: FC<{ article: IArticle }> = ({ article }) => {
  const replaceToCategoryId = (): string => {
    return replaceUrlParam(
      PATHS.articlesCategory,
      {
        param: ':category',
        value: article.categoryId,
      },
    );
  };

  return (
    <PageWrapper>
      <Breadcrumbs>
        <Link to={PATHS.articles}>← Блог</Link> / <Link to={replaceToCategoryId()}>{article.category}</Link> / {article.breadcrumbs}
      </Breadcrumbs>
      <fragments.ArticleContainer>
        <SelectedHeader header={article.header}/>
        {article.content.map((content, index) => (
          <SelectedComponent key={`${article.id}_${content.type}_${index}`} content={content}/>
        ))}
      </fragments.ArticleContainer>
    </PageWrapper>
  )
};

export default ArticleScreen;
