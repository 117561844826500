import { FC, ChangeEvent, useState, useEffect } from "react";

import { AppButton, InputField, OrderBlock, PageWrapper, Loader, Toast, FlexContainer, Title as PageTitle } from "../../components";
import { LOCALSTORAGE_ORDERS_IDS, NUMBER_SYMBOL } from "../../constants/appConfig";
import { getOrderByNumber, getOrdersById } from "../../util/api";
import { IOrder } from "../../types/orders";
import { OrdersScreenContainer, Title, Placeholder, FirstColumn, SecondColumn, ThirdColumn } from './styles';
import { formatPhone, reformatPhone } from "../../util/formatData";

interface ISearchOrderForm {
  id: string;
  phoneNumber: string;
}

const OrdersScreen: FC = () => {
  const [orders, setOrders] = useState<IOrder[] | null>(null);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [searchOrderForm, setSearchOrderForm] = useState<ISearchOrderForm>({
    id: '',
    phoneNumber: '',
  });

  useEffect(() => {
    const localStorageOrdersIdsData = localStorage.getItem(LOCALSTORAGE_ORDERS_IDS);

    if (localStorageOrdersIdsData) {
      const orderIds: string[] = JSON.parse(localStorageOrdersIdsData);

      getOrdersById(orderIds).then((ordersResponse) => {
        setOrders(ordersResponse);
      });
    }
  }, []);

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>, target: string): void => {
    setSearchOrderForm({
      ...searchOrderForm,
      [target]: reformatPhone(event),
    });
  };

  const searchOrder = async () => {
    setIsLoading(true);

    await getOrderByNumber(searchOrderForm.id, searchOrderForm.phoneNumber)
      .then((orderResponse) => {
        if (orderResponse) {
          const { id, city, createdAt, deliveryType, firstName, lastName, orderProducts, phoneNumber, status, warehouse } = orderResponse;

          setOrder({
            id,
            city,
            createdAt,
            deliveryType,
            firstName,
            lastName,
            orderProducts,
            phoneNumber,
            status,
            warehouse,
          });
          setError('');
        } else if (!searchOrderForm.id || !searchOrderForm.phoneNumber) {
          setError('Форма пошуку не заповнена');
        } else {
          setOrder(null);
          setError('Замовлення не знайдено');
        }
    });

    setIsLoading(false);
  };

  const OrderSection: FC = () => {
    if (isLoading) {
      return (
        <FlexContainer justify='center' align='center' height='100%'>
          <Loader />
        </FlexContainer>
      );
    } else if (error) {
      return (
        <FlexContainer justify='center' height='100%'>
          <Toast type='info' prefix={error} />
        </FlexContainer>
      );
    } else if (order) {
      return (
        <OrderBlock
          key={`${order.id}_${order.phoneNumber}`}
          id={order.id}
          firstName={order.firstName}
          lastName={order.lastName}
          phoneNumber={order.phoneNumber}
          city={order.city}
          orderProducts={order.orderProducts}
          status={order.status}
          isCloseable={false}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <PageWrapper>
      <PageTitle>Замовлення</PageTitle>
      <OrdersScreenContainer>
        <FirstColumn>
          <Title>Знайти замовлення</Title>
          <InputField
            label='Номер замовлення'
            placeholder='0000000'
            onChange={(event) => handleFormChange(event, 'id')}
          />
          <InputField
            label='Номер телефону'
            value={formatPhone(searchOrderForm.phoneNumber)}
            placeholder='+380 (00) 000 00 00'
            onChange={(event) => handleFormChange(event, 'phoneNumber')}
          />
          <AppButton onClick={() => searchOrder()}>Пошук</AppButton>
        </FirstColumn>
        <SecondColumn>
          <Title>Замовлення { order && `${NUMBER_SYMBOL}${order.id}` }</Title>
          <OrderSection />
        </SecondColumn>
        <ThirdColumn>
          <Title>Останні замовлення</Title>
          {
            orders
              ? orders.map((order) => (
                <OrderBlock
                  key={`${order.id}_${order.phoneNumber}`}
                  id={order.id}
                  firstName={order.firstName}
                  lastName={order.lastName}
                  phoneNumber={order.phoneNumber}
                  city={order.city}
                  orderProducts={order.orderProducts}
                  status={order.status}
                />
              ))
              : <Placeholder>Немає збережених замовлень</Placeholder>
          }
        </ThirdColumn>
      </OrdersScreenContainer>
    </PageWrapper>
  );
};

export default OrdersScreen;
