import { MutableRefObject, useEffect } from "react";

export const useClickOutside = (ref: MutableRefObject<any>, onClick: () => void) => {
  useEffect(() => {
    const handleClickOutsie = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutsie);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsie);
    };
  }, [ref, onClick]);
};
