const getPrecedingSubstring = (text: string, until: string) => {
  const regex = new RegExp(`(.*?)(?=${until.toLowerCase()})`, 'i');
  const match = text.match(regex);

  return match ? match[0] : null;
};

type paramType = {
  param: string,
  value: string,
};

const replaceUrlParam = (url: string, param: paramType): string => {
  return url.replace(param.param, param.value);
};

export {
  getPrecedingSubstring,
  replaceUrlParam,
};
