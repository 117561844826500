import { FC } from 'react';

import { Loader, PageWrapper } from '../../components';

const LoadingScreen: FC = () => (
  <PageWrapper display='flex' alignItems='center'>
    <Loader />
  </PageWrapper>
);

export default LoadingScreen;
