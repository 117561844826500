import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import { animations, breakpoints } from "../constants/styleConfig";

interface IProps {
  $isActive: boolean;
}

const AngleIcon = styled(FaAngleDown)<IProps>`
  transform: rotate(${({ $isActive }) => $isActive ? '180deg' : '0'});
  transition: transform ${animations.timeDefault} ease;

  @media (min-width: ${breakpoints.md}) {
    transform: rotate(0);
  }
`;

export default AngleIcon;
