import styled from 'styled-components';
import { animations, breakpoints, layers } from '../constants/styleConfig';

interface IProps {
  $isActive: boolean;
  $isClosedOnBreakpoint?: boolean;
};

const Overlay = styled.div<IProps>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: ${({ $isActive }) => $isActive ? '0%' : '100%'};
  z-index: ${layers.modalOverlay};
  background-color: ${({ $isActive }) => $isActive ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
  transition: background-color ${animations.timeDefault}, right ${({ $isActive }) => $isActive ? '0s' : `0s ${animations.timeDefault}`};

  @media (min-width: ${breakpoints.md}) {
    right: ${({ $isClosedOnBreakpoint }) => !$isClosedOnBreakpoint ? '0%' : '100%'};
    background-color: ${({ $isClosedOnBreakpoint }) => !$isClosedOnBreakpoint ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
    transition: background-color ${animations.timeDefault}, right ${({ $isClosedOnBreakpoint }) => !$isClosedOnBreakpoint ? '0s' : `0s ${animations.timeDefault}`};
  }
`;

export default Overlay;
