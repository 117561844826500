import { categoriesType } from "../../types/articles";

const categories: categoriesType[] = [
    {
        title: 'Протеїни',
        image: require('./assets/category_protein.png'),
        url: 'proteins',
    },
    {
        title: 'Амінокислоти',
        image: require('./assets/category_amino.png'),
        url: 'amino-acids',
    },
];

export default categories;
