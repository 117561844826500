import styled from "styled-components";
import { breakpoints, colors } from "../constants/styleConfig";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  font-size: 1.5rem;
  border: 1px solid ${colors.paleGrey};
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${colors.backgroundLight};
  }

  @media (min-width: ${breakpoints.md}) {
    width: 20px;
    height: 20px;
    font-size: 0.875rem;
  }
`;

export default ButtonWrapper;