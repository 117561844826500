import styled from "styled-components";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { animations, breakpoints, marketplaceColors } from "../../constants/styleConfig";

export const MainScreenWrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const CategoryCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0.5rem 0.25rem 0.5rem;
  border: 1px solid ${marketplaceColors.gray1};
  transition: all ${animations.timeFast};

  &:hover {
    color: ${marketplaceColors.primaryDark};
    border-color: ${marketplaceColors.primaryDark};
    box-shadow: 0 0 12px 0 ${marketplaceColors.primaryGhostDark};
  }

  @media (min-width: ${breakpoints.sm}) {
    padding: 1rem 0.25rem 1.5rem;
  }
`;

export const CategoryImage = styled.img`
  max-width: 100%;
  margin-bottom: 0.5rem;

  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 1rem;
  }
`;

export const CategoryLabel = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 2rem;
  font-weight: 600;
  color: ${marketplaceColors.gray9};
  padding: 1rem 0.5rem;
  text-align: center;
`;

export const FormWrapper = styled.div`
  margin: 4rem 0;
  padding: 2rem;
  border-top: 1px solid ${marketplaceColors.gray1};
  border-bottom: 1px solid ${marketplaceColors.gray1};
`;

export const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 0.5rem;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
    padding: 2rem 1rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media (min-width: ${breakpoints.xl}) {
    gap: 1.5rem;
  }
`;

export const CarouselWrapper = styled.div`
  padding: 1rem 0;
`;
