import { ITag } from "../../types/products";
import { TagContainer, Text, DeleteIcon } from "./styles";

const Tag = ({ type, label, onDelete }: ITag) => {
  return (
    <TagContainer type={type}>
      <Text>{label}</Text>
      <DeleteIcon type={type} onClick={onDelete} />
    </TagContainer>
  );
};

export { Tag };
