import styled from "styled-components";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { marketplaceColors } from "../../constants/styleConfig";

export const LoginScreenWrapper = styled(PageWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled.div`

`;

export const ErrorMessage = styled.div`
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: ${marketplaceColors.danger};
`;
