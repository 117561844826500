import { Dispatch, SetStateAction, useState} from "react";
import { FaFilter } from "react-icons/fa";

import { IFilterGroup, IFilterQueryCheckbox } from "../../types/reducers";
import { FILTERS } from "../../constants/appConfig";
import {
  Container,
  CheckboxGroupContainer,
  ButtonContainer,
  FilterButton,
  AdaptiveModalHeader,
  NavbarBackArrow,
  CartModalTitle,
  AppButtonModalWrapper,
  Text,
} from "./styles";
import {
  CheckboxGroup,
  AppButton,
  Overlay,
  AdaptiveModal,
  FlexContainer
} from "../index";

interface ISearchFilterProps {
  selectedCheckboxes: IFilterQueryCheckbox;
  setSelectedCheckboxes: Dispatch<SetStateAction<IFilterQueryCheckbox>>;
  onSearch: () => void;
}

const SearchFilter = ({ selectedCheckboxes, setSelectedCheckboxes, onSearch }: ISearchFilterProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onClickCheckbox = (id: string, filterItem: IFilterGroup) => {
      const selectedGroupList = selectedCheckboxes[filterItem.id];

      if (selectedGroupList.some(selectedId => selectedId === id)) {
          setSelectedCheckboxes((prev) => ({
              ...prev,
              [filterItem.id]: prev[filterItem.id].filter(selectedId => selectedId !== id)
          }));
      } else {
          const checkedItem = filterItem.list.find(item => item.id === id);

          if (checkedItem) {
              setSelectedCheckboxes(prev => ({
                  ...prev,
                  [filterItem.id]: [...prev[filterItem.id], checkedItem.id]
              }));
          }
      }
  };

  return (
    <Container>
      <Overlay
        $isActive={isMenuOpen}
        $isClosedOnBreakpoint={true}
        onClick={(e) => {
          setIsMenuOpen(false);
          e.stopPropagation();
        }}
      />
      <AdaptiveModal
        $isActive={isMenuOpen}
        $isDirectionColumn={true}
      >
        <AdaptiveModalHeader>
          <NavbarBackArrow onClick={() => setIsMenuOpen(false)} />
          <CartModalTitle>Фільтр</CartModalTitle>
        </AdaptiveModalHeader>
        {FILTERS.map((filterItem) => {
          const list = filterItem.list.map(item => ({
            ...item,
            isChecked: !!selectedCheckboxes[filterItem.id]?.some(selectedId => selectedId === item.id),
          }));

          return (
            <CheckboxGroupContainer key={filterItem.id}>
              <CheckboxGroup
                id={filterItem.id}
                label={filterItem.label}
                checkboxList={list}
                onClickCheckbox={(id) => onClickCheckbox(id, filterItem)}
              />
            </CheckboxGroupContainer>
          )
        })}
        <AppButtonModalWrapper>
          <AppButton
            size="small"
            buttonStyle="fill"
            onClick={() => {
              onSearch();
              setIsMenuOpen(false);
            }}
          >
            Пошук
          </AppButton>
        </AppButtonModalWrapper>
      </AdaptiveModal>
      <ButtonContainer>
        <FlexContainer align='center' onClick={() => setIsMenuOpen(true)}>
          <FilterButton>
            <FaFilter />
          </FilterButton>
          <Text>Фільтр</Text>
        </FlexContainer>
        <AppButton
          size="small"
          buttonStyle="fill"
          onClick={onSearch}
        >
          Пошук
        </AppButton>
      </ButtonContainer>
    </Container>
  );
};

export { SearchFilter };
