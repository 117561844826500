import styled from "styled-components";
import { InputField, IInputFieldProps } from "../InputField/InputField";
import { marketplaceColors, colors, layers } from "../../constants/styleConfig";
import { FocusEvent, useRef, useState } from "react";
import { useClickOutside } from "../../util/hooks";

interface IOption {
  value: string;
  label: string;
}

interface IProps extends IInputFieldProps {
  options: IOption[];
  onSelectOption: (option: IOption) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement, Element>) => void;
};

const Container = styled.div`
  position: relative;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 20rem;
  font-size: 0.75rem;
  background-color: ${marketplaceColors.white};
  border: 1px solid ${colors.borderLight};
  border-top: none;
  border-radius: 0 0 8px 8px;
  width: 100%;
  z-index: ${layers.modal};
  overflow: scroll;
`;

const Option = styled.div`
  padding: 0.375rem 0.75rem;
  background-color: ${marketplaceColors.white};
  border-bottom: 1px solid ${colors.borderLight};
  cursor: pointer;

  &:hover {
    background-color: ${marketplaceColors.gray0};
  }
`;

const InputSelect = ({ options, onFocus, onSelectOption, ...inputFieldProps}: IProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setIsOpened(false));

  return (
    <Container ref={wrapperRef}>
      <InputField
        {...inputFieldProps}
        onFocus={(e) => {
          onFocus && onFocus(e);
          setIsOpened(true);
        }}
      />
      {options.length && isOpened
        ? <OptionsWrapper>
          {options.map(option => (
            <Option
              key={`${inputFieldProps.id}_${option.value}`}
              onClick={() => onSelectOption(option)}
            >
              {option.label}
            </Option>
          ))}
        </OptionsWrapper>
        : null
      }
    </Container>
  );
};

export { InputSelect };
