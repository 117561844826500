import styled from "styled-components";
import { Link } from "react-router-dom";
import { breakpoints, colors } from "../../constants/styleConfig";
import ButtonWrapper from "../ButtonWrapper";

const CommonRowField = `
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CartRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0.25rem;
  border-bottom: 1px solid ${colors.borderLight};

  @media (min-width: ${breakpoints.sm}) {
    padding: 0.5rem 1rem;
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 60px minmax(0, 7fr) minmax(0, 3fr) minmax(0, 2fr) 40px;
  }
`;

export const ImageRow = styled(Link)`
  ${CommonRowField};
  grid-column: 1 / 4;
  grid-row: 1 / 2;

  & > img {
    width: 60%;  // Сохраняем изображение в пределах контейнера
    max-width: 100%;  // Не даем изображению быть больше его естественного размера
    height: auto;  // Поддерживаем пропорции при изменении ширины
  }

  @media (min-width: ${breakpoints.md}) {
    & > img {
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    grid-column: 1 / 2;
  }
`;

export const TitleRow = styled(Link)`
  ${CommonRowField};
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${breakpoints.md}) {
    grid-column: 2 / 3;  // Меняем колонки для больших экранов
    grid-row: 1 / 2;     // Меняем положение по вертикали
    flex-direction: row;
    align-items: center;
  }
`;

export const PriceRow = styled.div`
  ${CommonRowField};
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: ${breakpoints.md}) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    flex-direction: row;
    align-items: center;
  }
`;

export const CounterRow = styled.div`
  ${CommonRowField};
  grid-column: 1 / 2;
  grid-row: 3 / 4;

  @media (min-width: ${breakpoints.md}) {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
  }
`;

export const DeleteRow = styled.div`
  ${CommonRowField};
  grid-column: 3 / 4;
  grid-row: 3 / 4;
  justify-content: flex-end;

  @media (min-width: ${breakpoints.md}) {
    grid-column: 5 / 6;
    grid-row: 1 / 2;
    justify-content: flex-start;
  }
`;

export const Image = styled.img`
  width: 70%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    font-size: 0.75rem;
  }
`;

export const Name = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${breakpoints.md}) {
    font-size: 0.75rem;
  }
`;

export const Country = styled.div`
  position: relative;
  font-size: 1rem;
  color: ${colors.paleGrey};

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    left: 0;
    right: unset;
    bottom: 5px;
    font-size: 0.75rem;
  }
`;

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const Counter = styled.div`
  width: 30px;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    font-size: 0.875rem;
  }
`;

export const DeleteButtonWrapper = styled(ButtonWrapper)`
  color: ${colors.highlightRed};
  border: 1px solid ${colors.highlightRed};

  &:hover {
    background-color: ${colors.paleRed};
  }
`;

export const Price = styled.div`
  position: relative;
  font-size: 1rem;
  color: ${colors.paleGrey};

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    left: 0;
    right: unset;
    bottom: 5px;
    font-size: 0.75rem;
  }
`;

export const TotalPrice = styled.div`
  font-weight: 700;
  font-size: 1rem;

  @media (min-width: ${breakpoints.md}) {
    font-size: 0.75rem;
  }
`;
