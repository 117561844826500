import { InputHTMLAttributes, KeyboardEvent, useState } from "react";
import { SearchContainer, SearchButton, SearchInput, SearchIcon, InputContainer, CloseIcon } from "./styles";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {PATHS} from "../../constants/appConfig";

interface ISearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
};

const SearchInputField = ({
  ...inputProps
}: ISearchInputProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = (value: string) => {
    if (location.pathname === '/search') {
        searchParams.set('query', value);
        setSearchParams(searchParams);
    } else {
      navigate(`${PATHS.search}?query=${value}`);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch(searchValue);
    }
  };

  return (
    <SearchContainer>
      <InputContainer>
        <SearchInput
          name='Search'
          placeholder='Пошук...'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyDown}
          {...inputProps}
        />
        <CloseIcon onClick={() => setSearchValue('')} />
      </InputContainer>
      <SearchButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSearch(searchValue);
        }}
      >
        <SearchIcon />
      </SearchButton>
    </SearchContainer>
  );
};

export { SearchInputField };
