import styled from "styled-components";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { breakpoints, marketplaceColors } from "../../constants/styleConfig";

export const OrdersScreenContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-areas: 
    "first second third";
  gap: 20px;
  padding: 20px;

  @media (max-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 
      "first second"
      "first third";
  }

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "first"
      "second"
      "third";
  }
`;

export const FirstColumn = styled.div`
  grid-area: first;
`;

export const SecondColumn = styled.div`
  grid-area: second;
`;

export const ThirdColumn = styled.div`
  grid-area: third;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${marketplaceColors.gray6};
  padding: 1rem 0;
`;

export const Placeholder = styled.p`
  font-size: 16px;
  color: ${marketplaceColors.gray6};
  padding: 1rem 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoIcon = styled(IoMdInformationCircleOutline)`
  width: 25px;
  height: 25px;
  margin-left: 10px;
  cursor: help;
`;
