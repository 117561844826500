import { IoAdd, IoRemove } from "react-icons/io5";
import styled from "styled-components";
import { marketplaceColors } from "../constants/styleConfig";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${marketplaceColors.gray1};
  border-radius: 4px;
`;

const CounterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
`;

const CounterText = styled.div`
  width: 1.875rem;
  margin: 0 0.25rem;
  text-align: center;
`;

interface Props {
  value: number;
  onIncrement: () => void;
  onDecrement: () => void;
};

const Counter = ({
  value,
  onIncrement,
  onDecrement,
}: Props) => {
  return (
    <Container>
      <CounterButton onClick={onDecrement}>
        <IoRemove />
      </CounterButton>
      <CounterText>{value}</CounterText>
      <CounterButton onClick={onIncrement}>
        <IoAdd />
      </CounterButton>
    </Container>
  );
};

export { Counter };
