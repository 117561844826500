import styled from "styled-components";
import { animations, breakpoints, layers, marketplaceColors } from "../constants/styleConfig";

interface IAdaptiveModal {
  $isActive: boolean;
  $isDirectionColumn?: boolean;
}

const AdaptiveModal = styled.div<IAdaptiveModal>`
  position: fixed;
  left: ${({ $isActive }) => $isActive ? '0%' : '-100%'};
  top: 0;
  bottom: 0;
  z-index: ${({ $isActive }) => $isActive ? layers.modal : layers.header};
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 300px;
  max-height: 100%;
  overflow-y: auto;
  font-size: 1.25rem;
  color: ${marketplaceColors.gray6};
  background-color: ${marketplaceColors.white};
  border-radius: 0 8px 8px 0;
  transition: left ${animations.timeDefault};
  padding: 4rem 1rem 0;

  @media (min-width: ${breakpoints.md}) {
    position: static;
    z-index: ${layers.staticLayout};
    flex-direction: row;
    gap: 2rem;
    height: auto;
    overflow-y: unset;
    padding: 0;
    font-size: 0.875rem;
    border-top: none;
    flex-direction: ${({ $isDirectionColumn }) => $isDirectionColumn ? 'column' : 'row' };
  }
`;

export default AdaptiveModal;
