import { IoClose, IoAdd, IoRemove } from "react-icons/io5";
import {
  CartRow,
  Counter,
  CounterWrapper,
  Country,
  DeleteButtonWrapper,
  Image,
  Name,
  Price,
  ImageRow,
  TitleRow,
  PriceRow,
  CounterRow,
  DeleteRow,
  TotalPrice
} from "./styles";
import { CROSS_SYMBOL, CURRENCY_SYMBOL, PATHS } from "../../constants/appConfig";
import ButtonWrapper from "../ButtonWrapper";

interface ICartSectionProps {
  id: string,
  imageSrc: string,
  brand: string,
  name: string,
  country: string,
  price: number,
  count: number,
  deleteProduct: () => void,
  incrementProduct: () => void,
  decrementProduct: () => void,
};

const CartSection = ({ 
  id,
  imageSrc,
  brand,
  name,
  country,
  price,
  count,
  deleteProduct,
  incrementProduct,
  decrementProduct,
}: ICartSectionProps) => { 
  return (
    <CartRow>
      <ImageRow to={PATHS.product.replace(':id', id)}>
        <Image src={imageSrc} />
      </ImageRow>
      <TitleRow to={PATHS.product.replace(':id', id)}>
        <Name title={name}>{name}</Name>
        <Country>{brand}, {country}</Country>
      </TitleRow>
      <PriceRow>
        <TotalPrice>
          {price * count} {CURRENCY_SYMBOL}
        </TotalPrice>
        <Price>{price} {CURRENCY_SYMBOL} {CROSS_SYMBOL} {count}</Price>
      </PriceRow>
      <CounterRow>
        <CounterWrapper>
          <ButtonWrapper onClick={() => decrementProduct()}>
            <IoRemove />
          </ButtonWrapper>
          <Counter>{count}</Counter>
          <ButtonWrapper onClick={() => incrementProduct()}>
            <IoAdd />
          </ButtonWrapper>
        </CounterWrapper>
      </CounterRow>
      <DeleteRow>
        <DeleteButtonWrapper onClick={() => deleteProduct()}>
          <IoClose />
        </DeleteButtonWrapper>
      </DeleteRow>
    </CartRow>
  );
};

export { CartSection };
