import styled from "styled-components";
import { InputRadio, IInputRadioProps } from "./InputRadio";
import { breakpoints } from "../../constants/styleConfig";

interface IRadioGroupProps {
  id: string;
  label: string;
  radioList: IInputRadioProps[];
  onClick: (id: any) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 1rem;
`;

const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 700;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 0.75rem;
  }
`;

const InputRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  @media (min-width: ${breakpoints.sm}) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: column;
  }
`;

const RadioGroup = ({
  id,
  label,
  radioList,
  onClick,
}: IRadioGroupProps) => {
  return (
    <Container>
      <Label>{label}</Label>
      <InputRadioWrapper>
        {radioList.map((radio) => (
          <InputRadio
            key={radio.id}
            id={radio.id}
            name={id}
            label={radio.label}
            isChecked={radio.isChecked}
            onClick={() => onClick(radio.id)}
          />
        ))}
      </InputRadioWrapper>
    </Container>
  );
};

export { RadioGroup };
