import styled from "styled-components";
import { animations, marketplaceColors, breakpoints } from "../../constants/styleConfig";

export const ArticleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: fit-content;
    padding: 0.5rem;
    border: 1px solid ${marketplaceColors.gray1};
    transition: all ${animations.timeFast};
    cursor: pointer;

  &:hover {
    color: ${marketplaceColors.primaryDark};
    border-color: ${marketplaceColors.primaryDark};
    box-shadow: 0 0 12px 0 ${marketplaceColors.primaryGhostDark};
  }
  
  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    text-align: start;
  }
`;

export const Image = styled.img`
    width: 80px;
    height: auto;
    transition: width ${animations.timeDefault} ease;

  @media (min-width: ${breakpoints.xxs}) {
    width: 100px;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 120px;
  }
`;

export const Content = styled.div`
    flex: 1;
`;

export const Title = styled.h2`
    font-size: 16px;
    margin-bottom: 10px;
    transition: font-size ${animations.timeDefault} ease;

  @media (min-width: ${breakpoints.xxs}) {
    font-size: 20px;
  }
`;

export const Category = styled.p`
    font-size: 14px;
    color: #666;
`;
