import { CURRENCY_SYMBOL } from "../constants/appConfig";
import { ICartContext } from "../types/orders";

export const formatPrice = (priceSubUnits: number) => {
  const price = Math.round(priceSubUnits) / 100;
  return `${price} ${CURRENCY_SYMBOL}`;
};

export const getTotalPrice = (price: number, count: number) => {
  return (price * count);
};

export const getTotalCartPrice = (items: ICartContext[]) => {
  return items.reduce((accum, currentItem) => {
    return accum + getTotalPrice(currentItem.price, currentItem.count);
  }, 0);
};
