import { ChangeEvent, useState } from 'react';

import { AppButton, FlexContainer, InputField, Spinner, TextArea, Toast } from '../index';
import { Wrapper, Title, ButtonWrapper, MessageContainer, FormMessage, MessageIcon } from './styles';
import { OfferFormDataType } from '../../types/content';
import { sendEmail } from '../../util/api';
import { marketplaceColors } from '../../constants/styleConfig';
import { IoClose } from 'react-icons/io5';
import { validateData, ValidationErrors } from '../../util/validation';
import { IoMdCheckmark } from 'react-icons/io';

interface IProps {
  title: string;
}

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
}

const GetInTouch = ({ title }: IProps) => {
  const [offerFormData, setOfferFormData] = useState<OfferFormDataType>(EMPTY_FORM_DATA);
  const [errorStack, setErrorStack] = useState<ValidationErrors>({});
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = event.target;

    setOfferFormData({ ...offerFormData, [name]: value });
  };

  const onSubmit = () => {
    setIsLoading(true);
    sendEmail(offerFormData).then(res => {
      if (res?.status === 200) {
        setIsSuccess(true);
        setOfferFormData(EMPTY_FORM_DATA);
        setErrorStack({});
      }
    })
    .finally(() => {
      setIsLoading(false);
    })
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <FlexContainer gap='1rem'>
        <InputField
          radius='0.375rem'
          label="Ім'я"
          name='firstName'
          status={!!errorStack['firstName'] && 'error'}
          value={offerFormData.firstName}
          onChange={handleFormChange}
        />
        <InputField
          radius='0.375rem'
          label='Прізвище'
          name='lastName'
          status={!!errorStack['lastName'] && 'error'}
          value={offerFormData.lastName}
          onChange={handleFormChange}
        />
      </FlexContainer>
      <InputField
        radius='0.375rem'
        label='E-mail'
        placeholder='example@email.com'
        name='email'
        status={!!errorStack['email'] && 'error'}
        value={offerFormData.email}
        onChange={handleFormChange}
      />
      <TextArea
        label='Повідомлення'
        name='message'
        value={offerFormData.message}
        status={!!errorStack['message'] && 'error'}
        limit={200}
        onChange={handleFormChange}
      />
      <FlexContainer justify='space-between'>
        <MessageContainer>
          {isSuccess && (
            <FormMessage $type='success'>
              <MessageIcon>
                <IoMdCheckmark />
              </MessageIcon>
              Відправлено
            </FormMessage>
          )}
          {Object.keys(errorStack).filter((key) => errorStack[key]?.[0]).map(key => (
            <FormMessage key={`error_${key}`} $type='error'>
              <MessageIcon>
                <IoClose />
              </MessageIcon>
              {errorStack[key]![0]}
            </FormMessage>
          ))}
        </MessageContainer>
        <ButtonWrapper>
          <AppButton onClick={() => {
            if (isLoading) return;
            setIsSuccess(false);
            const { errors, isValid } = validateData(offerFormData, {
              firstName: {
                required: { message: 'Вкажіть імʼя' },
                inRange: { max: 20, message: 'Імʼя не повинно бути більше 20 символів' },
              },
              lastName: {
                required: { message: 'Вкажіть прізвище' },
                inRange: { max: 20, message: 'Прізвище не повинно бути більше 20 символів' },
              },
              email: {
                required: { message: 'Вкажіть E-mail' },
                isEmail: { message: 'Невірно вказаний E-mail' },
              },
              message: {
                required: { message: 'Введіть повідомлення' },
              }
            });

            if (!isValid) {
              setErrorStack(errors);
              return;
            }
            onSubmit();
          }}>
            {isLoading ? <Spinner $fontSize='1.375rem' $color={marketplaceColors.white} /> : 'Відправити'}
          </AppButton>
        </ButtonWrapper>
      </FlexContainer>
    </Wrapper>
  )
};

export default GetInTouch;
