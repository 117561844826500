import styled from "styled-components";
import { PageWrapper } from "../../components";
import {breakpoints, marketplaceColors} from "../../constants/styleConfig";

export const SearchScreenWrapper = styled(PageWrapper)`
  padding-top: 0;

  @media (min-width: ${breakpoints.md}) {
    display: grid;
    grid-template-columns: 1fr minmax(0, 5fr);
    gap: 1rem;
    align-content: baseline;
    padding-top: 1rem;
  }
`;

export const GridContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ProductGrid = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-rows: max-content;
  gap: 0.5rem;
  align-self: flex-start;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    place-items: center;
    margin: 0 auto 2rem;
  }
`;

export const NoResultsMessage = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${marketplaceColors.gray2};
  font-size: 1.5rem;
  user-select: none;
`;

export const TagsWrapper = styled.div`
  --scrollbar-color: #bbb;
  --scrollbar-width: 5px;
  position: relative;
  margin-bottom: 1rem;

  &::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 10px;
    width: 1rem;
  }

  &::before {
    left: 0;
    background: linear-gradient(to left, #FFFFFF00 0%, #FFFFFFFF 100%);
  }

  &::after {
    right: 0;
    background: linear-gradient(to right, #FFFFFF00 0%, #FFFFFFFF 100%);
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 1rem 1rem;
  overflow-x: scroll;
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-items: center;
  padding: 1rem 0 0.5rem;

  @media (min-width: ${breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    gap: 1rem;
    padding: 0;
  }
`;

export const PerPage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10rem;
  background-color: #ffffff8e;
`;
