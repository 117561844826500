import { useState, useEffect } from "react";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { searchProducts } from "../../util/api";
import { IProductSearchResult } from "../../types/products";
import ProductCard from "../../components/ProductCard";
import styled from "styled-components";
import { breakpoints } from "../../constants/styleConfig";
import Pagination from "../../components/Pagination";
import { Title } from "../../components/Title";

const PAGE_SIZE = 40;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
  align-self: flex-start;
  
  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto 1rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BookmarksScreen = () => {
  const [bookmarks, setBookmarks] = useState<IProductSearchResult>();
  const [pagination, setPagination] = useState<{
    totalPages: number;
    page: number;
  }>({
    totalPages: 1,
    page: 1,
  });

  useEffect(() => {
    const markedProducts = localStorage.getItem('bookmarks')?.split(',') || [];
    const totalPages = Math.ceil(markedProducts.length / PAGE_SIZE);
    setPagination({
      totalPages: totalPages,
      page: 1,
    })
  }, []);

  useEffect(() => {
    const markedProducts = localStorage.getItem('bookmarks')?.split(',') || [];
    if (markedProducts) {
      searchProducts({
        page: pagination.page,
        size: PAGE_SIZE,
        idList: markedProducts,
      }).then(res => {
        res && setBookmarks(res);
      });
    }
  }, [pagination]);

  return (
    <PageWrapper>
      <Title>Закладки</Title>
      <GridContainer>
        <ProductGrid>
          {bookmarks?.results.map(item => (
            <ProductCard
              key={item.id}
              productData={item}
            />
          ))}
        </ProductGrid>
        <PaginationContainer>
          <Pagination
            length={pagination.totalPages}
            selectedPage={pagination.page}
            onSelect={(page) => {
              setPagination(prev => ({
                ...prev,
                page: page,
              }))
            }}
          />
        </PaginationContainer>
      </GridContainer>
    </PageWrapper>
  );
};

export { BookmarksScreen };
