import { FC } from 'react';
import { Link } from "react-router-dom";

import { Wrapper } from './styles';

interface IProps {
  label: string;
  path: string;
}

const CategoryLabelButton: FC<IProps> = ({ label, path }) => {
  return (
    <Link to={path}>
      <Wrapper>{label}</Wrapper>
    </Link>
  );
};

export default CategoryLabelButton;
