import DOMPurify from "dompurify";
import { ChangeEvent } from "react";

export const PHONE_REGEX = /^\d{12}/g;
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const PRICE_REGEX = /^-?\d*\.?(\d{0,2})?/g;
export const ALLOWED_TAGS = ['div', 'span', 'p', 'strong', 'b', 'i', 'ul', 'li', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
export const ARTICLE_ALLOWED_TAGS = [...ALLOWED_TAGS, 'img'];

export const sanitizeHTML = (dirtyHTML: string, type?: 'article'): string => {
  const tags = type === 'article' ? ARTICLE_ALLOWED_TAGS : ALLOWED_TAGS;

  return DOMPurify.sanitize(dirtyHTML, { ALLOWED_TAGS: tags });
};

export const prettifyHTML = (HTML: string): string => {
  const tagged = HTML.replaceAll(/&lt;\s*/g, '<').replaceAll(/\s*&gt;/g, '>');
  // Add newlines
  const pretty = tagged
    .replace(/\n/g, '')
    .replace(/(<br\s*\/?>|<h\d>|<ul>)/g, '\n$1')
    .replace(/(<br\s*\/?>|<\/h\d>|<ul>|<\/ul>|<\/li>)/g, '$1\n');
  return pretty;
}

export const isPhoneValid = (phone: string): boolean => {
  const regex = new RegExp(PHONE_REGEX);
  return regex.test(phone);
};

export const isEmailValid = (email: string) => {
  const regex = new RegExp(EMAIL_REGEX);
  return regex.test(email);
};

export const formatPhone = (value: string) => {
  const rawValue = value.replace(/\D/g, '');
  if (!rawValue) return '';
  const countryCode = rawValue.slice(0, 3);
  const cityCode = rawValue.slice(3, 5);
  const phone = rawValue.slice(5, 12);
  const formattedValue = `${
    countryCode && `+${countryCode}`
  }${
    cityCode && ` (${cityCode}`
  }${
    phone && `) ${phone.slice(0, 3)} ${phone.slice(3, 5)} ${phone.slice(5, 7)}`
  }`;
  return formattedValue.trim();
};

export const reformatPhone = (event: ChangeEvent<HTMLInputElement>): string => {
  return event.target.value.replace(/\D/g, '');
};

export const convertPaymentToFloat = (value: string): number => {
  const floatMatch = value.match(PRICE_REGEX);
  const floatValue = floatMatch ? parseFloat(floatMatch[0]) : 0;
  if (isNaN(floatValue)) return 0;
  return floatValue;
};

export const isPaymentValid = (payment: string): boolean => {
  const floatPayment = convertPaymentToFloat(payment);
  return !isNaN(floatPayment) && floatPayment !== 0;
};

export const isDiscountValid = (discount: string): boolean => {
  const floatDiscount = convertPaymentToFloat(discount);
  return !isNaN(floatDiscount) && floatDiscount > 0 && floatDiscount <= 10;
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const uniqueArray = (array: any[]): any[] => {
  return array.filter((value, index, array) => array.indexOf(value) === index);
};
