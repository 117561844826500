import { Suspense } from "react";
import styled from "styled-components";
import { IProductExtended } from "../../types/products";
import {
  Await,
  useLoaderData
} from "react-router-dom";

import { CatalogItemInfo, CategoryLabelButton, FlexContainer, Spinner, PageWrapper } from "../../components";
import { CATEGORY_LABEL_BUTTONS } from "../../constants/appConfig";
import { marketplaceColors } from "../../constants/styleConfig";

export const ProductScreenWrapper = styled(PageWrapper)``;

export const SpinnerContainer = styled(PageWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScrollingWrapper = styled.div`
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
  overflow-y: hidden;
  white-space: nowrap;

  ::-webkit-scrollbar-thumb {
    background-color: ${marketplaceColors.gray7};
  }
`;

const ProductScreen = () => {
  const productData = useLoaderData() as { data: IProductExtended };

  return (
    <Suspense fallback={(
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )}>
      <Await resolve={productData.data}>
        {(data) => (
          <ProductScreenWrapper>
            <CatalogItemInfo
              productData={data}
            />
            {/* {productData.related?.length && (
              <RelatedProducts products={productData.related} />
            )} */}
          </ProductScreenWrapper>
        )}
      </Await>
      <ScrollingWrapper>
        <FlexContainer gap='0.5rem' width='max-content'>
          {CATEGORY_LABEL_BUTTONS.map((category) => (
            <CategoryLabelButton key={`${category.label}_${category.path}`} label={category.label} path={category.path} />
          ))}
        </FlexContainer>
      </ScrollingWrapper>
    </Suspense>
  );
}

export { ProductScreen };
