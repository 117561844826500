import styled from "styled-components";
import { colors, marketplaceColors } from "../constants/styleConfig";

const Toast = styled.div<{ type: 'warning' | 'error' | 'info' | 'success'; }>`
  display: inline-block;
  width: auto;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 5px;
  
  ${(props) => {
    const { textWarning, highlightRed, highlightBlue } = colors;
    
    switch(props.type) {
      case 'warning':
        return (
          `color: ${textWarning};
          border: 1px solid ${textWarning};`
        );
      case 'error':
        return (
          `color: ${highlightRed};
          border: 1px solid ${highlightRed};`
        );
      case 'info':
        return (
          `color: ${highlightBlue};
          border: 1px solid ${highlightBlue};`
        );
      case 'success':
        return (
          `color: ${marketplaceColors.success};
          border: 1px solid ${marketplaceColors.success};`
        )
    }
  }}

  &:before {
    content: '${({ prefix }) => prefix || ''}';
    font-weight: 600;
  }
`;

export default Toast;
