import styled from "styled-components";
import {
  IoTimeOutline,
  IoLocationOutline,
  IoMailOutline,
  IoCallOutline,
} from "react-icons/io5";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { marketplaceColors } from "../../constants/styleConfig";
import { Title } from "../../components/Title";
import { Link } from "react-router-dom";

const ScreenWrapper = styled(PageWrapper)``;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 4rem 2rem;
`;

const ContactBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
`;

const ContactIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  font-size: 2rem;
  color: ${marketplaceColors.primaryDark};
`;

const ContactInfo = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: ${marketplaceColors.gray9};
`;

const ContactScreen = () => {
  return (
    <ScreenWrapper>
      <Title>Контакти</Title>
      <ContactContainer>
        <ContactBlock>
          <ContactIcon>
            <IoMailOutline />
          </ContactIcon>
          <Link to={'mailto:fitness.post.llc@gmail.com'} >
            <ContactInfo>fitness.post.llc@gmail.com</ContactInfo>
          </Link>
        </ContactBlock>
        <ContactBlock>
          <ContactIcon>
            <IoCallOutline />
          </ContactIcon>
          <Link to={'tel:+380995146965'} >
            <ContactInfo>(099) 514-69-65</ContactInfo>
          </Link>
        </ContactBlock>
        <ContactBlock>
          <ContactIcon>
            <IoLocationOutline />
          </ContactIcon>
          <Link
            to={'https://www.google.com/maps/place/Fitness+Post/@50.3904763,30.3572922,17z/data=!3m1!4b1!4m6!3m5!1s0x40d4cb5c91b1b591:0x7ca1e78360c87084!8m2!3d50.3904729!4d30.3598671!16s%2Fg%2F11vh_c46m3?authuser=0&entry=ttu'}
            target='_blank'
          >
            <ContactInfo>м. Вишневе, вул. Київська, 2л, ТРЦ "Cherry Mall", 1 поверх</ContactInfo>
          </Link>
        </ContactBlock>
        <ContactBlock>
          <ContactIcon>
            <IoTimeOutline />
          </ContactIcon>
          <ContactInfo>10:30 - 20:00</ContactInfo>
        </ContactBlock>
      </ContactContainer>
    </ScreenWrapper>
  );
};

export { ContactScreen };
