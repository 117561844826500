import styled, { css } from "styled-components";

import { animations, breakpoints, marketplaceColors } from "../../constants/styleConfig";

const styledLink = css`
  background-color: ${marketplaceColors.gray0};
  color: ${marketplaceColors.primary};
  padding: 5px;
  border-radius: 7px;
  transition: background-color ${animations.timeDefault} ease;

  &:hover {
    background-color: ${marketplaceColors.gray1};
  }
`;

export const ArticleContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
    
    h3 {
        margin-top: 22px;
    }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: flex-direction ${animations.timeDefault} ease;

  @media only screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const HeaderImage = styled.img`
  width: 300px;
  height: auto;
  margin-right: 20px;
  transition: width ${animations.timeDefault} ease,
    margin-bottom ${animations.timeDefault} ease,
    transform ${animations.timeDefault} ease;


  @media only screen and (max-width: ${breakpoints.md}) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    width: 250px;
  }

  @media only screen and (max-width: ${breakpoints.xxs}) {
    width: 200px;
  }
`;

export const HeaderTitle = styled.p`
  font-size: 64px;
  font-weight: bold;
  color: #333;
  margin: 0;
  transition: font-size ${animations.timeDefault} ease;
  
  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 48px;
  }

  @media only screen and (max-width: ${breakpoints.xxs}) {
    font-size: 36px;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: #666;
  line-height: 1.6;
  margin-top: 20px;
  transition: font-size ${animations.timeDefault} ease;
  
  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 18px;
  }

  a {
    ${styledLink}
  }
`;

export const ListContainer = styled.ul`
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: none;
`;

export const ListItem = styled.li`
  font-size: 20px;
  color: #666;
  margin-top: 10px;
  transition: font-size ${animations.timeDefault} ease;

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 18px;
  }
`;
