import { FC } from "react";
import styled from "styled-components";
import {animations, breakpoints, marketplaceColors} from "../constants/styleConfig";

interface ICategoryBlock {
    image: string,
    title: string,
}

const CategoryCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0.5rem 0.25rem 0.5rem;
  border: 1px solid ${marketplaceColors.gray1};
  transition: all ${animations.timeFast};
  cursor: pointer;

  &:hover {
    color: ${marketplaceColors.primaryDark};
    border-color: ${marketplaceColors.primaryDark};
    box-shadow: 0 0 12px 0 ${marketplaceColors.primaryGhostDark};
  }

  @media (min-width: ${breakpoints.sm}) {
    padding: 1rem 0.25rem 1.5rem;
  }
`;

const CategoryImage = styled.img`
  max-width: 100%;
  margin-bottom: 0.5rem;

  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 1rem;
  }
`;

const CategoryLabel = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
`;

const CategoryBlock: FC<ICategoryBlock> = ({ image, title }) => {
    return (
        <CategoryCard>
            <CategoryImage alt={title} src={image} />
            <CategoryLabel>{title}</CategoryLabel>
        </CategoryCard>
    );
};

export default CategoryBlock;
