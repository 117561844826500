import { useState, ChangeEvent } from "react";
import { ICheckbox } from "../../types/reducers";
import { Checkbox } from "./Checkbox";
import { InputField } from "../InputField/InputField";
import {
  GroupContainer,
  GroupLabel,
  CheckboxWrapper,
  GroupLabelWrapper,
  SearchInputWrapper,
  ResultNotFound,
  AngleIconWrapper,
} from "./styles";
import AngleIcon from "../AngleIcon";

interface IProps {
  id: string;
  label: string;
  checkboxList: ICheckbox[];
  selectedAmount?: number;
  onClickCheckbox?: (id: string) => void;
}

const CheckboxGroup = ({ id, label, checkboxList, onClickCheckbox = () => {} }: IProps) => {
  const [isCheckboxesOpen, setIsCheckboxesOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredCheckboxList = checkboxList.filter((checkboxItem) =>
    checkboxItem.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <GroupContainer>
      <GroupLabelWrapper onClick={() => setIsCheckboxesOpen((prev) => !prev)}>
        <GroupLabel>{label}</GroupLabel>
        <AngleIconWrapper>
          <AngleIcon $isActive={isCheckboxesOpen} />
        </AngleIconWrapper>
      </GroupLabelWrapper>
      <CheckboxWrapper $isCheckboxesOpen={isCheckboxesOpen}>
        <SearchInputWrapper>
          <InputField
            placeholder="Пошук..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </SearchInputWrapper>
        {filteredCheckboxList.length ? (
          filteredCheckboxList.map((checkboxItem) => (
            <Checkbox
              key={`${id}_${checkboxItem.id}`}
              isGroupOpen={true}
              label={checkboxItem.label}
              isChecked={checkboxItem.isChecked}
              onClick={() => onClickCheckbox(checkboxItem.id)}
            />
          ))
        ) : (
          <ResultNotFound>Результатів не знайдено</ResultNotFound>
        )}
      </CheckboxWrapper>
    </GroupContainer>
  );
};

export { CheckboxGroup };
