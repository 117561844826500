import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";

import { AppButton, FlexContainer, PageWrapper } from '../../components';
import { marketplaceColors } from '../../constants/styleConfig';

interface IProps {
  title: string;
  linkTo: string;
  buttonTitle: string;
}

const Title = styled.b`
  font-size: 2rem;
  color: ${marketplaceColors.gray6};
  text-align: center;
  margin: 1rem 0;
`;

const NotFoundScreen: FC<IProps> = ({ title, linkTo, buttonTitle }) => (
  <PageWrapper display='flex' alignItems='center'>
    <FlexContainer direction='column' justify='center' align='center' width='100%'>
      <Title>{title}</Title>
      <Link to={linkTo}><AppButton>{buttonTitle}</AppButton></Link>
    </FlexContainer>
  </PageWrapper>
);

export default NotFoundScreen;
