import { DELIVERY_STATUS } from "../../types/orders";

export const RENDER_KEYS: { [key: string]: string } = {
  'status': 'Статус',
  'createdAt': 'Створений',
  'deliveryType': 'Спосіб доставки',
  'firstName': 'Ім\'я',
  'lastName': 'Прізвище',
  'orderProducts': 'Замовленні продукти',
  'totalPrice': 'Загальна вартість',
  'phoneNumber': 'Номер телефону',
  'city': 'Місто',
  'warehouse': 'Відділення Нової пошти',
};

export const RENDER_STATUSES: { [key in DELIVERY_STATUS]: string } = {
  [DELIVERY_STATUS.CREATED]: 'Створено',
  [DELIVERY_STATUS.CONFIRMED]: 'Підтверджено',
  [DELIVERY_STATUS.SENT]: 'Відправлено',
  [DELIVERY_STATUS.DELIVERED]: 'Доставлено',
  [DELIVERY_STATUS.DONE]: 'Виконано',
  [DELIVERY_STATUS.CANCELLED]: 'Скасовано',
};

export const KEYS = [
  'status',
  'createdAt',
  'deliveryType',
  'firstName',
  'lastName',
  'orderProducts',
  'totalPrice',
  'phoneNumber',
  'city',
  'warehouse',
];

export interface IAdminTableProps<T> {
  data: T[];
}

export interface IOrderProduct {
  id: string;
  name: string;
  price: number;
  count: number;
}

export interface IStyledCircle {
  type: DELIVERY_STATUS;
}
