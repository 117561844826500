import styled from "styled-components";
import { FiMenu } from "react-icons/fi";
import { IoArrowBackOutline, IoCart, IoClose } from "react-icons/io5";
import { animations, breakpoints, colors, layers, marketplaceColors } from "../../constants/styleConfig";
import { Link } from "react-router-dom";
import ButtonWrapper from "../ButtonWrapper";

interface INavbar {
  $isActive: boolean;
  $isClosedOnBreakpoint?: boolean;
};

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: ${layers.header};
`;

export const TopbarContainer = styled.div`
  padding: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    padding: 1.5rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 1.5rem 3.5rem;
  }
`;

export const Topbar = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  gap: 1rem 0.5rem;
  justify-items: center;
  align-items: center;
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
`;

export const LogoWrapper = styled(Link)`
  grid-column: 2 / 3;
  justify-self: center;
  width: 150px;
  height: 42px;

  @media (min-width: ${breakpoints.md}) {
    grid-column: 1 / 2;
    justify-self: flex-start;
  }
  
  & svg {
    transition: filter ${animations.timeDefault} ease;
  }
`;

export const SearchbarContainer = styled.div`
  grid-row: 2;
  grid-column: 1 / 4;
  max-width: 32rem;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    grid-row: 1;
    grid-column: 2 / 3;
  }
`;

export const NavbarContainer = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    border-top: 1px solid ${marketplaceColors.gray1};
    border-bottom: 1px solid ${marketplaceColors.gray1};
  }
`;

export const NavbarContentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    padding: 0 1.5rem;
  }
`;

export const NavbarLink = styled.div`
  width: fit-content;
  padding: 0.375rem 1rem;
  overflow: hidden;
  cursor: pointer;
  transition: max-height ${animations.timeDefault}, padding ${animations.timeDefault}, opacity ${animations.timeDefault};

  &:hover {
    color: ${marketplaceColors.primaryDark};
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem 0;
  }
`;

export const NavbarMultilink = styled.div<INavbar>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.375rem 1rem;
  color: ${({ $isActive }) => $isActive ? marketplaceColors.primaryDark : 'inherit'};
  cursor: pointer;

  &:hover {
    color: ${marketplaceColors.primaryDark};
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem 0;
    color: inherit;

    &:hover + div {
      display: flex;
    }
  }
`;

export const NavbarDropdownWrapper = styled.div`
  //position: relative;
`;

export const TreeContainer = styled.div`

`;

export const NavbarDropdownContainer = styled.div<INavbar>`
  width: auto;
  padding-left: 0.75rem;
  font-size: 0.875rem;
  background-color: ${marketplaceColors.white};

  & div {
    max-height: ${({ $isActive }) => $isActive ? '2.5rem' : '0'};
    padding-top: ${({ $isActive }) => $isActive ? '0.375rem' : '0'};
    padding-bottom: ${({ $isActive }) => $isActive ? '0.375rem' : '0'};
    opacity: ${({ $isActive }) => $isActive ? '1' : '0'};
  }

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(-1px);
    display: none;
    //grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0 1rem;
    width: 100%;
    max-height: 300px;
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    border: 1px solid ${marketplaceColors.gray1};
    box-shadow: 0px 20px 20px 0px rgba(190,190,190,0.35);

    &:hover {
      display: flex;
    }

    & div {
      max-height: unset;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      opacity: 1;
    }

    a:hover {
      color: ${marketplaceColors.primaryDark};
    }
  }
`;

export const NavbarBurger = styled(FiMenu)<INavbar>`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  z-index: 10;
  font-size: 2rem;
  color: ${({ $isActive }) => $isActive ? marketplaceColors.primary : marketplaceColors.gray8};
  transition: color ${animations.timeDefault};
  cursor: pointer;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const NavbarCartIcon = styled(IoCart)<INavbar>`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: ${({ $isActive }) => $isActive ? layers.modal : layers.header};
  font-size: 2rem;
  color: ${({ $isActive }) => $isActive ? marketplaceColors.primary : marketplaceColors.gray8};
  transition: color ${animations.timeDefault};

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const NavbarBackArrow = styled(IoArrowBackOutline)`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  font-size: 2rem;
  cursor: pointer;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const CartLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CartWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    margin: 0;
  }
`;

export const IoCartWrapper = styled(IoCart)`
  font-size: 2.25rem;
  color: ${marketplaceColors.gray8};
  cursor: pointer;
  user-select: none;
`;

export const CartIconWrapper = styled.div`
  grid-column: 3 / 4;
  position: relative;
  justify-self: flex-end;
  width: 2.25rem;
  height: 2.25rem;

  
`;

export const CartCount = styled.div`
  position: absolute;
  height: 18px;
  width: 18px;
  background: ${marketplaceColors.danger};
  border-radius: 9px;
  top: -3px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  font-size: 12px;
  user-select: none;
`;

export const CartModal = styled.div<INavbar>`
  position: fixed;
  top: 0;
  right: ${({ $isActive }) => $isActive ? '0%' : '-100%'};
  bottom: 0;
  z-index: ${layers.modal};
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 300px;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid ${marketplaceColors.gray1};
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: right ${animations.timeDefault};

  @media (min-width: ${breakpoints.md}) {
    
  }
`;

export const CartModalHead = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
`;

export const CartItemsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-right: -0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  overflow-y: scroll;
`;

export const CloseIcon = styled(IoClose)`
  font-size: 2rem;
  cursor: pointer;
`;

export const CartModalTitle = styled.div`
  font-size: 1.25rem;
  text-align: center;
`;

export const CartEmptyItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 150px;
  font-size: 2.5rem;
  color: ${marketplaceColors.gray2};
`;

export const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.25rem;
  border: 1px solid ${marketplaceColors.gray0};
  border-radius: 8px;
`;

export const ModalItemTop = styled(Link)`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const ModalItemBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
`;

export const ModalItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.75rem;
`;

export const ModalItemPriceOld = styled.div`
  color: ${marketplaceColors.gray2};
  text-decoration: line-through;
`;

export const ModalItemImage = styled.img`
  width: 4.5rem;
  height: 4.5rem;
`;

export const ModalItemText = styled.div`
  margin: 0.25rem 0;
  font-size: 0.875rem;
  text-align: left;
  color: ${marketplaceColors.gray6};
`;

export const CartBottom = styled.div`
  
`;

export const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  border-top: 1px solid ${marketplaceColors.gray0};
`;

export const DeleteButtonWrapper = styled(ButtonWrapper)`
  color: ${marketplaceColors.dangerLight};
  border: 1px solid ${marketplaceColors.dangerLight};
`;
