import styled from "styled-components";
import { FaBookmark } from "react-icons/fa";
import { animations, breakpoints, colors, marketplaceColors } from "../../constants/styleConfig";
import { Link } from "react-router-dom";

interface IInfoTableItemProps {
  length: number;
}

interface IVariant {
  $isActive: boolean;
  $isFullMatch: boolean;
  $isDisabled: boolean;
};

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const OrderButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media (min-width: ${breakpoints.xs}) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints.md}) {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

export const ProductMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${marketplaceColors.gray1};
  transition: margin ${animations.timeDefault}, padding ${animations.timeDefault};

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding-bottom: 3rem;
  }
`;

export const ProductImage = styled.img`
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  transition: width ${animations.timeDefault}, height ${animations.timeDefault};

  @media (min-width: ${breakpoints.sm}) {
    width: 400px;
    height: 400px;
  }
`;

export const ProductInfo = styled.div`
  position: relative;
  width: 100%;
  padding: 0.5rem;
  transition: padding ${animations.timeDefault};

  @media (min-width: ${breakpoints.md}) {
    padding: 0.5rem 1.5rem;
  }
`;

export const ProductName = styled.h1`
  margin-bottom: 0.75rem;
  padding-right: 2rem;
  font-size: 1.375rem;
  font-weight: 600;
  color: ${marketplaceColors.gray9};
  transition: font-size ${animations.timeDefault};

  @media (min-width: ${breakpoints.sm}) {
    padding-right: 3rem;
    font-size: 2.25rem;
  }
`;

interface IBookmarkIcon {
  isActive: boolean;
}

export const BookmarkIcon = styled(FaBookmark)<IBookmarkIcon>`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 2rem;
  color: ${({ isActive }) => isActive ? marketplaceColors.danger : marketplaceColors.gray4};
  cursor: pointer;

  &:hover {
    color: ${({ isActive }) => isActive ? marketplaceColors.dangerDark : marketplaceColors.dangerLight};
  }

  @media (min-width: ${breakpoints.sm}) {
    right: 1.5rem;
  }
`;

export const ProductBrand = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${marketplaceColors.gray7};

  b {
    color: ${marketplaceColors.gray9};
  }
`;

export const ProductPriceContainer = styled.div`
  padding: 1.25rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${marketplaceColors.gray1};
`;

interface IProductPrice {
  isOld?: boolean;
}

export const ProductPrice = styled.span<IProductPrice>`
  margin-right: 0.75rem;
  font-size: ${({ isOld }) => isOld ? '1.25 rem' : '1.5rem'};
  font-weight: ${({ isOld }) => isOld ? 300 : 500};
  color: ${({ isOld }) => isOld ? marketplaceColors.gray5 : marketplaceColors.gray9};
  text-decoration: ${({ isOld }) => isOld ? 'line-through' : 'none'};
`;

interface IProductAvailability {
  isAvailable?: boolean;
}

export const ProductAvailability = styled.div<IProductAvailability>`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ isAvailable }) => isAvailable ? marketplaceColors.success : marketplaceColors.gray4};
`;

export const ProductVariantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

export const VariantRowContainer = styled.div`
  flex-grow: 1;
  font-weight: 700;

  @media (min-width: ${breakpoints.sm}) {
    max-width: 33%;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: unset;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 0;
    max-width: 33%;
  }
`;

export const VariantName = styled.div`
  margin-bottom: 0.5rem;
`;

export const VariantRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 0.75rem;
`;

export const VariantItem = styled(Link)<IVariant>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 2.5rem;
  padding: 0.25rem 0.5rem;
  color: ${({ $isActive, $isFullMatch, $isDisabled }) => {
    if ($isActive) return marketplaceColors.primaryDark;
    if ($isDisabled) return marketplaceColors.gray4;
    return marketplaceColors.black;
  }};
  font-weight: ${({ $isActive }) => $isActive ? 700 : 400};
  text-align: center;
  background-color: ${({ $isActive, $isFullMatch, $isDisabled }) => {
    if ($isActive) return marketplaceColors.primaryGhost;
    return marketplaceColors.white;
  }};
  border: 1px solid ${({ $isActive, $isFullMatch, $isDisabled }) => {
    if ($isActive) return marketplaceColors.primaryDark;
    if ($isDisabled) return marketplaceColors.gray1;
    if ($isFullMatch) return marketplaceColors.primaryDark;
    return marketplaceColors.gray3;
  }};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ $isActive, $isFullMatch, $isDisabled }) => {
      if ($isActive) return marketplaceColors.primaryGhost;
      if ($isDisabled) return marketplaceColors.gray1;
      if ($isFullMatch) return marketplaceColors.primaryGhost;
      return marketplaceColors.gray0;
    }};
  }
`;

export const ProductParameterContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 0 1rem;
  justify-content: flex-start;
  font-size: 0.875rem;

  b {
    color: ${marketplaceColors.gray9};
  }
`;

export const ProductParameterValue = styled.div`
  font-weight: 400;
  color: ${marketplaceColors.gray5};
`;

export const InfoTitle = styled.h2`
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: start;
  white-space: nowrap;
  transition: font-size ${animations.timeDefault};

  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: 2rem;
  }
`;

export const DescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 3fr 2fr;
  }
`;

export const InfoDescription = styled.div`
  font-size: 0.875rem;
  margin-bottom: 10px;

  strong {
    margin-top: 20px;
  }

  h3 {
    margin: 1.5rem 0 0.5rem;
  }
`;

export const InfoTable = styled.div`
  font-size: 0.75rem;
  max-height: 90vh;
  overflow-y: scroll;
`;

export const InfoTableItem = styled.div<IInfoTableItemProps>`
  display: grid;
  grid-template-columns: ${(props) => `2fr repeat(${props.length - 1}, minmax(0, 1fr))`};
  gap: 0 0.25rem;
  padding: 4px 8px;
  border-bottom: 1px solid ${colors.borderLight};
`;
