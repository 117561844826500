import { useState } from "react";

import { addClientDiscount, getClientDiscount } from "../../util/api";
import { useAuthContext } from "../../contexts/AuthContext";
import { IClientDiscountData, IClientDiscountResponse } from "../../types/reducers";
import { InputField } from "../../components";
import Error from "../../components/Error/Error";
import { IS_ADMIN_PRODUCT_CATALOG_DISPLAY, PATHS } from "../../constants/appConfig";

import {
    PRICE_REGEX,
    convertPaymentToFloat,
    formatPhone,
    reformatPhone,
    isDiscountValid,
    isPaymentValid,
    isPhoneValid,
} from "../../util/formatData";

import {
    ClientDataForm,
    ClientDataItem,
    ClientDataLabel,
    ClientDataValue,
    FormButton,
    InfoContainer,
    ListContainer,
    ScreenWrapper,
    LinkWrapper,
    HeaderWrapper,
} from "./styles";

interface IError {
  message: string;
  code: number;
}

interface IClientDiscountErrors {
  searchError?: IError;
  updateError?: IError;
}

const ClientBaseScreen = () => {
  const auth = useAuthContext();
  const [phoneSearchValue, setPhoneSearchValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [paymentValue, setPaymentValue] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [searchResult, setSearchResult] = useState<IClientDiscountResponse>();
  const [lastActions, setLastActions] = useState<string[]>([]);
  const [errors, setErrors] = useState<IClientDiscountErrors>({});

  const fetchPhoneList = () => {
    if (!isPhoneValid(phoneSearchValue)) return;
    getClientDiscount(auth, phoneSearchValue).then(discountData => (
      discountData && setSearchResult(discountData)
    )).catch(e => {
      setErrors(prev => ({ ...prev, searchError: { message: e.message, code: e.code } }));
    });
  };
  
  const submitClientDiscountData = () => {
    const clientDiscountData: IClientDiscountData = {
      phone: phoneValue,
      payment: convertPaymentToFloat(paymentValue),
      fixedDiscount: convertPaymentToFloat(discountValue),
    };

    if (isPhoneValid(phoneValue) && (isPaymentValid(paymentValue) || isDiscountValid(discountValue))) {
      addClientDiscount(auth, clientDiscountData).then(() => {
        setLastActions(prev => [...prev, `${
          new Date().toLocaleTimeString()
        }: ${
          clientDiscountData.payment > 0 ? 'Додано' : 'Знято'
        } ${
          Math.abs(clientDiscountData.payment)
        } грн. ${
          clientDiscountData.payment > 0 ? 'на номер' : 'з номеру'
        } ${formatPhone(clientDiscountData.phone)}`]);
        setPaymentValue('');
      });
    }
  };

  const productCatalogHandler = () => {
    if (IS_ADMIN_PRODUCT_CATALOG_DISPLAY) {
      return PATHS.adminProductCatalog;
    }

    return '#';
  };
  
  return (
    <ScreenWrapper>
      <HeaderWrapper>
          <LinkWrapper to={PATHS.adminOrders}>
            <FormButton
                key='button3'
            >
              Замовлення
            </FormButton>
          </LinkWrapper>
          <LinkWrapper to={productCatalogHandler()}>
            <FormButton
              key='button4'
              isDisabled={true}
            >
              Список товарів
            </FormButton>
          </LinkWrapper>
        </HeaderWrapper>
      <ClientDataForm>
        <InputField
          label='Пошук за номером'
          placeholder='+380 (00) 000 00 00'
          value={formatPhone(phoneSearchValue)}
          onChange={(event) => {
            const value = reformatPhone(event);
            if (value.length <= 12) setPhoneSearchValue(value);
          }}
        />
        <FormButton
          key='button1'
          onClick={fetchPhoneList}
          isDisabled={!isPhoneValid(phoneSearchValue)}
        >
          Пошук
        </FormButton>
      </ClientDataForm>
      <Error
        message={errors.searchError?.message}
        code={errors.searchError?.code}
      />
      <ListContainer>
        <ClientDataItem>
          <ClientDataLabel>
            Номер телефону
          </ClientDataLabel>
          <ClientDataLabel>
            Знижка
          </ClientDataLabel>
        </ClientDataItem>
        {searchResult ? (
          <ClientDataItem>
            <ClientDataValue>
              {formatPhone(searchResult.phone)}
            </ClientDataValue>
            <ClientDataValue>
              {searchResult.discount}% {searchResult.isFixed ? '(фікс.)' : ''}
            </ClientDataValue>
          </ClientDataItem>
        ) : (
          <ClientDataItem>
            Не знайдено
          </ClientDataItem>
        )}
      </ListContainer>
      <ClientDataForm>
        <InputField
          label='Номер телефону'
          placeholder='+380 (00) 000 00 00'
          value={formatPhone(phoneValue)}
          onChange={(event) => {
            const value = reformatPhone(event);
            if (value.length <= 12) setPhoneValue(value);
          }}
        />
        <InputField
          label='Сума'
          placeholder='123'
          value={paymentValue}
          onChange={e => {
            const value = e.target.value;
            const floatMatch = value.match(PRICE_REGEX);
            const floatValue = floatMatch ? floatMatch[0] : '';
            setPaymentValue(floatValue);
          }}
        />
        <InputField
          label='Фіксована знижка'
          placeholder='1'
          value={discountValue}
          onChange={e => {
            const value = e.target.value;
            const floatMatch = value.match(PRICE_REGEX);
            const floatValue = floatMatch ? floatMatch[0] : '';
            const numberValue = parseFloat(floatValue);
            if (isNaN(numberValue)) setDiscountValue('');
            if (numberValue >= 0 && numberValue <= 10) {
              setDiscountValue(floatValue);
            }
          }}
        />
        <FormButton
          key='button2'
          onClick={submitClientDiscountData}
          isDisabled={!isPhoneValid(phoneValue) || (!isPaymentValid(paymentValue) && !isDiscountValid(discountValue))}
        >
          Додати
        </FormButton>
      </ClientDataForm>
      <InfoContainer>
        <ClientDataLabel>Останні дії</ClientDataLabel>
        {lastActions.map(action => (
          <ClientDataValue>{action}</ClientDataValue>
        ))}
      </InfoContainer>
    </ScreenWrapper>
  );
}

export { ClientBaseScreen };
