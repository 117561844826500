import styled, { keyframes } from 'styled-components';
import {animations} from "../../constants/styleConfig";

interface IPageWrapper {
  display?: 'flex';
  alignItems?: 'center',
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const PageWrapper = styled.div<IPageWrapper>`
  display: ${({ display }) => display || 'block'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  flex-grow: 1;
  width: 100%;
  max-width: 100rem;
  height: 100%;
  margin: 0 auto;
  padding: 1rem 0.5rem;
  animation: ${fadeIn} ${animations.timeDefault} ease-in-out;
`;
