import styled from "styled-components";
import { colors } from "../../constants/styleConfig";
import { IErrorProps } from "./Error";

export const ErrorContainer = styled.div<IErrorProps>`
  width: 100%;
  padding: 0.5rem 0.25rem;
  color: ${props => {
    return props.type === 'error' ? colors.textError : colors.darkYellow;
  }};
  background-color: ${props => {
    return props.type === 'error' ? colors.paleRed : colors.palestYellow;
  }};
  border: 1px solid ${props => {
    return props.type === 'error' ? colors.textError : colors.darkYellow;
  }};
  border-radius: 4px;
`;

export const ErrorCode = styled.span`
  font-weight: 700;
`;
