import { useState } from "react";
import { ErrorMessage, LoginForm, LoginScreenWrapper } from "./styles";
import { logIn } from "../../util/api";
import { useAuthContext, useAuthDispatch } from "../../contexts/AuthContext";
import { AUTH_ACTION_TYPE } from "../../types/reducers";
import { Navigate } from "react-router-dom";
import { AppButton, InputField, Spinner } from "../../components";
import { PATHS } from "../../constants/appConfig";

const LoginScreen = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const dispatchAuth = useAuthDispatch();

  const onLogin = () => {
    if (isLoading) return;
    setIsLoading(true);
    logIn(login, password).then(response => {
      if (response.code === 'AU-001') {
        setError(response.message);
      } else if (response) {
        setError('');
        dispatchAuth({
          type: AUTH_ACTION_TYPE.SET,
          username: login,
          token: response,
        })
      }
    }).finally(() => {
      setIsLoading(false);
    })
  };

  return (
    <LoginScreenWrapper>
      {auth.isAuthenticated ? (
        <Navigate to={PATHS.adminDiscounts} replace />
      ) : (
        <LoginForm>
          <InputField
            value={login}
            placeholder="Login"
            onChange={(e) => setLogin(e.target.value)}
          />
          <InputField
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <AppButton
            onClick={onLogin}
          >
            {isLoading ? <Spinner/> : 'Log in'}
          </AppButton>
        </LoginForm>
      )}
    </LoginScreenWrapper>
  );
}

export { LoginScreen };
