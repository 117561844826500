import { FC, ReactNode, useEffect, useState } from "react";
import {
  ActionFunctionArgs,
  createBrowserRouter,
  defer,
  json,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
  useParams,
} from "react-router-dom";
import { useAuthContext } from "./contexts/AuthContext";
import { PATHS, S3Link } from "./constants/appConfig";
import { getArticleById, getProductById } from "./util/api";
import {
  CartScreen,
  ClientBaseScreen,
  ContactScreen,
  ErrorScreen,
  LoginScreen,
  MainScreen,
  ProductScreen,
  SearchScreen,
  AdminProductCatalogScreen,
  ArticlesScreen,
  ArticleScreen,
  OrdersScreen,
  SuccessOrderScreen,
  AdminOrdersScreen,
  LoadingScreen,
  NotFoundScreen,
} from "./screens";
import { Footer, Header } from "./components";
import { IArticle, HeaderWithImageType } from "./types/articles";

interface IProtectedRouteProps {
  children: ReactNode;
}

interface TodoLoaderArgs extends ActionFunctionArgs {
  params: Params<ParamParseKey<typeof PATHS.product>>;
}

const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
  const auth = useAuthContext();

  return <>
    {auth.isAuthenticated ? children : <Navigate to={PATHS.login} replace />}
  </>;
};

declare type _PathParam<Path extends string> = Path extends `${infer L}/${infer R}` ? _PathParam<L> | _PathParam<R> : Path extends `:${infer Param}` ? Param extends `${infer Optional}?` ? Optional : Param : never;
declare type PathParam<Path extends string> = Path extends "*" ? "*" : Path extends `${infer Rest}/*` ? "*" | _PathParam<Rest> : _PathParam<Path>;
declare type ParamParseKey<Segment extends string> = [
    PathParam<Segment>
] extends [never] ? string : PathParam<Segment>;
declare type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

const loader = (loaderFunction: Function) => async ({ params }: TodoLoaderArgs) => {
  const data = loaderFunction(params.id ?? '');
  return defer({ data });
};

const fetchProduct = async (id: string) => {
  const data = await getProductById(id);
  if (!data) {
    throw json(
      {
        message: "Товар не знайдено",
      },
      { status: 404 }
    );
  }
  return data;
};

const ArticleScreenWrapper: FC = () => {
  const { id } = useParams<{ id?: string }>();

  const [article, setArticle] = useState<IArticle | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    if (id) {
      getArticleById(id).then((response) => {
        if (['left-image-right-text'].includes(response.header.type)) {
          response.header = {
            ...response.header,
            image: `${S3Link}/${response.id}/headerImage.png`,
          } as HeaderWithImageType;
        }

        setArticle(response);
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
      });
    }
  }, [id]);

  if (!id) {
    return null;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return article
    ? <ArticleScreen article={article} />
    : <NotFoundScreen title='Статтю не знайдено' linkTo={PATHS.articles} buttonTitle='До блогу' />;
};

export const RedirectResponder = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Layout = () => {
  return (<>
    <RedirectResponder />
    <Header />
    <Outlet />
    <Footer />
  </>);
};

const router = createBrowserRouter([
  {
    path: PATHS.adminOrders,
    element: (
      <ProtectedRoute>
        <AdminOrdersScreen />
      </ProtectedRoute>
    )
  },
  {
    path: PATHS.adminDiscounts,
    element: (
      <ProtectedRoute>
        <ClientBaseScreen />
      </ProtectedRoute>
    )
  },
  {
    path: PATHS.adminProductCatalog,
    element: (
      <ProtectedRoute>
        <AdminProductCatalogScreen />
      </ProtectedRoute>
    )
  },
  {
    path: PATHS.login,
    element: <LoginScreen />
  },
  {
    element: <Layout />,
    errorElement: <ErrorScreen />,
    children: [
      {
        path: PATHS.main,
        element: <MainScreen />
      },
      {
        path: PATHS.search,
        element: <SearchScreen />
      },
      {
        path: PATHS.product,
        loader: loader(fetchProduct),
        element: <ProductScreen />
      },
      {
        path: PATHS.contact,
        element: <ContactScreen />
      },
      /* {
        path: PATHS.bookmarks,
        element: <BookmarksScreen />
      }, */
      {
        path: PATHS.cart,
        element: <CartScreen />
      },
      {
        path: PATHS.orders,
        element: <OrdersScreen />
      },
      {
        path: PATHS.successOrder,
        element: <SuccessOrderScreen />
      },
      {
        path: PATHS.articles,
        element: <ArticlesScreen />
      },
      {
        path: PATHS.articlesCategory,
        element: <ArticlesScreen />
      },
      {
        path: PATHS.article,
        element: <ArticleScreenWrapper />
      },
    ],
  },
]);

export const Router = () => {
  return (
    <RouterProvider router={router} />
  );
};
