import styled from "styled-components";
import { FaAngleLeft, FaAngleDoubleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";
import { marketplaceColors } from "../constants/styleConfig";
import { PAGINATION_MAX_LENGTH } from "../constants/appConfig";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding-top: 0.5rem;
`;

interface IPaginationLinkProps {
  isActive?: boolean;
}

const PaginationLink = styled.div<IPaginationLinkProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  color: ${({ isActive }) => isActive ? marketplaceColors.white : marketplaceColors.gray9};
  background-color: ${({ isActive }) => isActive ? marketplaceColors.primary : marketplaceColors.white};
  border: 1px solid ${marketplaceColors.gray1};
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${({ isActive }) => isActive ? marketplaceColors.primary : marketplaceColors.primaryLight};
  }
`;

interface IPaginationProps {
  length: number;
  maxLength?: number;
  selectedPage: number;
  onSelect: (page: number) => void;
}

const Pagination = ({
  length,
  maxLength = PAGINATION_MAX_LENGTH,
  selectedPage,
  onSelect,
}: IPaginationProps) => {
  const maxPages = Math.min(length, maxLength);
  const selectedIndex = selectedPage - 1;
  const startIndex = length - selectedIndex > maxPages ? selectedIndex : (length - maxPages);
  const pagesArray = [...Array(length)].slice(startIndex, startIndex + maxPages);

  return (
    <Wrapper>
      {selectedIndex > 0 && (
        <PaginationLink
          onClick={() => onSelect(1)}
        >
          <FaAngleDoubleLeft />
        </PaginationLink>
      )}
      {selectedIndex > 0 && (
        <PaginationLink
          onClick={() => onSelect(selectedPage - 1)}
        >
          <FaAngleLeft />
        </PaginationLink>
      )}
      {pagesArray.map((value, index) => (
        <PaginationLink
          isActive={index + startIndex === selectedIndex}
          key={index + startIndex}
          onClick={() => {
            if (index + startIndex === selectedIndex) return;
            
            onSelect(index + startIndex + 1);
          }}
        >
          {index + startIndex + 1}
        </PaginationLink>
      ))}
      {selectedIndex < length - 1 && (
        <PaginationLink
          onClick={() => onSelect(selectedPage + 1)}
        >
          <FaAngleRight />
        </PaginationLink>
      )}
      {selectedIndex < length - 1 && (
        <PaginationLink
          onClick={() => onSelect(length)}
        >
          <FaAngleDoubleRight />
        </PaginationLink>
      )}
    </Wrapper>
  );
};

export default Pagination;
