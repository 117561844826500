import { DescriptionContainer, InfoDescription, InfoTable, InfoTableItem, InfoTitle } from "./styles";
import { sanitizeHTML } from '../../util/formatData';

interface IProps {
  id: string;
  descriptionUA: string;
}

const DescriptionInfo = ({ id, descriptionUA }: IProps) => {
  const descriptionHTML = descriptionUA.replaceAll(/&lt;\s*/g, '<').replaceAll(/\s*&gt;/g, '>');
  const cleanDescriptionHTML = sanitizeHTML(descriptionHTML);
  const ingredientsIndex = cleanDescriptionHTML.search(/<h3>Вміст/g);
  const ingredients = ingredientsIndex > 0 ? cleanDescriptionHTML.slice(ingredientsIndex) : '';
  const formattedDescription = cleanDescriptionHTML.slice(0, ingredientsIndex);

  const formattedIngredients = ingredients ? ingredients.split(/<br\s*>/g).map(item => {
    const trimmed = item.trim().replaceAll('(', '<i>(').replaceAll(')', ')</i>');
    if (trimmed.search('<strong>') !== -1) {
      return [trimmed];
    }

    const itemValueIndex = trimmed.search(/\s+\d+/g);
    if (itemValueIndex !== -1) {
      const itemName = trimmed.slice(0, itemValueIndex);
      const itemValue = trimmed.slice(itemValueIndex);
      return [itemName, itemValue];
    }
    
    return [trimmed];
  }) : [];

  return (
    <DescriptionContainer>
      <div>
        <InfoTitle>Опис</InfoTitle>
        <InfoDescription dangerouslySetInnerHTML={{ __html: formattedDescription }} />
      </div>
      {formattedIngredients.length ? (
        <InfoTable>
          <InfoTitle>Склад</InfoTitle>
          {formattedIngredients.map((ingredientItem, ingredientIndex) => (
            <InfoTableItem key={`${id}_ingredient_${ingredientIndex}`} length={ingredientItem.length}>
              {ingredientItem.map((ingredientValue, ingredientValueIndex) => (
                <div key={`${id}_ingredient_${ingredientIndex}_${ingredientValueIndex}`} dangerouslySetInnerHTML={{ __html: ingredientValue}} />
              ))}
            </InfoTableItem>
          ))}
        </InfoTable>
      ) : null}
    </DescriptionContainer>
  )
};

export default DescriptionInfo;
