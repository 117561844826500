import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCartContext, useCartDispatchContext, useUserContext } from "../../contexts/ProductCartContext";
import {
  CartBottom,
  CartCount,
  CartEmptyItem,
  CartIconWrapper,
  CartItemsContainer,
  CartModal,
  CartModalHead,
  CartModalTitle,
  CloseIcon,
  DeleteButtonWrapper,
  HeaderWrapper,
  IoCartWrapper,
  LogoWrapper,
  ModalItem,
  ModalItemBottom,
  ModalItemImage,
  ModalItemPrice,
  ModalItemText,
  ModalItemTop,
  NavbarBackArrow,
  NavbarBurger,
  NavbarContainer,
  NavbarContentWrapper,
  NavbarDropdownContainer,
  NavbarDropdownWrapper,
  NavbarLink,
  NavbarMultilink,
  SearchbarContainer,
  Topbar,
  TopbarContainer,
  TotalPrice,
  TreeContainer
} from "./styles";
import { CURRENCY_SYMBOL, ITreeNode, NAVBAR_LINKS, PATHS } from "../../constants/appConfig";
import { LogoIcon } from "../../assets/svgs/LogoIcon";
import { IoCartOutline, IoClose } from "react-icons/io5";
import { SearchInputField } from "../InputField/SearchInput";
import { AppButton } from "../Button/Button";
import { Counter } from "../Counter";
import { getTotalCartPrice, getTotalPrice } from "../../util/price";
import { CATEGORY_ID_NAME } from "../../constants/dictionary";
import { Overlay } from '../../components';
import AdaptiveModal from "../AdaptiveModal";
import AngleIcon from "../AngleIcon";

const Tree = ({ tree, indent = 0 }: { tree: ITreeNode[], indent?: number }) => {
  return (<>
    {tree.map(node => (
      <React.Fragment key={node.id}>
        <div style={{ paddingLeft: `${indent * 10}px` }}>{CATEGORY_ID_NAME[node.id]}</div>
        {!!node.sub?.length && (
          <Tree tree={node.sub} indent={indent + 1} />
        )}
      </React.Fragment>
    ))}
  </>);
};

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState('');

  const cartContext = useCartContext();
  const { setIsModalOpen } = useCartDispatchContext();

  const userContext = useUserContext();
  const cartDispatchContext = useCartDispatchContext();

  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const {
    incrementProductInCart,
    decrementProductInCart,
    removeProductFromCart,
  } = cartDispatchContext;

  const productsCount: number | undefined = cartContext?.reduce((count: number, product) => count + (product.count || 0), 0);
  const isCartNotEmpty: boolean = (cartContext && cartContext.length > 0) || false;

  return (
    <HeaderWrapper>
      <Overlay
        $isActive={isMenuOpen || userContext.isModalOpen}
        $isClosedOnBreakpoint={!userContext.isModalOpen}
        onClick={(e) => {
          e.stopPropagation();
          setIsMenuOpen(false);
          setSelectedDropdown('');
          setIsModalOpen(false);
        }}
      />
      <TopbarContainer>
        <Topbar>
          <NavbarBurger
            $isActive={isMenuOpen}
            onClick={() => {
              setIsMenuOpen(prev => !prev);
              setSelectedDropdown('');
            }}
          />
          <LogoWrapper to={PATHS.main}>
            <LogoIcon />
          </LogoWrapper>
          <SearchbarContainer>
            <SearchInputField />
          </SearchbarContainer>
          <CartIconWrapper onClick={() => setIsModalOpen(!userContext.isModalOpen)}>
            <IoCartWrapper />
            {productsCount ? <CartCount>{productsCount}</CartCount> : null}
          </CartIconWrapper>
        </Topbar>
      </TopbarContainer>
      <NavbarContainer>
        <NavbarContentWrapper>
          <AdaptiveModal
            $isActive={isMenuOpen}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <NavbarBackArrow
              onClick={() => {
                setIsMenuOpen(false);
                setSelectedDropdown('');
              }}
            />
            {NAVBAR_LINKS.map((linkItem) => {
              if (!linkItem.sublinks) return (
                <Link key={linkItem.label} to={linkItem.route}>
                  <NavbarLink>
                    {linkItem.label}
                  </NavbarLink>
                </Link>
              );
              return (
                <NavbarDropdownWrapper key={linkItem.label}>
                  <NavbarMultilink
                    $isActive={selectedDropdown === linkItem.label}
                    onClick={() => setSelectedDropdown(prev => prev === linkItem.label ? '' : linkItem.label)}
                  >
                    {linkItem.label}
                    <AngleIcon $isActive={selectedDropdown === linkItem.label} />
                  </NavbarMultilink>
                  {linkItem.tree ? (
                    <TreeContainer>
                      <Tree tree={linkItem.tree} />
                    </TreeContainer>
                  ) : (
                    <NavbarDropdownContainer $isActive={selectedDropdown === linkItem.label}>
                      {linkItem.sublinks.map(sublink => {
                        if (sublink.id === 'other' && sublink.route) {
                          return (
                            <Link key={`${linkItem.label}_${sublink.label}`} to={sublink.route}>
                              <NavbarLink>
                                {sublink.label}
                              </NavbarLink>
                            </Link>
                          )
                        }

                        return (
                          <Link key={`${linkItem.label}_${sublink.label}`} to={`${linkItem.route}${sublink.id}`}>
                            <NavbarLink>
                              {sublink.label}
                            </NavbarLink>
                          </Link>
                        )
                      })}
                    </NavbarDropdownContainer>
                  )}
                </NavbarDropdownWrapper>
              );
            })}
          </AdaptiveModal>
          <CartModal $isActive={userContext.isModalOpen}>
            <CartModalHead>
              <CloseIcon onClick={() => setIsModalOpen(false)} />
              <CartModalTitle>Кошик</CartModalTitle>
            </CartModalHead>
            <CartItemsContainer>
              {cartContext?.length ? cartContext.map((product) => (
                <ModalItem key={product.id}>
                  <ModalItemTop to={PATHS.product.replace(':id', product.id)}>
                    <ModalItemImage src={product.imageSrc} alt="" />
                    <div>
                      <ModalItemText>{product.vendor} {product.name}</ModalItemText>
                      <ModalItemPrice>
                        {/* <ModalItemPriceOld>
                          {getTotalPrice(product.priceOld, product.count)} {CURRENCY_SYMBOL}
                        </ModalItemPriceOld> */}
                        {getTotalPrice(product.price, product.count)} {CURRENCY_SYMBOL}
                      </ModalItemPrice>
                    </div>
                  </ModalItemTop>
                  <ModalItemBottom>
                    <Counter
                      value={product.count}
                      onIncrement={() => incrementProductInCart(product.id)}
                      onDecrement={() => decrementProductInCart(product.id)}
                    />
                    <DeleteButtonWrapper onClick={() => removeProductFromCart(product.id)}>
                      <IoClose />
                    </DeleteButtonWrapper>
                  </ModalItemBottom>
                </ModalItem>
              )) : (
                <CartEmptyItem>
                  <IoCartOutline />
                  <CartModalTitle>Кошик порожній</CartModalTitle>
                </CartEmptyItem>
              )}
            </CartItemsContainer>
            <CartBottom>
              {cartContext && (
                <TotalPrice>
                  <div>Всього:</div>
                  <div>{getTotalCartPrice(cartContext)} {CURRENCY_SYMBOL}</div>
                </TotalPrice>
              )}
              <Link to={isCartNotEmpty ? PATHS.cart : '#'}>
                <AppButton 
                  isDisabled={!isCartNotEmpty}
                  onClick={() => setIsModalOpen(false)}
                >
                  Оформити замовлення
                </AppButton>
              </Link>
            </CartBottom>
          </CartModal>
        </NavbarContentWrapper>
      </NavbarContainer>
    </HeaderWrapper>
  );
};

export { Header };
